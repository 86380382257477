import React, {Fragment, useEffect, useRef, useState} from 'react';
import Countdown from 'react-countdown';
import s from "../Lot/Lot.module.css";
function Profile(){
    const [isAddTime, setIsAddTime] = useState(false);
    const countdownRef = useRef();
    const [counterDate, setCounterDate] = useState(Date.now()+10000);

    return(
        <div>
            <Countdown
                ref={countdownRef}
                onComplete={()=>{
                    if(isAddTime) alert('Out');
                    setIsAddTime(true);
                    setTimeout(()=>{
                        countdownRef.current.getApi().start(); //перемістити в компонент каунтера?
                    },0)
            }}
                daysInHours={true}
                date={isAddTime?(counterDate)+5000:(counterDate)}/>


            <button onClick={()=>{
                setCounterDate(Date.now()+10000);
                setIsAddTime(false)
            }}>Clear timers</button>
        </div>
    )
}

export default Profile;























// import React, {useEffect, useRef, useState} from 'react';
// import Countdown from 'react-countdown';
// function Profile(){
//     const [isAddTime, setIsAddTime] = useState(false);
//     const addCounterRef = useRef();
//     const [counterDate, setCounterDate] = useState(Date.now()+10000);
//     const [counterAddTime, setCounterAddTime] = useState(Date.now()+15000);
//     return(
//         <div>
//             {isAddTime?<Countdown
//                     ref={addCounterRef}
//                     onComplete={()=>{alert('Вилетів')}}
//                     date={counterAddTime}/>:
//                 <Countdown
//                     onComplete={()=>{
//                         setIsAddTime(true);
//                         setTimeout(()=>{
//                             addCounterRef.current.getApi().start()
//                         },0)}
//                     }
//                     date={counterDate}/>}
//
//             <button onClick={()=>{
//                 setCounterDate(Date.now()+10000);
//                 setCounterAddTime(Date.now()+15000);
//                 setIsAddTime(false)
//             }}>Clear timers</button>
//         </div>
//     )
// }
//
// export default Profile;