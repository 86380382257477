import {useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import {AuthContext} from '../context/AuthContext'
import localizate from '../localization/localization'
import ErrorHandler from "../utils/ErrorLoger";
export const useHttp = () => {
    const {fetchRefreshToken, logout} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);
    const [requestErrors, setRequestErrors] = useState(null);
    //const token = useSelector(state => state.token);
    //TODO rewrite this (FormData, works without try catch)
    const request = async(url, method = 'GET', body = null, headers = {}) => {

        // return new Promise((res, rej)=>{
        //     setTimeout(()=>{
        //         res([
        //             {id: 1, name: "testingOption1"},
        //             {id: 2, name: "testingOption2"},
        //         ])
        //     },2000)
        // })

        // return new Promise((res, rej)=>{
        //     fetch().then(data=>data.json()).then(data=>res(data));
        // })


        function getToken(){
            const storage = JSON.parse(localStorage.getItem('userData'));
            if(storage && Object.keys(storage).length != 0 && storage.token){
                return storage.token;
            }else return null;
        }
        setMsg(null);
        setRequestErrors(null);
        setLoading(true);
        let token = getToken();
        try {
             let data;

            if(token && !token.startsWith('ya29.')) headers = {...headers, 'Authorization': `Bearer ${token}`}

            if (body) {
                body = JSON.stringify(body);
                headers['Content-Type'] = 'application/json';
            }

            let response = await fetch(url, {
                method, body, headers
            });

            if(response.status == 401){
                logout();
                // let tkn;
                // await fetchRefreshToken().then((result)=>{tkn=result});
                //
                // headers = {...headers, 'Authorization': `Bearer ${tkn}`}
                // const _data = await fetch(url, {
                //     method:method,
                //     headers: headers,
                //     body:body
                // });
                //
                // return await _data.json();
            }

            data = await response.json();
            if (!response.ok) {

                let errorsArray = [];
                data.errors.map((error, i)=>{
                    errorsArray.push(localizate(error));
                });
                setRequestErrors(errorsArray);
                throw new Error('Something wrong');

            }
            setLoading(false);

            return data;

         } catch (e) {
            ErrorHandler(e)
             setLoading(false);
             setMsg(e.message);
        }
    };

    return { loading, request, msg, requestErrors }
}