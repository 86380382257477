import React, {useContext, useState} from 'react';
import s from './Restore.module.css'
import {NavLink} from 'react-router-dom';
import {useHttp} from "../../hooks/HttpHook";
import config from "../../config/config";
import InputMask from "react-input-mask";
import {AuthContext} from "../../context/AuthContext";
import {useHistory} from 'react-router-dom';
import ErrorHandler from "../../utils/ErrorLoger";
function Restore(){
    const history = useHistory();
    const [phoneNumber, setPhoneNumber] = useState();
    const {request, requestErrors} = useHttp();
    const auth = useContext(AuthContext);
    const numberChangeHandler = (e) =>{
        const number = e.target.value.replace(/[^0-9]/g,'');
        setPhoneNumber(number);
    }

    const phoneNumberHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + '/api/v1/user/password/reset/sms/request/', 'POST', {phone_number: '+' + phoneNumber});
            if(data.token){
                auth.login(data.token.access, data.token.refresh);
                switch(data.next_page){
                    case 'home': history.push('/'); break;
                    case 'verify_sms_code': history.push('/verify'); break;
                }
            }
        }catch (e) {
            ErrorHandler(e)
        }
    }
    
    return(
        <div className={s.Restore}>
            <div className={s.background}>
            </div >

            <div className={s.resForm}>
                <form>
                    <h1>Забули пароль</h1>
                    {requestErrors?<h2 data-testid="register-errros" className={s.error}>{requestErrors[0]}</h2>:null}
                    <InputMask mask="+38 (099) 999-99-99" type="text" name="phone_number" onChange={numberChangeHandler} placeholder="Номер телефону"/>
                    <div className={s.butBox}>
                        <button onClick={phoneNumberHandler}>Надіслати</button>
                    </div>
                <div className={s.regBox}>
                    <p>Ще не зареєстровані?</p>
                    <NavLink to="/register/">Зареєструватись</NavLink>
                </div>
                </form>
            </div>  
        </div>
    )
}

export default Restore;