import React, {useContext, useEffect, useState} from 'react';
import s from './Protocols.module.css';
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {useHttp} from "../../hooks/HttpHook";
import config from '../../config/config'
import icon from '../../assets/img/document-icon.png';
import {FileDrop} from "react-file-drop";
import PreviewItem from "../../components/PreviewItem/PreviewItem";
import OutsideClickHandler from 'react-outside-click-handler';
import ErrorHandler from "../../utils/ErrorLoger";
import SignComponent from "../../components/SignComponent/SignComponent";
import {AuthContext} from "../../context/AuthContext";
import ProtocolItem from "./components/ProtocolItem";
function Protocols() {
    const [winnerProtocols, setWinnerProtocols] = useState([]);
    const [organizerProtocols, setOrganizerProtocols] = useState([]);
    const {request} = useHttp();

    const getData = async () => {
        try{
            let _winnerProtocols = await request(config.baseUrl + '/api/v1/auctions/auction-protocols/protocols/winner/','GET');
            let _organizerProtocols = await request(config.baseUrl + '/api/v1/auctions/auction-protocols/protocols/organizer/','GET');
            if(_winnerProtocols) setWinnerProtocols(_winnerProtocols)
            if(_organizerProtocols) setOrganizerProtocols(_organizerProtocols)
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const WinnerProtocols = () => {
        return (
            <div className={s.monthDiv}>
                {winnerProtocols.length>0?
                    winnerProtocols.map((elem,i)=> <ProtocolItem onUpdate={getData} type={"winner"} key={i} item={elem}/>):
                    <h3 className={s.emptyInvoices}>Немає доступних протоколів</h3>}
            </div>
        )
    }

    const OrganizerProtocols = () => {
        return (
            <div className={s.monthDiv}>
                {organizerProtocols.length>0?
                    organizerProtocols.map((elem,i)=> <ProtocolItem onUpdate={getData} type={"organizer"} key={i} item={elem}/>):
                    <h3 className={s.emptyInvoices}>Немає доступних протоколів</h3>}
            </div>
        )
    }

    useEffect( async ()=>{
        await getData();
    },[])

    return(
        <div style={s.Protocols}>
            <div className={s.headerBox}>
                <h1>Протоколи</h1>
            </div>
            {/*<h2 className={s.typeTitle}>Переможець</h2>*/}
            <WinnerProtocols/>
            {/*<h2 className={s.typeTitle}>Організатор</h2>*/}
            {/*<OrganizerProtocols/>*/}
        </div>
    )
}

export default Protocols;