import React, {useContext, useState, useEffect} from 'react';
import s from './ResetPassword.module.css'
import {NavLink} from 'react-router-dom';
import {useHttp} from "../../hooks/HttpHook";
import config from "../../config/config";
import InputMask from "react-input-mask";
import {useHistory} from 'react-router-dom';
import {AuthContext} from "../../context/AuthContext";
import ErrorHandler from "../../utils/ErrorLoger";
function ResetPassword(){
    const [form, setForm] = useState();
    const [modalShown, setModalShown] = useState(false);
    const {request, requestErrors} = useHttp();
    const auth = useContext(AuthContext);
    const history = useHistory();
    const changeHandler = (e) => {
        setForm({...form, [e.target.name]: e.target.value})
    }

    const phoneNumberHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + '/api/v1/user/password/reset/sms/', 'POST', form);
            if(data.status=="OK"){
                setModalShown(true);
            }
        }catch (e) {
            ErrorHandler(e)
        }
    }

    useEffect(() => {
        return () => {
            auth.logout();
        }
    }, []);

    return(
        <div className={s.Restore}>
            <div className={s.background}>
            </div >
            <div className={s.resForm}>
                <form>
                    <h1>Відновлення паролю</h1>
                    {requestErrors?<h2 data-testid="register-errros" className={s.error}>{requestErrors[0]}</h2>:null}
                    <h3>Введіть новий пароль</h3>
                    <input onChange={changeHandler} name="new_password1" type="password"/>
                    <h3>Повторіть новий пароль</h3>
                    <input onChange={changeHandler} name="new_password2" type="password"/>
                    <div className={s.butBox}>
                        <button onClick={phoneNumberHandler}>Надіслати</button>
                    </div>
                </form>
            </div>

            {modalShown?
                <div className={s.success}>
                    <div className={s.successContent}>
                        <h2>Пароль було успішно змінено</h2>
                        <button onClick={()=>{history.push(`/login/`)}}>Готово</button>
                    </div>
                </div>:null}
        </div>
    )
}

export default ResetPassword;