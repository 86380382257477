import React, {useEffect, useState, useRef} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import s from './FixedMarket.module.css';
import {NavLink} from 'react-router-dom';
import {useHttp} from '../../hooks/HttpHook';
import InputMask from 'react-input-mask';
import config from '../../config/config.js';
import Card from '../../components/Card/Card';
import InputRange from 'react-input-range';
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import "react-input-range/lib/css/index.css";
function FixedMarket(props){
    const areaFilter = useRef();
    const [lots, setLots] = useState([]);
    const [filter, setFilter] = useState({});
    const [settlementShown, setSettlementShown] = useState(false);
    const [purposeShown, setPurposeShown] = useState(false);
    const [priceShown, setPriceShown] = useState(false);
    const [areaShown, setAreaShown] = useState(false);
    const [area, setArea] = useState({min:0, max:10000});
    const [price, setPrice] = useState({min:0, max:1000000000});
    const [sidemenuShown, setSidemenuShown] = useState(false);
    const {request} = useHttp();

    const resetFiltersHandler = async () => {
        setFilter({});
        setArea({min:0, max:10000});
        setPrice({min:0, max:1000000000});
    }

    const handleAreaBlur = (e) => {
                setAreaShown(false);    
     }

     const numberChangeHandler = async (e) =>{
        setFilter({...filter,search:e.target.value});
    }

    // async function getLots(){
    //     const params = new URLSearchParams(filter);
    //     if(params) {
    //         const data = await request(config.baseUrl + `/api/v1/auctions/?${params}`);
    //         if(data) setLots(data.results);
    //     }else{
    //         const data = await request(config.baseUrl + '/api/v1/auctions/');
    //         if(data) setLots(data.results);
    //     }
    // }
    //
    // useEffect(()=>{
    //     getLots();
    // },[filter]);
    //
    // useEffect(()=>{
    //     setFilter(JSON.parse(props.filter));
    // },[props.filter])

    async function getLots(){
        const params = new URLSearchParams(filter);
        if(params) {
            const data = await request(config.baseUrl + `/api/v1/land-market/lands/?${params}`);
            if(data) setLots(data.results);
        }else{
            const data = await request(config.baseUrl + '/api/v1/land-market/lands/');
            if(data) setLots(data.results);
        }
    }

    useEffect(()=>{
        getLots();
    },[filter]);

    useEffect(()=>{
        setFilter(JSON.parse(props.filter));
    },[props.filter])

    return(
        <div  className={s.FixedMarket}>
            <div style={{transition: "all .5s"}} className={sidemenuShown?s.sideMenu:s.sideMenuNotActive}>
                <div>
                    <div className={s.sideMenuHeader}>
                        <p>Фільтри</p>
                        <i onClick={()=>{setSidemenuShown(false)}} class="fas fa-times"></i>
                    </div>
                    <div className={s.sliderFilter}>
                        <label>Ціна землі</label>
                        <div className={s.sliderInputs}>
                            <input value={price.min} type="number"/>
                            <div className={s.sliderLine}/>
                                <input value={price.max} type="number"/>
                            </div>
                            <InputRange
                                maxValue={1000000000}
                                step={10000000}
                                minValue={0}
                                formatLabel={() => ''}
                                onChange={value => setPrice(value)}
                                onChangeComplete={(value)=>{setFilter({...filter, price_min: value.min, price_max: value.max})}}
                                value={price} />
                        </div>
                    <label>Область</label>
                        <CustomSelect 
                            onSelect={(id)=>{setFilter({...filter, region_id: id})}} 
                            url="/api/v1/location/regions/"/>
                    <label>Район</label>
                        <CustomSelect 
                            onSelect={(id)=>{setFilter({...filter, district_id: id})}}
                            url="/api/v1/location/districts/"/>
                    <label>Населений пункт</label>
                        <CustomSelect 
                            onSelect={(id)=>{setFilter({...filter, settlement_id: id})}}
                            url="/api/v1/location/settlements/"/>
                    <label>Вулиця</label>
                        <CustomSelect 
                            onSelect={(id)=>{setFilter({...filter, street_id: id})}}
                            url="/api/v1/location/streets/"/>
                    <label>Призначення</label>
                        <CustomSelect 
                            onSelect={(id)=>{setFilter({...filter, purpose_id: id})}}  
                            url="/api/v1/land-market/lands/purposes/"/>
                    <label>Площа</label>
                    <div className={s.sliderFilter}>
                        <div className={s.sliderInputs}>
                            <input value={area.min} type="number"/>
                            <div className={s.sliderLine}/>
                                <input value={area.max} type="number"/>
                            </div>
                            <InputRange
                                maxValue={10000}
                                step={100}
                                minValue={0}
                                formatLabel={() => ''}
                                onChange={value => setArea(value)}
                                onChangeComplete={(value)=>{setFilter({...filter, area_min: value.min, area_max: value.max})}}
                                value={area} />
                        </div>
                </div>
                <div  className={s.filterButBox}>
                    <button className={s.clearFiltersButton} onClick={()=>{resetFiltersHandler();setSidemenuShown(false)}}>Скинути фільтри</button>
                    <button className={s.setFiltersButton} onClick={()=>{setSidemenuShown(false)}}>Фільтрувати</button>
                </div>
                
            </div>
                <div className={s.filter}>
                    <div className={s.filterInput}>
                        <i className="fas fa-search"></i>
                        <input onChange={numberChangeHandler} placeholder="Кадастровий номер, номер аукціону"/>
                    </div>
                    <input id={s.filterCheck} type="checkbox" onChange={(e)=>{setSidemenuShown(true)}}/>
                        <label className={s.filtersButton} htmlFor={s.filterCheck}>
                                <p>Фільтри оголошень</p>
                                <i class="fas fa-filter"></i>
                        </label>
                    <div className={s.filterBottom}>
                        <div className={s.filterBottomLeft}>

                        <div className={s.filterBox}>
                                <div onClick={()=>{setPriceShown(!priceShown)}} className={s.filterBoxContent}>
                                    <p>Ціна землі</p>
                                    {priceShown?<i className="fas fa-chevron-up"></i>:<i className="fas fa-chevron-down"></i>}
                                </div>
                                {priceShown?<OutsideClickHandler onOutsideClick={()=>{setPriceShown(false)}}><div className={s.filterDropdown}>
                                    <div className={s.sliderFilter}>
                                        <div className={s.sliderInputs}>
                                            <input value={price.min} type="number"/>
                                            <div className={s.sliderLine}/>
                                            <input value={price.max} type="number"/>
                                        </div>
                                        <InputRange
                                            maxValue={1000000000}
                                            step={10000000}
                                            minValue={0}
                                            formatLabel={() => ''}
                                            onChange={value => setPrice(value)}
                                            onChangeComplete={(value)=>{setFilter({...filter, price_min: value.min, price_max: value.max})}}
                                            value={price} />
                                    </div>
                                </div></OutsideClickHandler>:null}
                            </div>

                            <div className={s.filterBox}>
                                <div onClick={()=>{setPurposeShown(true)}} className={s.filterBoxContent}>
                                    <p>Тип/Призначення</p>
                                    {purposeShown?<i className="fas fa-chevron-up"></i>:<i className="fas fa-chevron-down"></i>}
                                </div>
                                {purposeShown?<OutsideClickHandler onOutsideClick={()=>{setPurposeShown(false)}}>
                                   <div className={s.filterDropdown}>
                                <div className={s.infoBox}>
                                    <label>Призначення</label>
                                    <CustomSelect 
                                    onSelect={(id)=>{setFilter({...filter, purpose_id: id})}}  
                                    url="/api/v1/land-market/lands/purposes/"
                                    defaultId={filter.purpose_id?filter.purpose_id-1:null}/>
                                </div>
                                </div> </OutsideClickHandler>:null}
                            </div>
                           
                            <div onClick={()=>{setSettlementShown(true)}} className={s.filterBox}>
                                <div className={s.filterBoxContent}>
                                    <p>Населений пункт</p>
                                    {settlementShown?<i className="fas fa-chevron-up"></i>:<i className="fas fa-chevron-down"></i>}
                                </div>
                                {settlementShown? <OutsideClickHandler 
                                onOutsideClick={()=>{setSettlementShown(false)}}>
                                    <div className={s.filterDropdown}>
                                    <div className={s.infoBox}>
                                        <label>Область</label>
                                        <CustomSelect 
                                        onSelect={(id)=>{setFilter({...filter, region_id: id})}} 
                                        url="/api/v1/location/regions/"
                                        defaultId={filter.region_id?filter.region_id-1:null}/>
                                    </div>
                                    <div className={s.infoBox}>
                                        <label>Район</label>
                                        <CustomSelect 
                                        onSelect={(id)=>{setFilter({...filter, district_id: id})}}
                                        url="/api/v1/location/districts/"
                                        defaultId={filter.district_id?filter.district_id-1:null}/>
                                    </div>
                                    <div className={s.infoBox}>
                                        <label>Населений пункт</label>
                                        <CustomSelect 
                                        onSelect={(id)=>{setFilter({...filter, settlement_id: id})}}
                                        url="/api/v1/location/settlements/"
                                        defaultId={filter.settlement_id?filter.settlement_id-1:null}/>
                                    </div>
                                    <div className={s.infoBox}>
                                        <label>Вулиця</label>
                                        <CustomSelect 
                                        onSelect={(id)=>{setFilter({...filter, street_id: id})}}
                                        url="/api/v1/location/streets/"
                                        defaultId={filter.street_id?filter.street_id-1:null}/>
                                    </div>
                                </div></OutsideClickHandler>:null}
                            </div>
                           
                           <div
                            onClick={()=>{setAreaShown(true)}}
                            className={s.filterBox}>
                                <div className={s.filterBoxContent}>
                                    <p>Площа</p>
                                    {areaShown?<i className="fas fa-chevron-up"></i>:<i className="fas fa-chevron-down"></i>}
                                </div>
                                {areaShown? <OutsideClickHandler onOutsideClick={()=>{setAreaShown(false)}}><div className={s.filterDropdown}>
                                    <div className={s.sliderFilter}>
                                        <div className={s.sliderInputs}>
                                            <input value={area.min} type="number"/>
                                            <div className={s.sliderLine}/>
                                            <input value={area.max} type="number"/>
                                        </div>
                                        <InputRange
                                            maxValue={10000}
                                            step={100}
                                            minValue={0}
                                            formatLabel={() => ''}
                                            onChange={value => setArea(value)}
                                            onChangeComplete={(value)=>{setFilter({...filter, area_min: value.min, area_max: value.max})}}
                                            value={area} />
                                    </div>
                                </div></OutsideClickHandler>:null}
                                </div>


                        </div>
                        <button onClick={resetFiltersHandler} className={s.resetBtn}>Скинути фільтри</button>
                    </div>
                </div>
                <div className={s.content}>
                    {lots.length>0?lots.map((lot, i)=>{
                       return <Card type="fixed" item={lot} key={i}/>
                    }):<h3 className={s.notFound}>Оголошень не знайдено</h3>}
                </div>
                
        </div>
        
    )   
}

export default FixedMarket;