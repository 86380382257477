import React, {useEffect, useState} from 'react';
import s from './Documents.module.css';
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {useHttp} from "../../hooks/HttpHook";
import config from '../../config/config'
import {FileDrop} from "react-file-drop";
import fileIcon from '../../assets/img/document-icon.png';
import PreviewItem from "../../components/PreviewItem/PreviewItem";
import OutsideClickHandler from 'react-outside-click-handler';
import ErrorHandler from "../../utils/ErrorLoger";
function Documents() {
    const [documentsInfo, setDocumentsInfo] = useState();
    const [years, setYears] = useState();
    const [clearInputs, setClearInputs] = useState(false);
    const [modalShown, setModalShown] = useState(false);
    const [previewDocuments, setPreviewDocuments] = useState([]);
    const [documentIds, setDocumentIds] = useState([]);
    const [userDocuments, setUserDocuments] = useState([]);
    const [filter, setFilter] = useState({});
    const {request} = useHttp();
    const months = [
        {name:"Січень", id:1},
        {name:"Лютий", id:2},
        {name:"Березень", id:3},
        {name:"Квітень", id:4},
        {name:"Травень", id:5},
        {name:"Червень", id:6},
        {name:"Липень", id:7},
        {name:"Серпень", id:8},
        {name:"Вересень", id:9},
        {name:"Жовтень", id:10},
        {name:"Листопад", id:11},
        {name:"Грудень", id:12},
    ]

    const genereteYears = () => {
        const arr = [];
        let d = new Date( "01 " + "July 2021"),
        first = d.getFullYear();
        let s = new Date(),
        second = s.getFullYear();
        for(let i = first; i <= second; i++) arr.push({id:i, name:`${i}`});
        return arr;
    }

    async function fileListToBase64(fileList) {
        function getBase64(file) {
            const reader = new FileReader()
            const fileExtension = file.name.split('.').pop();
            const fileName = file.name.split('.')[0];
            return new Promise(resolve => {
                reader.onload = ev => {
                    resolve({
                        "file_extension": fileExtension,
                        "encoded_file": ev.target.result,
                        "filename": fileName,
                        "is_main_picture": false
                    })
                }
                reader.readAsDataURL(file)
            })
        }
        const promises = []
        for (let i = 0; i < fileList.length; i++) {
            promises.push(getBase64(fileList[i]))
        }
        return await Promise.all(promises)
    }

    const deleteDocumentsHandler = (id) => {
        let oldIds = [...documentIds];
        oldIds.splice(id, 1);
        setDocumentIds(oldIds);

        let oldPreviews = [...previewDocuments];
        oldPreviews.splice(id, 1);
        setPreviewDocuments(oldPreviews);
    }

    const documentsHandler = async (files) => {
        const documents = await fileListToBase64(files);
        let promises = [];
        let ids = [];
        let preview = [];
        documents.map((document)=>{
            if(!previewDocuments.some((el)=>el.encoded_file === document.encoded_file)){
                const promise = request(config.baseUrl + '/api/v1/land-market/lands/documents/upload/','POST', document);
                promise.then((data)=>{
                    ids.push(data.file_id);
                    preview.push(document);
                })
                promises.push(promise);
            }
        })
        Promise.all(promises).then(()=>{
            setDocumentIds([...documentIds,...ids]);
            setPreviewDocuments([...previewDocuments,...preview]);
            getDocuments();
        })
        //request(config.baseUrl + '/api/v1/land-market/lands/documents/upload/','POST', document);

    }

    const renderDocuments = (documents) => {
        const elems =  [];
        months.forEach((month, i)=>{
            console.log('month',month);
            const arr = [];
            documents.map((document)=>{
                if(document.month == (i+1)) arr.push(document);
            })
            if(arr.length>=1) elems.push(
                <div className={s.monthDiv}>
                    <h1>{month.name + ' 2021'}</h1>
                    <div className={s.monthCards}>
                    {arr.map((elem,i)=> {
                        const extension = elem.filename.split('.')[elem.filename.split('.').length-1].toLowerCase();
                        const photoExtensions = ['png','jpg','jpeg'];
                        const isImage = photoExtensions.some((photoExtension)=>{return extension===photoExtension});
                        return <div className={s.monthCard} key={i}>
                            <img className={isImage?s.documentImage:s.documentFile} src={isImage?config.baseUrl + elem.url:fileIcon}/>
                            <div className={s.cardText}>
                                <p>{elem.filename}</p>
                                <div className={s.cardButtons}>
                                    <a target="_blank" href={config.baseUrl + elem.url} className={s.download}>Переглянути</a>
                                    <p documentId={elem.id} onClick={deleteHandler} className={s.cardDelete}><i onClick={deleteHandler} className="far fa-trash-alt"/></p>
                                </div>
                            </div>
                        </div>
                    })}
                    </div>
                </div>
            )
        })
        console.log(elems);
        return (
            <div>
                {elems.map((item,i)=>{
                    return elems[i];
                })}
            </div>);
    }

    const deleteHandler = async (e) => {
        const id = e.target.getAttribute('documentId');
        let data;
        try{
            data = await request(config.baseUrl+`/api/v1/user/documents/${id}/delete/`, 'DELETE');
            getDocuments();
        }catch (e) {
            ErrorHandler(e)
        }
        //
    }


    const getDocuments = async () =>{
        const params = new URLSearchParams(filter);
        if(params) {
            const data = await request(config.baseUrl + `/api/v1/user/documents/?${params}`);
            if(data) setUserDocuments(data.results);
        }else{
            const data = await request(config.baseUrl + '/api/v1/user/documents/');
            if(data) setUserDocuments(data.results);
        }
    }

    useEffect( ()=>{
        setYears(genereteYears());
        getDocuments();
    },[filter])

    useEffect( ()=>{
        if(clearInputs) setClearInputs(false);
    },[clearInputs])

    return(
        <div style={s.Documents}>
            <div className={s.headerBox}>
                <h1>Документи</h1>
                <button onClick={()=>{setModalShown(true)}}>Завантажити документ</button>
            </div>
            <div className={s.filtersBox}>
                    <CustomSelect clear={clearInputs} placeholder="Місяць" onSelect={(id)=>{setFilter({...filter, month:id})}} data={months}/><p/>
                {years?<CustomSelect clear={clearInputs} placeholder="Рік" onSelect={(id)=>{setFilter({...filter, year:id})}} data={years}/>:null}<p/>
                <button onClick={()=>{setClearInputs(true);setFilter({});}} className={s.clearFilters}>Очистити фільтри</button>
            </div>
            {userDocuments.length>=1? renderDocuments(userDocuments):<h3 className={s.emptyDocuments}>Немає завантажених документів</h3>}
            {modalShown?
                <div className={s.success}>
                    <div className={s.successContent}>
                        <div className={s.documents}>
                            <h2>Документи ділянки</h2>
                            <p>Доступні формати: .jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx . Максимальний розмір файлу не повинен перевищувати 20 Mb. </p>
                            <FileDrop onDrop={documentsHandler} className={s.dropBox} name="document">
                                <div>
                                    <h3>Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері</h3>
                                    <input onChange={(e)=>documentsHandler(e.target.files)} type="file" multiple accept=".jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx"/>
                                    <button onClick={(e)=>{e.target.parentElement.children[1].click()}}>Обрати файл</button>
                                </div>
                            </FileDrop>
                            <div className={s.documentsItems}>
                                {previewDocuments.map((item, i)=>{
                                    return <PreviewItem onDelete={deleteDocumentsHandler} id={i}  key={item.encoded_file} item={item}/>
                                })}
                            </div>
                        </div>
                        <div className={s.buttonsBox}>
                            <button className={s.closeBtn} onClick={()=>{setModalShown(false)}}>Відхилити</button>
                            <button className={s.acceptBtn} onClick={()=>{setModalShown(false); setPreviewDocuments([])}}>Готово</button>
                        </div>
                    </div>
                </div>:null}
        </div>
    )
}

export default Documents;