import React, {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import s from "../RegisterRedesign.module.css";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import InputMask from "react-input-mask";

const SecondStage = ({setMainForm, mainForm, setCurrentStage, currentStage}) => {

        const submitButtonRef = useRef()
        const [validationSchema, setValidationSchema] = useState(null)
        const legalValidationSchema = Yup.object().shape({
            tax_number: Yup.string()
                .required('Поле обов\'язкове')
                .matches(/^[0-9]+$/, "Невірно введено")
                .min(8, 'Довжина повинна бути 8 цифр')
                .max(8, 'Довжина повинна бути 8 цифр'),
            legal_entity_full_name: Yup.string()
                .required('Поле обов\'язкове'),
            second_name: Yup.string()
                .required('Поле обов\'язкове'),
            username: Yup.string()
                .required('Поле обов\'язкове'),
            third_name: Yup.string()
                .required('Поле обов\'язкове'),
            position: Yup.string()
                .required('Поле обов\'язкове'),
            is_based_on: Yup.string()
                .required('Поле обов\'язкове'),
            profile_type: Yup.string()
                .required('Поле обов\'язкове'),
        });

        const individualValidationSchema = Yup.object().shape({
            tax_number: Yup.string()
                .required('Поле обов\'язкове')
                .matches(/^[0-9]+$/, "Невірно введено")
                .min(8, 'Довжина повинна бути 8 цифр')
                .max(8, 'Довжина повинна бути 8 цифр'),
            second_name: Yup.string()
                .required('Поле обов\'язкове'),
            username: Yup.string()
                .required('Поле обов\'язкове'),
            third_name: Yup.string()
                .required('Поле обов\'язкове'),
            profile_type: Yup.string()
                .required('Поле обов\'язкове'),
        });


        useEffect(()=>{
            setCurrentStage(2)
        },[])

        return (
            <Formik
                validationSchema={validationSchema}
                initialValues={{}}
                onSubmit={(values)=>{
                    setMainForm({...mainForm,...values});
                    setCurrentStage(currentStage+1);
                }}
            >
                {props =>
                    <Form>

                        {/*Profile type selection*/}

                        <div className={s.inputItem + ' '+ s.short}>
                            <label>Оберіть організаційно-правову форму*</label>
                            <CustomSelect
                                onSelect={(values, id) => {
                                    props.resetForm()
                                    props.setFieldValue("profile_type",id,false);
                                    if(id === 'LEGAL_ENTITY') setValidationSchema(legalValidationSchema);
                                    else if(id === 'INDIVIDUAL' || id === 'INDIVIDUAL_ENTREPRENEUR') setValidationSchema(individualValidationSchema);
                                }}
                                data={[
                                    { name: "Фізична особа", id: 'INDIVIDUAL' },
                                    { name: "Юридична особа", id: 'LEGAL_ENTITY' },
                                    { name: "Фізична особа - підприємець", id: 'INDIVIDUAL_ENTREPRENEUR' },
                                ]}
                            />
                        </div>

                        {/*Legal form part*/}

                        {props.values.profile_type==='LEGAL_ENTITY' &&
                            <>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Код ЄДРПОУ або ІПН:</label>
                                    <InputMask
                                        onBlur={()=>props.validateField('tax_number')}
                                        onChange={(e)=>{props.setFieldValue("tax_number", e.target.value,false)}}
                                        mask="99999999"
                                        name="tax_number"
                                        value={props.values.phone_number}
                                    />
                                    <p className={s.error}>
                                        {props.errors.tax_number}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Повна назва юридичної особи:</label>
                                    <Field name="legal_entity_full_name"/>
                                    <p className={s.error}>
                                        {props.errors.legal_entity_full_name}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Прізвище:</label>
                                    <Field name="second_name"/>
                                    <p className={s.error}>
                                        {props.errors.second_name}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Ім'я:</label>
                                    <Field name="username"/>
                                    <p className={s.error}>
                                        {props.errors.username}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>По батькові:</label>
                                    <Field name="third_name"/>
                                    <p className={s.error}>
                                        {props.errors.third_name}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Посада:</label>
                                    <Field name="position"/>
                                    <p className={s.error}>
                                        {props.errors.position}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Діє на підставі:</label>
                                    <Field name="is_based_on"/>
                                    <p className={s.error}>
                                        {props.errors.is_based_on}
                                    </p>
                                </div>
                            </>
                        }


                        {/*Individual and individual entrepreneur form part*/}

                        {(props.values.profile_type==='INDIVIDUAL' || props.values.profile_type==='INDIVIDUAL_ENTREPRENEUR') &&
                            <>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label htmlFor="tax_number">Код ЄДРПОУ або ІПН:</label>
                                    <InputMask
                                        onBlur={()=>props.validateField('tax_number')}
                                        onChange={(e)=>{props.setFieldValue("tax_number", e.target.value,false)}}
                                        mask="99999999"
                                        name="tax_number"
                                        id="tax_number"
                                        value={props.values.phone_number}
                                    />
                                    <p className={s.error}>
                                        {props.errors.tax_number}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label htmlFor="second_name">Прізвище:</label>
                                    <Field id="second_name" name="second_name"/>
                                    <p className={s.error}>
                                        {props.errors.second_name}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label htmlFor="username">Ім'я:</label>
                                    <Field id="username" name="username"/>
                                    <p className={s.error}>
                                        {props.errors.username}
                                    </p>
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label htmlFor="third_name">По батькові:</label>
                                    <Field id="third_name" name="third_name"/>
                                    <p className={s.error}>
                                        {props.errors.third_name}
                                    </p>
                                </div>
                            </>
                        }

                        <button
                            className={s.registerButton}
                            type="submit"
                            ref={submitButtonRef}
                            disabled={!props.values.profile_type}
                        >Продовжити реєстрацію</button>
                    </Form>
                }
            </Formik>
        )
    }

    export default SecondStage;