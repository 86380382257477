import React, {useContext} from 'react';
import s from './CabinetSideBar.module.css';
import {NavLink} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

function CabinetSideBar(props) {

    const auth = useContext(AuthContext);

    return (
        <div className={s.SideBar}>
            <div className={s.left}>
                <ul>
                    <h1>Мій кабінет</h1>
                    <li><NavLink to="/mylots/auction/" activeClassName={s.active}><i className="fas fa-layer-group"/>Оголошення</NavLink></li>
                    <li><NavLink to="/invoices/" activeClassName={s.active}><i className="fas fa-file-invoice-dollar"/>Мої рахунки</NavLink></li>
                    <li><NavLink to="/applications/" activeClassName={s.active}><i className="fas fa-file-contract"/>Заяви на участь</NavLink></li>
                    <li><NavLink to="/documents/" activeClassName={s.active}><i className="fas fa-file-image"/>Мої документи</NavLink></li>
                    <li><NavLink to="/protocols/" activeClassName={s.active}><i className="fas fa-file-powerpoint"/>Мої протоколи</NavLink></li>
                    <li><NavLink to="/contracts/" activeClassName={s.active}><i className="fas fa-file-alt"/>Мої договори</NavLink></li>
                    <li><NavLink to="/notifications/" activeClassName={s.active}><i className="fas fa-bell"/>Усі сповіщення</NavLink></li>
                    <li><NavLink to="/4235" activeClassName={s.active}><i className="fas fa-headset"/>Техпідтримка</NavLink></li>
                    <li><NavLink to="/profile/" activeClassName={s.active}><i className="fas fa-cog"/>Налаштування</NavLink></li>
                    <p onClick={auth.logout} className={s.logout}><i className="fas fa-sign-out-alt"/>Вийти</p>
                </ul>

            </div>
            <div className={s.right}>
                {props.children}
            </div>
        </div>
    )
}

export default CabinetSideBar;