import React, {useEffect, useState, useRef} from 'react';
import {useHttp} from '../../hooks/HttpHook';
import {useParams} from 'react-router-dom';
import config from '../../config/config.js';
import mapMarker from '../../assets/img/mapMarker.svg';
import 'leaflet/dist/leaflet.css';
import '../../assets/styles/leaflet-styles.css';
import 'leaflet';
import 'leaflet.markercluster';
import ErrorHandler from "../../utils/ErrorLoger";


function OtherLotsMap(props){
    const id = useParams().params;
    let map;
    let markerGroup = new window.L.MarkerClusterGroup({showCoverageOnHover:false});
    const mainIcon = window.L.icon({
        iconUrl: mapMarker,

        iconSize:     [38, 95], // size of the icon
        iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });


    const {request} = useHttp();
    const [markers, setMarkers] = useState([]);
    const [landPosition, setLandPosition] = useState([35,-79]);

    const displayMarkers = async (bounds) =>{
        //window.L.rectangle(bounds).addTo(map);
        //deleteMarkers();

        console.log('markers', markers)
        const ne = bounds.getNorthEast(),
            sw = bounds.getSouthWest();
        let data;
        try{
            if(props.type=="land") data = await request(config.baseUrl + `/api/v1/land-market/lands/in-bounds/?ne=${ne.lat},${ne.lng}&sw=${sw.lat},${sw.lng}`,'GET');
            if(props.type=="lot") data = await request(config.baseUrl + `/api/v1/auctions/in-bounds/?ne=${ne.lat},${ne.lng}&sw=${sw.lat},${sw.lng}`,'GET');
            console.log(data);
        }catch(e){
            ErrorHandler(e)
        }

        map.removeLayer(markerGroup);
        markerGroup = new window.L.MarkerClusterGroup({showCoverageOnHover:false}).addTo(map);
        data.forEach((point)=>{
            if(props.defaultMarkerId==point.id || props.defaultMarkerId==point.auction_id){
                const marker = window.L.marker(point.position, {icon: mainIcon}).addTo(markerGroup);
            }else {
                const text = props.type == "lot"? "Перейти до лоту":"Перейти до оголошення";
                let linkId = props.type =="lot"? point.auction_id:point.id;
                console.log(linkId);

                const marker = window.L.marker(point.position, {icon: mainIcon, opacity: 0.5}).addTo(markerGroup);
                marker.bindPopup(`
                <img src="${config.baseUrl + point.photo}">
                <div>
                    <a target="_blank"
                       href=${point?'http://map.land.gov.ua/kadastrova-karta?cadnum='+point.cadastral_number:''}>
                        <p>${point.cadastral_number}</p>
                    </a>
                    <p>Площа: ${point.area}га</p>
                    <a href="${config.baseUrl + '/'+ props.type +'/' + linkId}" class="popup-button">${text}</a>
                </div>
            `, {autoPan:false});
            }
        })
    }

    function initMap(){
        map = window.L.map('map', {
            center: props.center || [49,31],
            zoom: 10,
            tap: false
        });
        window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
        markerGroup = new window.L.MarkerClusterGroup({showCoverageOnHover:false}).addTo(map);
        displayMarkers(map.getBounds());
        //map.fitBounds(markerGroup.getBounds());
        map.on('moveend',() => displayMarkers(map.getBounds()));
    }


    useEffect(()=>{
        if(landPosition) initMap();
    },[landPosition]);
    return(
        <div style={props.style?props.style:{height: 'calc(100vh - 80px)', width: '100vw'}} id="map"/>
    )
}

export default OtherLotsMap;