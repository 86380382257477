import React, {useEffect, useState, useRef} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import s from './Saved.module.css';
import {NavLink} from 'react-router-dom';
import {useHttp} from '../../hooks/HttpHook';
import InputMask from 'react-input-mask';
import config from '../../config/config.js';
import Card from '../../components/Card/Card';
import InputRange from 'react-input-range';
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import "react-input-range/lib/css/index.css";
function Saved(props){
    const [lots, setLots] = useState([]);
    const [filter, setFilter] = useState({});
    const {request} = useHttp();


    async function getLots(){
            const data = await request(config.baseUrl + '/api/v1/user/saved-lands/');
            if(data) setLots(data.results);
    }

    useEffect(()=>{
        getLots();
    },[]);

    return(
        <div  className={s.Saved}>
            <div className={s.content}>
                {lots.length>0?lots.map((lot, i)=>{
                    let item;
                    if(lot.auction) item = {...lot.auction, land: {...lot}};
                    else item = {...lot};
                    console.log(item);
                    return <Card saved={true} type={item.land?"auction":"fixed"} item={item} key={i}/>
                }):<h3 className={s.notFound}>Оголошень не знайдено</h3>}
            </div>

        </div>

    )
}

export default Saved;