import React, {useEffect, useState} from 'react';
import s from './NewLot.module.css';
import {ListItem, List, Link, Grid, Item, Box} from "@mui/material";
import image from '../../assets/img/topSearchBackground.jpg';
import config from "../../config/config";
import {useParams, useHistory} from 'react-router-dom';
import {useHttp} from "../../hooks/HttpHook";
import LotInfo from "./components/LotInfo";
import Menu from "./components/Menu";
import LotDetails from "./components/LotDetails";
import Participation from "../../components/Participation/Participation";
import ErrorHandler from "../../utils/ErrorLoger";

function NewLot() {
    const [auctionInfo, setAuctionInfo] = useState({});
    const [currentImage, setCurrentImage] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [isParticipation, setIsParticipation] = useState(false);
    const lotNumber = useParams().number;
    const history = useHistory();
    const {request} = useHttp();

    const saveHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + `/api/v1/auctions/${auctionInfo.id}/add-to-user-saved/`,'POST');
            if(data.status === 'OK') setIsSaved(true);
        } catch (e) {
            ErrorHandler(e)
        }
    }

    const removeHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + `/api/v1/auctions/${auctionInfo.id}/remove-from-user-saved/`,'POST');
            if(data.status === 'OK') setIsSaved(false);
        } catch (e) {
            ErrorHandler(e)
        }
    }

    function getData(){
        try{
            request(`${config.baseUrl}/api/v1/auctions/${lotNumber}/`,'GET').then((data)=>{
                if(data && data.lot) {
                    setAuctionInfo(data);
                    setCurrentImage(config.baseUrl + data.lot.photos[0].url);
                    setIsSaved(data.lot.is_saved);
                }
            });
        }catch(e){
            ErrorHandler(e)
        }
    }

    const OtherPhotos = () => {
        return (
            <div className={s.otherPhotos}>
                {auctionInfo.lot && auctionInfo.lot.photos.map((item, i)=>
                    <img
                        key={i}
                        src={config.baseUrl + item.url}
                        onClick={()=>setCurrentImage(config.baseUrl + item.url)}
                    />
                )}
            </div>
        )
    }

    const SubscribeButton = () => {
        return (
            <>
                {isSaved?
                    <p onClick={removeHandler}>Не стежити</p>:
                    <p onClick={saveHandler}><b>Стежити</b></p>
                }
            </>
        )
    }

    const AuctionButton = ({status}) => {
        if(+Date.now() > (auctionInfo.entrant_period_start_at_timestamp*1000) && +Date.now() < (auctionInfo.entrant_period_end_at_timestamp*1000))
            return <button onClick={()=>{setIsParticipation(true)}} className={s.blueButton}>Вступити в аукціон</button>
        if(+Date.now() > (auctionInfo.date_start_timestamp*1000))
            return <button onClick={()=>{history.push(`/room/${auctionInfo.number}/`)}} className={s.blueButton}>Приєднатись до аукціону</button>
        else return <></>
    }


    useEffect(()=>{
        getData();
    },[])

    return(
        <>
            {isParticipation?
                <Participation
                    lotNumber={auctionInfo.number}
                    lotId={auctionInfo.id}
                    lot={auctionInfo.lot}
                />:
                <div className={s.wrapper}>
                    <Menu/>
                    <div className={s.content}>
                        <div className={s.mainInfo}>
                            <div className={s.imagesBox}>
                                <img src={currentImage}/>
                                <OtherPhotos/>
                            </div>
                            <div className={s.titleBox}>
                                <h3>{auctionInfo.lot && auctionInfo.lot.theme}</h3>
                                <AuctionButton/>
                                <SubscribeButton/>
                            </div>
                            <LotDetails auctionInfo={auctionInfo} lotNumber={lotNumber}/>
                        </div>
                        <LotInfo auctionInfo={auctionInfo}/>
                    </div>
                </div>}
        </>
    )
}

export default NewLot;