import React, {useEffect, useState} from 'react';
import s from './OrganizerLot.module.css';
import image from '../../assets/img/topSearchBackground.jpg';
import {Link} from "react-router-dom";
import checkmarkIcon from '../../assets/img/checkmark-blue.png';
import config from '../../config/config';
import ContractComponent from "./components/ContractComponent";
import DocumentsComponent from "./components/DocumentsComponent";
import {useHttp} from "../../hooks/HttpHook";
import {useParams, useHistory} from 'react-router-dom';
import ErrorHandler from "../../utils/ErrorLoger";
import Loading from "../../components/Loading/Loading";
function OrganizerLot() {
    const [item, setItem] = useState({});
    const number = useParams().number;
    const history = useHistory();
    const {request, loading} = useHttp();

    const getLotInfo = async () => {
        try{
            let data = await request(config.baseUrl+`/api/v1/auctions/organizer/auction/${number}/single/`, 'GET');
            if(data) setItem(data);
        }catch (e) {
            ErrorHandler(e)
        }
    }

    useEffect( ()=>{
        getLotInfo();
    },[])

    const CardComponent = () => {
        return(
            <div className={s.CardComponent}>
                <div className={s.cardTop}>
                    <p className={s.backButton} onClick={()=>{history.push('/mylots/auction/')}}>
                        <i className="fas fa-long-arrow-alt-left"/>
                        Назад
                    </p>
                    <p>Номер земельних торгів</p>
                </div>
                <div className={s.cardContent}>
                    <img src={config.baseUrl + item.photo_url}/>
                    <div className={s.cardContentText}>
                        <div className={s.cardContentTop}>
                            <p>Оголошення про проведення земельних торгів:</p>
                            <Link to="/">{item.theme}</Link>
                        </div>
                        <div className={s.cardContentBottom}>
                            <Link to={`/edit/${item.number}/`} className={s.viewButton}>Переглянути</Link>
                            <button className={s.cancelButton}>Скасувати торги</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    const PaymentComponent = () => {
        const {request} = useHttp();
        const confirmPayment = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/${item.id}/mark-as-payed/`,'PUT');
                if(data){
                    getLotInfo();
                }
            }catch (e) {
                ErrorHandler(e)
            }
        }

        return(
            <div className={s.PaymentComponent}>
                <h2 className={s.title}>Оплата</h2>
                <p className={s.paymentStatus}>Статус:</p>
                <p className={s.paymentStatusText}>Очікується</p>
                <h5 className={s.actionsTitle}>Доступні дії:</h5>
                <div className={s.buttonsTop}>
                    <button onClick={confirmPayment}>
                        {item.auction_status==="ENDED"?
                            <img src={checkmarkIcon}/>:
                            <div className={s.circle}/>
                        }
                        Підтвердити оплату</button>
                    <p>або</p>
                    <button className={s.buttonDisabled}><i className="fas fa-times"/>Сформувати акт про несплату</button>
                </div>
            </div>
        )
    }

    return(
        <div className={s.LotItem}>
            {loading && <Loading/>}
            {item.id &&
            <>
                <CardComponent/>
                <DocumentsComponent item={item}/>
                <ContractComponent onUpdate={getLotInfo} item={item}/>
                {(item.auction_status === "AWAITING_PAYMENT" || item.auction_status === "ENDED") && <PaymentComponent/>}
            </>
            }
        </div>
    )
}

export default OrganizerLot;