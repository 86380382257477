import React, {useEffect, useState} from 'react'
import s from './EditLotRedesign.module.css'
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import FileDropBox from "../../components/FileDropBox/FileDropBox";
import {Field, FieldArray, ErrorMessage, Form, Formik} from "formik";
import DatePicker from "react-datepicker";
import {useParams} from "react-router-dom";
import { addDays } from '@progress/kendo-date-math';
import InputMask from "react-input-mask";
import Loading from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
import ErrorHandler from "../../utils/ErrorLoger";

function EditLot() {
    const [lotInfo, setLotInfo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [modalText, setModalText] = useState(null);
    const [buttonSettings, setButtonSettings] = useState({
        sign: true,
        publish: true,
        edit: true,
        save: true
    })
    const {request, requestErrors, loading} = useHttp();
    const auctionNumber = useParams().number;

    async function signLot() {
        try {
            let data = await request(`${config.baseUrl}/api/v1/auctions/${auctionNumber}/sign/`,'POST');
            if(data && data.status==='OK') setModalText('Оголошення було успішно підписано')
        } catch (e) {
            ErrorHandler(e)
        }
    }

    async function publishLot() {
        try {
            let data = await request(`${config.baseUrl}/api/v1/auctions/${auctionNumber}/publish/`,'POST');
            if(data && data.status_code==='OK') setModalText('Оголошення було успішно опубліковано')
        } catch (e) {
            ErrorHandler(e)
        }
    }

    async function editLot() {
        try {
            let data = await request(`${config.baseUrl}/api/v1/auctions/${lotInfo.id}/update/`,'PUT');
            if(data && data.status==='OK') setModalText('Оголошення було успішно відредаговано')
        } catch (e) {
            ErrorHandler(e)
        }
    }

    async function getData(){
        try {
            let data = await request(`${config.baseUrl}/api/v1/auctions/organizer/auction/${auctionNumber}/`,'GET');
            if(data.lot) setLotInfo(data);
        } catch (e) {
            ErrorHandler(e)
        }
    }


    useEffect(()=>{
        getData();
    },[])
    return (
        <>
            {!lotInfo?
                <Loading/>:
                <div className={s.EditLotRedesign}>

                    {modalText &&
                        <Modal isVisible={true} onClose={()=>{
                            setModalText(null);
                        }}>
                            <div className={s.successModal}>
                                <h1>{modalText}</h1>
                                <button onClick={()=>{
                                    setModalText(null);
                                }}>Готово</button>
                            </div>
                        </Modal>
                    }

                    <h1 className={s.title}>Оголошення про аукціон</h1>
                    <div className={s.content}>
                        <Formik
                            initialValues={{
                                ...lotInfo,
                                lot:{
                                    ...lotInfo.lot,
                                    purpose_info: lotInfo.lot.purpose,
                                    purpose_id: lotInfo.lot.purpose.id,



                                    documents_info: lotInfo.lot.documents,
                                    documents: lotInfo.lot.documents.map(el=>el.id),
                                    photos_info: lotInfo.lot.photos,
                                    photos: lotInfo.lot.photos.map(el=>el.id),

                                    location:{
                                        ...lotInfo.lot.location,
                                        district_info: lotInfo.lot.location.district,
                                        district_id: lotInfo.lot.location.district.id,


                                        region_info: lotInfo.lot.location.region,
                                        region_id: lotInfo.lot.location.region.id,

                                        settlement_info: lotInfo.lot.location.settlement,
                                        settlement_id: lotInfo.lot.location.settlement.id,

                                        //settlement
                                    }
                                }
                            }}
                            onSubmit={async (values)=>{

                            }}
                        >
                            {props => (
                                <fieldset disabled={!isEditing}>
                                <Form className={s.form}>
                                    <h3 onClick={()=>{console.log(props.values)}} className={s.subtitle}>Оголошення № 000 000 001</h3>
                                    <div className={s.inputItem}>
                                        <label>Тема оголошення:</label>
                                        <Field name="lot.theme"/>
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Тип оголошення:</label>
                                            <CustomSelect
                                                disabled={!isEditing}
                                                data = {[{id:0, name:'Аукціон'}]}
                                                onSelect={()=>{}}
                                                defaultValueByName={'Аукціон'}
                                            />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Вид торгів:</label>
                                            <CustomSelect
                                                defaultValueId={props.values.type}
                                                disabled={!isEditing}
                                                onSelect={(values, id) => {
                                                    if(id==='SECONDARY') props.setFieldValue("primary_auction_number",null,false);
                                                    props.setFieldValue("auction_type",id,false);
                                                }}
                                                data={[
                                                    { name: "Первинні", id: 'PRIMARY' },
                                                    { name: "Вторинні", id: 'SECONDARY' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {props.values.auction_type==='SECONDARY'?
                                    <div className={s.inputItem+' '+s.short}>
                                        <label>Номер вторинного аукціону</label>
                                        <Field name="primary_auction_number"/>
                                    </div>:null}
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>

                                            <label>Тип процедури:</label>
                                            <CustomSelect
                                                defaultValueByName={props.values.lot.procedure_type}
                                                disabled={!isEditing}
                                                onSelect={(values, id) => {
                                                    props.setFieldValue("lot.procedure_type_id",id,false);
                                                }}
                                                url="/api/v1/land-market/lands/procedure-types/"
                                            />
                                        </div>
                                        <div className={s.inputItem}>

                                            <label>Кількість лотів:</label>
                                            <Field name="lots_count"/>
                                        </div>
                                    </div>
                                    <div className={s.inputItem + ' ' + s.short}>
                                        <label>Дата проведення аукціону:</label>
                                        <DatePicker
                                            disabled={!isEditing}
                                            dateFormat="yyyy-MM-dd HH:mm"
                                            selected={props.values.date_start_timestamp*1000}
                                            onChange={(date)=>{
                                                props.setFieldValue("date_start", date.toISOString(),false);
                                                props.setFieldValue("date_start_timestamp", +date,false);
                                            }}
                                            timeFormat="HH:mm"
                                            showTimeSelect
                                            locale="ua-Uk"
                                            minDate={addDays(new Date(),30)}
                                            maxDate={addDays(new Date(),45)}

                                        />
                                    </div>
                                    <div className={s.itemRowTriple}>
                                        <div className={s.inputItem}>
                                            <label>Період редагування:</label>
                                            <input value={
                                                props.values.editing_period_start_at?
                                                    props.values.editing_period_start_at + ' - ' + props.values.editing_period_end_at:
                                                    null
                                            } disabled />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Період уточнень:</label>
                                            <input value={
                                                props.values.editing_period_start_at?
                                                    props.values.editing_period_end_at + ' - ' + props.values.entrant_period_end_at:
                                                    null
                                            } disabled/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Період подання пропозицій:</label>
                                            <input value={
                                                props.values.editing_period_start_at?
                                                    props.values.entrant_period_start_at + ' - ' + props.values.entrant_period_end_at:
                                                    null
                                            } disabled/>
                                        </div>
                                    </div>
                                    <div className={s.gap}/>
                                    <hr/>

                                    <h3 className={s.subtitle+' '+s.blue}>Інформація про лот</h3>
                                    <div className={s.inputItem}>
                                        <label>Номер лоту:</label>
                                        <Field name="number"/>
                                    </div>
                                    <FileDropBox
                                        type="photo"
                                        header="Фото ділянки"
                                        title="Доступні формати: .jpg, .jpeg, .tiff, .png . Максимальний розмір файлу не повинен перевищувати 10 Mb."
                                        subtitle="Перетягніть фото сюди
        або вкажіть шлях до них на вашому комп’ютері"
                                        accept=".jpg, .jpeg, .tiff, .png"
                                        onSelect={(ids)=>{props.setFieldValue("lot.photo_ids", ids,false)}}
                                    />
                                    <div className={s.inputItem}>
                                        <label>Опис лоту:</label>
                                        <Field name="lot.description"/>
                                    </div>
                                    <div className={s.gap}/>
                                    <h3 className={s.subtitle}>Місцезнаходження</h3>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Область:</label>
                                            <CustomSelect
                                                disabled={!isEditing}
                                                defaultValueId={props.values.lot.location.region_id}
                                                onSelect={(value,id) => {
                                                    props.setFieldValue("lot.location.region_id",id,false);
                                                }}
                                                url="/api/v1/location/regions/"
                                            />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Район:</label>
                                            <CustomSelect
                                                disabled={!isEditing}
                                                defaultValueId={props.values.lot.location.district_id}
                                                onSelect={(value,id) => {
                                                    props.setFieldValue("lot.location.district_id",id,false);
                                                }}
                                                url="/api/v1/location/districts/"
                                            />
                                        </div>
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Населенний пункт:</label>
                                            <CustomSelect
                                                disabled={!isEditing}
                                                defaultValueId={props.values.lot.location.settlement_id}
                                                onSelect={(value,id) => {
                                                    props.setFieldValue("lot.location.settlement_id",id,false);
                                                }}
                                                url="/api/v1/location/settlements/"
                                            />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Вулиця:</label>
                                            <Field name="lot.location.street"/>
                                        </div>
                                    </div>
                                    <div className={s.itemRowTriple}>
                                        <div className={s.inputItem}>
                                            <label>Кадастровий номер:</label>
                                            <Field name="lot.cadastral_number"/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Площа (га):</label>
                                            <Field name="lot.area"/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Форма власності:</label>
                                            <CustomSelect
                                                defaultValueId={props.values.lot.ownership_type}
                                                disabled={!isEditing}
                                                onSelect={(values, id) => {
                                                    props.setFieldValue("lot.ownership_type",id,false);
                                                }}
                                                data={[
                                                    { name: "Комунальна", id: 'COMMUNAL' },
                                                    { name: "Державна", id: 'STATE' },
                                                    { name: "Приватна", id: 'PRIVATE' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Категорія земель:</label>
                                            <CustomSelect
                                                defaultValueByName={props.values.lot.category}
                                                disabled={!isEditing}
                                                onSelect={(values, id) => {
                                                    props.setFieldValue("lot.category_id",id,false);
                                                }}
                                                url="/api/v1/land-market/lands/categories/"
                                            />

                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Цільове призначення:</label>
                                            <CustomSelect
                                                disabled={!isEditing}
                                                defaultValueId={props.values.lot.purpose_id}
                                                onSelect={(values, id) => {
                                                    props.setFieldValue("lot.purpose_id",id,false);
                                                }}
                                                url="/api/v1/land-market/lands/purposes/"
                                            />
                                        </div>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Співвласники (за наявності):</label>
                                        <Field name="lot.co_owners"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Обтяження прав на земельну ділянку, обмеження у використанні земель (за наявності):</label>
                                        <Field name="lot.restrictions"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Містобудівні умови земельної ділянки (за наявності):</label>
                                        <Field name="lot.city_building_conditions"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Інженерні мережі (за наявності):</label>
                                        <Field name="lot.engineering_networks"/>
                                    </div>
                                    <div className={s.inputItem + ' ' + s.short}>

                                        {/*//TODO check if works fine*/}
                                        <label>Наявність суб’єкта переважного права*:</label>
                                        <CustomSelect
                                            defaultValueId={props.values.lot.has_preference_subjects}
                                            disabled={!isEditing}
                                            onSelect={(values, id) => {
                                                props.setFieldValue("lot.has_preference_subjects",id,false);
                                                if(id=='true') props.setFieldValue("lot.preference_subjects",[{
                                                    name:'',
                                                    preference_type:''
                                                }],false);
                                                else if(id=='false') props.setFieldValue("lot.preference_subjects",[],false);

                                            }}
                                            data={[
                                                { name: "Так", id: true },
                                                { name: "Ні", id: false },
                                            ]}
                                        />
                                    </div>
                                    {props.values.lot.has_preference_subjects &&
                                    <FieldArray name="lot.preference_subjects">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {props.values.lot.preference_subjects.length > 0 &&
                                                props.values.lot.preference_subjects.map((subject, index) => (
                                                    <div key={index}>
                                                        <div className={s.inputItem}>
                                                            <label>Повне найменування або прізвище, ім’я, по батькові суб’єкта переважного права:</label>
                                                            <Field name={`lot.preference_subjects.${index}.name`}/>
                                                        </div>
                                                        <div className={s.itemRow}>
                                                            <div className={s.inputItem}>
                                                                <label>Вибрати чергу:</label>
                                                                <CustomSelect
                                                                    disabled={!isEditing}
                                                                    onSelect={(values, id) => {
                                                                        props.setFieldValue(`lot.preference_subjects.${index}.preference_type`,id,false);
                                                                    }}
                                                                    data={[
                                                                        { name: "Суб’єкт переважного права 1 черги", id: 'SPECIAL_USE_PREFERENCE' },
                                                                        { name: "Суб’єкт переважного права 2 черги", id: 'RENTER_PREFERENCE' },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div className={s.inputItem}>
                                                                <label>Підстава:</label>
                                                                {/*<CustomSelect data = {[{id:0, name:'test'}]}/>*/}
                                                                <input disabled/>
                                                            </div>
                                                        </div>

                                                        {index!==0?
                                                            <div className={s.inputItem}>
                                                                <div className={s.removeAccountBtn} onClick={() => remove(index)}>
                                                                    <div className={s.removeAccountBtnPlus}>
                                                                        <p>+</p>
                                                                    </div>
                                                                    <p>Видалити суб'єкта</p>
                                                                </div>
                                                            </div>:null}
                                                        <div className={s.line}/>

                                                    </div>
                                                ))}
                                                <div className={s.inputItem}>
                                                    <div className={s.removeAccountBtn+' '+s.blueBtn} onClick={() => push({
                                                        bank_name:'',
                                                        bank_tax_number:'',
                                                        bank_code: '',
                                                        iban_number:'',
                                                        currency:'',
                                                        account_purpose:''
                                                    })}>
                                                        <div className={s.removeAccountBtnPlus}>
                                                            <p>+</p>
                                                        </div>
                                                        <p>Додати суб'єкта</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>}


                                    <div className={s.inputItem}>
                                        <label>Дата реєстрації Організатором наміру щодо продажу земельної ділянки у Державному реєстрі речових прав на нерухоме майно:</label>
                                        <DatePicker
                                            disabled={!isEditing}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText={props.values.lot.registration_date_of_intention_to_sell_in_state_register}
                                            onChange={(date)=>{
                                                const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                                props.setFieldValue("lot.registration_date_of_intention_to_sell_in_state_register", stringDate,false);
                                                props.setFieldValue("lot.registration_date_of_intention_to_sell_in_state_register_timestamp", +date,false);
                                            }}
                                            locale="ua-Uk"
                                        />
                                    </div>

                                    <div className={s.line}/>

                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Експертна / Нормативна грошова оцінка:</label>
                                            <Field name="lot.expert_estimated_cost"/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Сума витрат (видатків), здійснених на підготовку лота:</label>
                                            <Field name="lot.preparation_expenses"/>
                                        </div>
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Стартова ціна:</label>
                                            <Field name="starting_price"/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Розмір реєстраційного внеску:</label>
                                            <input disabled/>
                                        </div>
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Крок аукціону:</label>
                                            <input disabled/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Розмір гарантійного внеску:</label>
                                            <input disabled/>
                                        </div>
                                    </div>

                                    <div className={s.line}/>

                                    <h3 className={s.subtitle}>Документи лоту</h3>

                                    <FileDropBox
                                        type="document"
                                        header="Документи лоту"
                                        title="Доступні формати: .jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx . Максимальний розмір файлу не повинен перевищувати 20 Mb."
                                        subtitle="Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері"
                                        accept=".jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx"
                                        onSelect={(ids)=>{props.setFieldValue("lot.document_ids", ids,false)}}
                                    />

                                    <hr/>

                                    <h3 className={s.subtitle}>Організатор земельних торгів</h3>
                                    <div className={s.inputItem}>
                                        <label>Повне найменування юридичної особи або повне прізчище, ім’я по батькові фізичної особи:</label>
                                        <Field name="organizer_name"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Адреса:</label>
                                        <Field name="organizer_address"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Код ЄДРПОУ або ІПН:</label>
                                        <Field name="organizer_tax_number"/>
                                    </div>

                                    <div className={s.line}/>

                                    <h3 onClick={()=>{console.log(props.values)}} className={s.subtitle}>Банківські реквізити</h3>



                                    <FieldArray name="bank_accounts">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {props.values.bank_accounts.length > 0 &&
                                                props.values.bank_accounts.map((friend, index) => (
                                                    <div key={index}>
                                                        <div className={s.itemRowTriple}>
                                                            <div className={s.inputItem}>
                                                                <label>Назва банку</label>
                                                                <Field name={`bank_accounts.${index}.bank_name`}/>
                                                            </div>
                                                            <div className={s.inputItem}>
                                                                <label>Код ЄДРПОУ банку:</label>
                                                                <InputMask
                                                                    onChange={(e) => {
                                                                        props.setFieldValue(`bank_accounts.${index}.bank_tax_number`,e.target.value,false);
                                                                    }}
                                                                    mask="99999999"
                                                                />
                                                            </div>
                                                            <div className={s.inputItem}>
                                                                <label>МФО банку:</label>
                                                                <InputMask
                                                                    onChange={(e) => {
                                                                        props.setFieldValue(`bank_accounts.${index}.bank_code`,e.target.value,false);
                                                                    }}
                                                                    mask="999999"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={s.itemRowTriple}>
                                                            <div className={s.inputItem}>
                                                                <label>ІВАN:</label>
                                                                <InputMask
                                                                    onChange={(e) => {
                                                                        props.setFieldValue(`bank_accounts.${index}.iban_number`,e.target.value,false);
                                                                    }}
                                                                    mask="UA999999999999999999999999999"
                                                                />
                                                            </div>
                                                            <div className={s.inputItem}>
                                                                <label>Валюта рахунка:</label>
                                                                <CustomSelect
                                                                    defaultValueByName={props.values.bank_accounts[index].currency}
                                                                    disabled={!isEditing}
                                                                    onSelect={(values, id) => {
                                                                        props.setFieldValue(`bank_accounts.${index}.currency`,id,false);
                                                                    }}
                                                                    data={[
                                                                        { name: "UAH", id: 'UAH' },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div className={s.inputItem}>
                                                                <label>Призначення рахунка:</label>
                                                                <CustomSelect
                                                                    disabled={!isEditing}
                                                                    onSelect={(values, id) => {
                                                                        props.setFieldValue(`bank_accounts.${index}.account_purpose`,id,false);
                                                                    }}
                                                                    data={[
                                                                        { name: "Всі платежі", id: 'ALL_PAYMENTS' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        {index!==0?<div className={s.inputItem}>
                                                            <div className={s.removeAccountBtn} onClick={() => remove(index)}>
                                                                <div className={s.removeAccountBtnPlus}>
                                                                    <p>+</p>
                                                                </div>
                                                                <p>Видалити рахунок</p>
                                                            </div>
                                                        </div>:null}
                                                        <div className={s.line}/>
                                                    </div>

                                                ))}
                                                <div className={s.inputItem}>
                                                    <div className={s.removeAccountBtn+' '+s.blueBtn} onClick={() => push({
                                                        bank_name:'',
                                                        bank_tax_number:'',
                                                        bank_code: '',
                                                        iban_number:'',
                                                        currency:'',
                                                        account_purpose:''
                                                    })}>
                                                        <div className={s.removeAccountBtnPlus}>
                                                            <p>+</p>
                                                        </div>
                                                        <p>Додати рахунок</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>

                                    <div className={s.line}/>

                                    <h3 className={s.subtitle}>Контактна особа</h3>

                                    <div className={s.inputItem}>
                                        <label>Прізвище, ім’я, по батькові:</label>
                                        <Field name="contact_person_name"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>E-mail:</label>
                                        <Field name="contact_person_email"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Номер телефону:</label>
                                        <Field name="contact_person_phone_number"/>
                                    </div>
                                    {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                                </Form>
                                </fieldset>
                            )}
                        </Formik>
                        {/*//TODO make this button disabled and add sign modal*/}
                        <button onClick={signLot} className={s.saveButton}>Підписати</button>
                        <button onClick={publishLot} className={s.saveButton}>Опублікувати</button>
                        <button onClick={()=>setIsEditing(true)} className={s.saveButton}>Редагувати</button>
                        <button disabled={!isEditing} onClick={editLot} className={s.saveButton}>Зберегти</button>
                    </div>
                </div>
            }
        </>
    )
}


export default EditLot;