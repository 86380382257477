import React, {useContext, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import s from './Login.module.css';
import {NavLink} from 'react-router-dom';
import {AuthContext} from '../../context/AuthContext';
import InputMask from 'react-input-mask';
import {useHttp} from '../../hooks/HttpHook';
import config from '../../config/config.js';
import {GoogleLogin} from "react-google-login";
import ErrorHandler from "../../utils/ErrorLoger";

function Login(props){
const history = useHistory();
const auth = useContext(AuthContext);
const [form, setForm] = useState({});
const [error, setError] = useState(null);
const {request, requestErrors} = useHttp();
const phoneRef = useRef();
const [googleId, setGoogleId] = useState(null);
const [isGoogleRegistration, setGoogleRegistration] = useState(false);


const numberChangeHandler = (e) =>{
    const number = e.target.value.replace(/[^0-9]/g,'');
    setForm({...form, 'phone_number': '+' + number});
}

const changeHandler = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
}
const googleRegisterHandler = async (e) => {
    e.preventDefault();
    let data;
    const phoneNumber = '+' + phoneRef.current.value.replace(/[^0-9]/g,'');
    try{
        data = await request(config.baseUrl + '/api/v1/user/google/auth/', 'POST', {id_token:googleId, phone_number: phoneNumber});
        if(data && data.token && data.token.access){
            auth.login(data.token.access, data.token.refresh);
            props.renderHeader();
            switch(data.next_page){
                case 'home': history.push('/'); break;
                case 'verify_sms_code': history.push('/verify/'); break;
            }
        }
    }catch(e){
        ErrorHandler(e)
    }
}

const loginUser = async () => {
    let data;
    try{
        data = await request(config.baseUrl + '/api/v1/user/login/', 'POST', form);
    }catch(e){
        ErrorHandler(e)
    } 
    if(data && data.token && data.token.access){
        auth.login(data.token.access, data.token.refresh);
        switch(data.next_page){
            case 'home': history.push('/'); break;
            case 'verify_sms_code': history.push('/verify/'); break;
        }
    }
     
}   

const loginHandler = async (e) => {
    e.preventDefault();
    if(!form['phone_number']) setError('Введіть номер телефону');
    else if(!form['password']) setError('Введіть пароль');
    else loginUser();
    
}
const googleLoginSuccess = async (googleObj) => {
    console.log(googleObj);
    let data;
    try{
        data = await request(config.baseUrl + `/api/v1/user/google/user/${googleObj.googleId}/`, 'GET');
        if(data && data.phone_number){
            data = await request(config.baseUrl + `/api/v1/user/google/auth/`, 'POST',
                {phone_number: data.phone_number, id_token: googleObj.tokenId});
            if(data.token){
                auth.login(data.token.access, data.token.refresh);
                props.renderHeader();
            }
        }else {
            setGoogleRegistration(true);
            setGoogleId(googleObj.tokenId);
        }
        //auth.login(googleObj.tokenId, '');
    }catch(e){
        ErrorHandler(e)
    }
}

const googleLoginFailure = async (data) => {
    console.log(data);
}

    return(
        <div data-testid="login" className={s.Login}>
            <div className={s.background}>
            </div >
            {!isGoogleRegistration?<div className={s.logForm}>
            <form>
                <h1>Вхід</h1>
                {requestErrors?<h2 data-testid="register-errros" className={s.error}>{requestErrors}</h2>:error?<h2 data-testid="register-errros" className={s.error}>{error}</h2>:null}
                <InputMask mask="+38 (099) 999-99-99" type="text" name="phone_number" onChange={numberChangeHandler} placeholder="Номер телефону"/>
                <input type="password"name="password" onChange={changeHandler} placeholder="Пароль"/>
                <div className={s.bottomBar}>
                    <NavLink to='/restore'>Забули пароль?</NavLink>
                    <button onClick={loginHandler}>Увійти</button>
                </div>
               {/*<div className={s.bottomText}>*/}
               {/*     <p>або</p>*/}
               {/*</div>*/}
            </form>
                {/*<GoogleLogin*/}
                {/*    className={s.googleButt}*/}
                {/*    clientId={config.googleClientID}*/}
                {/*    onSuccess={googleLoginSuccess}*/}
                {/*    onFailure={googleLoginFailure}*/}
                {/*    buttonText="Увійти через Gmail"*/}
                {/*/>*/}
            <div className={s.regBox}>
                <p>Ще не зареєстровані?</p>
                <NavLink to="/register/">Зареєструватись</NavLink>
            </div>
            </div>:
                <div className={s.logForm}>
                    <form>
                        <h1>Реєстрація</h1>
                        {requestErrors?<h2 className={s.error}>{requestErrors[0]!='user_with_google_id_not_exist'?requestErrors[0]:null}</h2>:null}
                        <InputMask ref={phoneRef} mask="+38 (099) 999-99-99" name="phone_number" placeholder="Номер телефону"/>
                        <div className={s.bottomBar}>
                            <button onClick={googleRegisterHandler}>Зареєструватись</button>
                        </div>
                    </form>
                </div>}
        </div>
    )
}

export default Login;