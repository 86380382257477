import React, {useContext} from "react";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import config from "../../../config/config";
import s from "../RegisterRedesign.module.css";
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import {useHttp} from "../../../hooks/HttpHook";
import {AuthContext} from "../../../context/AuthContext";
import Loading from "../../../components/Loading/Loading";
import ErrorHandler from "../../../utils/ErrorLoger";


const InputsComponent = () => {
        const auth = useContext(AuthContext)
        const {request, requestErrors, loading} = useHttp()
        const formValidationSchema = Yup.object().shape({
            phone_number: Yup.string()
                .required('Поле обов\'язкове')
                .min(13, 'Невірно введено')
                .max(13, 'Невірно введено'),
            email: Yup.string()
                .email('Невірно введено')
                .required('Поле обов\'язкове'),
            password: Yup.string()
                .min(8, 'Мінімальна довжина 8 символів')
                .required('Поле обов\'язкове'),
            password_repeat: Yup.string()
                .required('Поле обов\'язкове')
                .when("password", {
                    is: val => (!!(val && val.length > 0)),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Паролі не співпадають"
                    )
                })
        });


        return (
            <>
                {loading && <Loading/>}
            <Formik
                validationSchema={formValidationSchema}
                initialValues={{}}
                validateOnBlur={false}
                validateOnChange={false}
                //TODO fix this (form clears after request)
                //TODO fix google registration
                //TODO delete registration data after registration
                //TODO add resend SMS
                onSubmit={async (values,e)=>{
                    localStorage.setItem('registration_data', JSON.stringify(values));
                    try{
                        let data = await request(config.baseUrl + '/api/v1/user/registration/','POST', values);
                        if(data && data.token && data.token.access) {
                            auth.login(data.token.access, data.token.refresh)
                        }

                    }catch(e){
                        ErrorHandler(e)
                    }
                }}
            >
                {props =>
                    <Form>
                        <div className={s.inputItem + ' '+ s.short}>
                            <label htmlFor="phone_number">Номер телефону:</label>
                            <InputMask
                                onChange={(e)=>{props.setFieldValue("phone_number",'+' + e.target.value.replace(/[^0-9]/g,''),false)}}
                                mask="+38 (099) 999-99-99"
                                name="phone_number"
                                id="phone_number"
                                value={props.values.phone_number}
                            />
                            <p className={s.error}>
                                {props.errors.phone_number}
                            </p>
                        </div>
                        <div className={s.inputItem + ' '+ s.short}>
                            <label htmlFor="email">Email:</label>
                            <Field id="email" name="email"/>
                            <p className={s.error}>
                                {props.errors.email}
                            </p>
                        </div>
                        <div className={s.inputItem + ' '+ s.short}>
                            <label htmlFor="password">Пароль:</label>
                            <Field id="password" type="password" name="password"/>
                            <p className={s.error}>
                                {props.errors.password}
                            </p>
                        </div>
                        <div className={s.inputItem + ' '+ s.short}>
                            <label htmlFor="password_repeat">Повторити пароль:</label>
                            <Field id="password_repeat" type="password" name="password_repeat"/>
                            <p className={s.error}>
                                {props.errors.password_repeat}
                            </p>
                        </div>

                        {requestErrors && <p className={s.error}>{requestErrors[0]}</p>}
                        <button className={s.registerButton} type="submit">Продовжити реєстрацію</button>
                    </Form>
                }
            </Formik>
                <p className={s.alreadyRegistedText}>Вже зареєстровані?
                    <Link to="/login/">Увійти</Link>
                </p>
                </>
        )
    }

    export default InputsComponent;