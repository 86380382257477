import './App.css';
import {useState} from 'react';
import {useRoutes} from './routes';
import Header from './components/Header/Header'
import {AuthContext} from './context/AuthContext'
import { useAuth } from './hooks/AuthHook';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";

function App() {
const [renderStatus, setRenderStatus] = useState(false);
const {token, refreshToken, scope, login, logout, fetchRefreshToken} = useAuth();
const isAuthenticated = !!token;
const renderHeader = () => {
    setRenderStatus(!renderStatus);
}
const routes = useRoutes(scope, renderHeader);
const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#292527',
        },
        secondary: {
            main: '#103989',
        },
        divider: '#000000',
        text: {
            primary: '#292527',
        },
        background: {
            default: '#ffffff',
        },
    },
});

  return (
    <AuthContext.Provider value={{token, refreshToken, scope, login, logout, fetchRefreshToken, isAuthenticated}}>
        <ThemeProvider theme={theme}>
          <div className="App">
              <Header status={renderStatus} isLogined={scope==='main'?true:false}/>
            {routes}
          </div>
        </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
