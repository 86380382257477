import React, {useState} from 'react'
import s from './AddLotRedesign.module.css'
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {FileDrop} from "react-file-drop";
import PreviewItem from "../../components/PreviewItem/PreviewItem";
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import FileSign from "../../components/FileSign/FileSign";
import FileDropBox from "../../components/FileDropBox/FileDropBox";
import {Field, FieldArray, ErrorMessage, Form, Formik} from "formik";
import DatePicker from "react-datepicker";
import { addDays } from '@progress/kendo-date-math';
import InputMask from "react-input-mask";
import Modal from '../../components/Modal/Modal'
import {useHistory} from 'react-router-dom';
import ErrorHandler from "../../utils/ErrorLoger";
function AddLotRedesign() {
    const [startDate, setStartDate] = useState();
    const history = useHistory();
    const [modalText, setModalText] = useState(null);
    const {request, requestErrors} = useHttp();

    return (
        <div className={s.AddLotRedesign}>

            {modalText &&
                <Modal
                    onClose={()=>{
                        setModalText(null);
                        history.push('/');
                    }}
                    isVisible={true}
                >
                    <div className={s.successModal}>
                        <h1>{modalText}</h1>
                        <button onClick={()=>{
                            setModalText(null);
                            history.push('/');
                        }}>На головну</button>
                    </div>
                </Modal>
            }

            <h1 className={s.title}>Оголошення про аукціон</h1>
            <div className={s.content}>
                <Formik
                    initialValues={{
                        lot:{
                            has_preference_subjects:false,
                            preference_subjects:[]
                        },
                        bank_accounts:[{
                            bank_name:'',
                            bank_tax_number:'',
                            bank_code: '',
                            iban_number:'',
                            currency:'',
                            account_purpose:'',
                        }],
                    }}
                    onSubmit={async (values)=>{
                        let form = {...values};
                        if(!values.lot.has_preference_subjects) delete form.lot.preference_subjects;
                        try {
                            let data = await request(config.baseUrl + '/api/v1/auctions/create/', 'POST', form);
                            if(data.auction_id) setModalText('Оголошення було успішно створене')
                        } catch (e) {
                            ErrorHandler(e)
                        }
                    }}
                >
                    {props => (
                        <Form className={s.form}>
                <h3 onClick={()=>{console.log(props.values)}} className={s.subtitle}>Оголошення № 000 000 001</h3>
                <div className={s.inputItem}>
                    <label>Тема оголошення:</label>
                    <Field name="lot.theme"/>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Тип оголошення:</label>
                        <CustomSelect onSelect={()=>{}} data = {[{id:0, name:'Аукціон'}]}/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Вид торгів:</label>
                        <CustomSelect
                            onSelect={(values, id) => {
                                if(id==='PRIMARY') delete props.values.primary_auction_number;
                                props.setFieldValue("auction_type",id,false);
                            }}
                            data={[
                                { name: "Первинні", id: 'PRIMARY' },
                                { name: "Вторинні", id: 'SECONDARY' },
                            ]}
                        />
                    </div>
                </div>
                {props.values.auction_type==='SECONDARY'?
                <div className={s.inputItem+' '+s.short}>
                    <label>Номер вторинного аукціону</label>
                    <Field name="primary_auction_number"/>
                </div>:null}
                <div className={s.itemRow}>
                    <div className={s.inputItem}>

                        <label>Тип процедури:</label>
                        <CustomSelect
                            onSelect={(values, id) => {
                                props.setFieldValue("lot.procedure_type_id",id,false);
                            }}
                            url="/api/v1/land-market/lands/procedure-types/"
                        />
                    </div>
                    <div className={s.inputItem}>

                        <label>Кількість лотів:</label>
                        <Field name="lots_count"/>
                    </div>
                </div>
                <div className={s.inputItem + ' ' + s.short}>
                    <label>Дата проведення аукціону:</label>
                    <DatePicker
                        dateFormat="yyyy-MM-dd HH:mm"
                        selected={startDate}
                        onChange={(date)=>{
                            props.setFieldValue("date_start", date.toISOString(),false);
                            setStartDate(+date)
                        }}
                        timeFormat="HH:mm"
                        showTimeSelect
                        locale="ua-Uk"
                        minDate={addDays(new Date(),30)}
                        maxDate={addDays(new Date(),45)}

                    />
                </div>
                <div className={s.itemRowTriple}>
                    <div className={s.inputItem}>
                        <label>Період редагування:</label>
                        <input disabled />
                    </div>
                    <div className={s.inputItem}>
                        <label>Період уточнень:</label>
                        <input disabled/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Період подання пропозицій:</label>
                        <input disabled/>
                    </div>
                </div>
                <div className={s.gap}/>
                <hr/>

                <h3 className={s.subtitle+' '+s.blue}>Інформація про лот</h3>
                <div className={s.inputItem}>
                    <label>Номер лоту:</label>
                    <Field name="lot.number"/>
                </div>
                <FileDropBox
                    type="photo"
                    header="Фото ділянки"
                    title="Доступні формати: .jpg, .jpeg, .tiff, .png . Максимальний розмір файлу не повинен перевищувати 10 Mb."
                    subtitle="Перетягніть фото сюди
або вкажіть шлях до них на вашому комп’ютері"
                    accept=".jpg, .jpeg, .tiff, .png"
                    onSelect={(ids)=>{props.setFieldValue("lot.photo_ids", ids,false)}}
                />
                <div className={s.inputItem}>
                    <label>Опис лоту:</label>
                    <Field name="lot.description"/>
                </div>
                <div className={s.gap}/>
                <h3 className={s.subtitle}>Місцезнаходження</h3>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Область:</label>
                        <CustomSelect
                            onSelect={(value,id) => {
                                props.setFieldValue("lot.location.region_id",id,false);
                            }}
                            url="/api/v1/location/regions/"
                        />
                    </div>
                    <div className={s.inputItem}>
                        <label>Район:</label>
                        <CustomSelect
                            onSelect={(value,id) => {
                                props.setFieldValue("lot.location.district_id",id,false);
                            }}
                            url="/api/v1/location/districts/"
                        />
                    </div>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Населенний пункт:</label>
                        <CustomSelect
                            onSelect={(value,id) => {
                                props.setFieldValue("lot.location.settlement_id",id,false);
                            }}
                            url="/api/v1/location/settlements/"
                        />
                    </div>
                    <div className={s.inputItem}>
                        <label>Вулиця:</label>
                        <Field name="lot.location.street"/>
                    </div>
                </div>
                <div className={s.itemRowTriple}>
                    <div className={s.inputItem}>

                        <label>Кадастровий номер:</label>
                        <InputMask
                            onChange={(e) => {
                                props.setFieldValue(`lot.cadastral_number`,e.target.value,false);
                            }}
                            mask="9999999999:99:999:9999"
                        />
                    </div>
                    <div className={s.inputItem}>
                        <label>Площа (га):</label>
                        <Field name="lot.area"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Форма власності:</label>
                        <CustomSelect
                            onSelect={(values, id) => {
                                props.setFieldValue("lot.ownership_type",id,false);
                            }}
                            data={[
                                { name: "Комунальна", id: 'COMMUNAL' },
                                { name: "Державна", id: 'STATE' },
                                { name: "Приватна", id: 'PRIVATE' },
                            ]}
                        />
                    </div>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Категорія земель:</label>
                        <CustomSelect
                            onSelect={(values, id) => {
                                props.setFieldValue("lot.category_id",id,false);
                            }}
                            url="/api/v1/land-market/lands/categories/"
                        />

                    </div>
                    <div className={s.inputItem}>
                        <label>Цільове призначення:</label>
                        <CustomSelect
                            onSelect={(values, id) => {
                                props.setFieldValue("lot.purpose_id",id,false);
                            }}
                            url="/api/v1/land-market/lands/purposes/"
                        />
                    </div>
                </div>
                <div className={s.inputItem}>
                    <label>Співвласники (за наявності):</label>
                    <Field name="lot.co_owners"/>
                </div>
                <div className={s.inputItem}>
                    <label>Обтяження прав на земельну ділянку, обмеження у використанні земель (за наявності):</label>
                    <Field name="lot.restrictions"/>
                </div>
                <div className={s.inputItem}>
                    <label>Містобудівні умови земельної ділянки (за наявності):</label>
                    <Field name="lot.city_building_conditions"/>
                </div>
                <div className={s.inputItem}>
                    <label>Інженерні мережі (за наявності):</label>
                    <Field name="lot.engineering_networks"/>
                </div>
                <div className={s.inputItem + ' ' + s.short}>

                    {/*//TODO check if works fine*/}
                    <label onClick={()=>{console.log(props.values.lot.preference_subjects)}}>Наявність суб’єкта переважного права*:</label>
                    <CustomSelect
                        onSelect={(values, id) => {
                            props.setFieldValue("lot.has_preference_subjects",id,false);
                            if(id=='true') props.setFieldValue("lot.preference_subjects",[{
                                name:'',
                                preference_type:''
                            }],false);
                            else if(id=='false') props.setFieldValue("lot.preference_subjects",[],false);

                        }}
                        data={[
                            { name: "Так", id: true },
                            { name: "Ні", id: false },
                        ]}
                    />
                </div>
                            {props.values.lot.has_preference_subjects &&
                            <FieldArray name="lot.preference_subjects">
                                {({ insert, remove, push }) => (
                                    <div>
                                        {props.values.lot.preference_subjects.length > 0 &&
                                        props.values.lot.preference_subjects.map((subject, index) => (
                                            <div key={index}>
                                                <div className={s.inputItem}>
                                                    <label>Повне найменування або прізвище, ім’я, по батькові суб’єкта переважного права:</label>
                                                    <Field name={`lot.preference_subjects.${index}.name`}/>
                                                </div>
                                                <div className={s.itemRow}>
                                                    <div className={s.inputItem}>
                                                        <label>Вибрати чергу:</label>
                                                        <CustomSelect
                                                            onSelect={(values, id) => {
                                                                props.setFieldValue(`lot.preference_subjects.${index}.preference_type`,id,false);
                                                            }}
                                                            data={[
                                                                { name: "Суб’єкт переважного права 1 черги", id: 'SPECIAL_USE_PREFERENCE' },
                                                                { name: "Суб’єкт переважного права 2 черги", id: 'RENTER_PREFERENCE' },
                                                            ]}
                                                        />
                                                    </div>
                                                    <div className={s.inputItem}>
                                                        <label>Підстава:</label>
                                                        {/*<CustomSelect data = {[{id:0, name:'test'}]}/>*/}
                                                        <input disabled/>
                                                    </div>
                                                </div>

                                                {index!==0?
                                                <div className={s.inputItem}>
                                                    <div className={s.removeAccountBtn} onClick={() => remove(index)}>
                                                        <div className={s.removeAccountBtnPlus}>
                                                            <p>+</p>
                                                        </div>
                                                        <p>Видалити суб'єкта</p>
                                                    </div>
                                                </div>:null}
                                                <div className={s.line}/>

                                            </div>
                                        ))}
                                        <div className={s.inputItem}>
                                            <div className={s.removeAccountBtn+' '+s.blueBtn} onClick={() => push({
                                                bank_name:'',
                                                bank_tax_number:'',
                                                bank_code: '',
                                                iban_number:'',
                                                currency:'',
                                                account_purpose:''
                                            })}>
                                                <div className={s.removeAccountBtnPlus}>
                                                    <p>+</p>
                                                </div>
                                                <p>Додати суб'єкта</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>}


                <div className={s.inputItem}>
                    <label>Дата реєстрації Організатором наміру щодо продажу земельної ділянки у Державному реєстрі речових прав на нерухоме майно:</label>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={props.values.lot.registration_date_of_intention_to_sell_in_state_register_timestamp}
                        onChange={(date)=>{
                            const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                            props.setFieldValue("lot.registration_date_of_intention_to_sell_in_state_register", stringDate,false);
                            props.setFieldValue("lot.registration_date_of_intention_to_sell_in_state_register_timestamp", +date,false);
                        }}
                        locale="ua-Uk"
                    />
                </div>

                <div className={s.line}/>

                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Експертна / Нормативна грошова оцінка:</label>
                        <Field name="lot.expert_estimated_cost"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Сума витрат (видатків), здійснених на підготовку лота:</label>
                        <Field name="lot.preparation_expenses"/>
                    </div>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Стартова ціна:</label>
                        <Field name="starting_price"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Розмір реєстраційного внеску:</label>
                        <input disabled/>
                    </div>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Крок аукціону:</label>
                        <input disabled/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Розмір гарантійного внеску:</label>
                        <input disabled/>
                    </div>
                </div>

                <div className={s.line}/>

                <h3 className={s.subtitle}>Документи лоту</h3>

                <FileDropBox
                    type="document"
                    header="Документи лоту"
                    title="Доступні формати: .jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx . Максимальний розмір файлу не повинен перевищувати 20 Mb."
                    subtitle="Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері"
                    accept=".jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx"
                    onSelect={(ids)=>{props.setFieldValue("lot.document_ids", ids,false)}}
                />

                <hr/>

                <h3 className={s.subtitle}>Організатор земельних торгів</h3>
                <div className={s.inputItem}>
                    <label>Повне найменування юридичної особи або повне прізчище, ім’я по батькові фізичної особи:</label>
                    <Field name="organizer_name"/>
                </div>
                <div className={s.inputItem}>
                    <label>Адреса:</label>
                    <Field name="organizer_address"/>
                </div>
                <div className={s.inputItem}>
                    <label>Код ЄДРПОУ або ІПН:</label>
                    <InputMask
                        onChange={(e) => {
                            props.setFieldValue(`organizer_tax_number`,e.target.value,false);
                        }}
                        mask="99999999"
                    />
                </div>

                <div className={s.line}/>

                <h3 onClick={()=>{console.log(props.values)}} className={s.subtitle}>Банківські реквізити</h3>



                <FieldArray name="bank_accounts">
                    {({ insert, remove, push }) => (
                        <div>
                            {props.values.bank_accounts.length > 0 &&
                            props.values.bank_accounts.map((friend, index) => (
                                <div key={index}>
                                    <div className={s.itemRowTriple}>
                                        <div className={s.inputItem}>
                                            <label>Назва банку</label>
                                            <Field name={`bank_accounts.${index}.bank_name`}/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Код ЄДРПОУ банку:</label>
                                            <InputMask
                                                onChange={(e) => {
                                                    props.setFieldValue(`bank_accounts.${index}.bank_tax_number`,e.target.value,false);
                                                }}
                                                mask="99999999"
                                            />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>МФО банку:</label>
                                            <InputMask
                                                onChange={(e) => {
                                                    props.setFieldValue(`bank_accounts.${index}.bank_code`,e.target.value,false);
                                                }}
                                                mask="999999"
                                            />
                                        </div>
                                    </div>
                                    <div className={s.itemRowTriple}>
                                        <div className={s.inputItem}>
                                            <label>ІВАN:</label>
                                            <InputMask
                                                onChange={(e) => {
                                                    props.setFieldValue(`bank_accounts.${index}.iban_number`,e.target.value,false);
                                                }}
                                                mask="UA999999999999999999999999999"
                                            />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Валюта рахунка:</label>
                                            <CustomSelect
                                                onSelect={(values, id) => {
                                                    props.setFieldValue(`bank_accounts.${index}.currency`,id,false);
                                                }}
                                                data={[
                                                    { name: "UAH", id: 'UAH' },
                                                ]}
                                            />
                                        </div>

                                        <div className={s.inputItem}>
                                            <label>Призначення рахунка:</label>
                                            <CustomSelect
                                                onSelect={(values, id) => {
                                                    props.setFieldValue(`bank_accounts.${index}.account_purpose`,id,false);
                                                }}
                                                data={[
                                                    { name: "Всі платежі", id: 'ALL_PAYMENTS' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {index!==0?<div className={s.inputItem}>
                                        <div className={s.removeAccountBtn} onClick={() => remove(index)}>
                                            <div className={s.removeAccountBtnPlus}>
                                                <p>+</p>
                                            </div>
                                            <p>Видалити рахунок</p>
                                        </div>
                                    </div>:null}
                                    <div className={s.line}/>
                                </div>

                            ))}
                            <div className={s.inputItem}>
                                <div className={s.removeAccountBtn+' '+s.blueBtn} onClick={() => push({
                                    bank_name:'',
                                    bank_tax_number:'',
                                    bank_code: '',
                                    iban_number:'',
                                    currency:'',
                                    account_purpose:''
                                })}>
                                    <div className={s.removeAccountBtnPlus}>
                                        <p>+</p>
                                    </div>
                                    <p>Додати рахунок</p>
                                </div>
                            </div>
                        </div>
                    )}
                </FieldArray>

                <div className={s.line}/>

                <h3 className={s.subtitle}>Контактна особа</h3>

                <div className={s.inputItem}>
                    <label>Прізвище, ім’я, по батькові:</label>
                    <Field name="contact_person_name"/>
                </div>
                <div className={s.inputItem}>
                    <label>E-mail:</label>
                    <Field name="contact_person_email"/>
                </div>
                <div className={s.inputItem}>
                    <label>Номер телефону:</label>
                    <InputMask
                        onChange={(e)=>{props.setFieldValue("contact_person_phone_number",'+' + e.target.value.replace(/[^0-9]/g,''),false)}}
                        mask="+38 (099) 999-99-99"
                        name="contact_person_phone_number"
                        id="contact_person_phone_number"
                        value={props.values.contact_person_phone_number}
                    />
                </div>
                            {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button className={s.saveButton} type="submit">Зберегти</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}


export default AddLotRedesign;