import React, {Fragment, useEffect, useState} from "react";
import s from './Timer.module.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Countdown, { zeroPad } from 'react-countdown';

const Timer = (props) => {
    const [percentage, setPercentage] = useState(0);
    const [maxDelta, setMaxDelta] = useState();
    const tickHandler = (e) => {
        setPercentage(Math.floor(100-(100*e.total)/maxDelta));
        if(props.reverse) setPercentage(Math.floor((100*e.total)/maxDelta));
        else setPercentage(Math.floor(100-(100*e.total)/maxDelta));

    }
    const completeHandler = () => {
        if(props.reverse) setPercentage(100);
        else setPercentage(0);

        if(props.onComplete) props.onComplete();
    }

    const renderer = ({ minutes, seconds }) => (
        <span>
            {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
    );

    useEffect(()=>{
        setMaxDelta(props.timerEnd-props.timerStart);
        if((props.timerEnd-props.timerStart!==maxDelta)) setPercentage(0);
    },[props])

    return (
        <div className={s.TimerBox}>
            <div>
                <CircularProgressbar strokeWidth={3} styles={{'trail':{'stroke':'#E1E1E1'},'path':{'stroke':'#057E36'}}} value={percentage} text={``} />
            </div>
            <div className={s.TimerContent}>
                <p>{props.text}</p>
                <h1 style={props.hideCountdown?{display:"none"}:{}} className={s.countdown}>
                    <Countdown
                        ref={props.reference}
                        now={()=>Date.now()+(+props.timeDelta)}
                        renderer={renderer}
                        zeroPadTime={4}
                        onComplete={completeHandler}
                        onTick={tickHandler}
                        daysInHours={true}
                        date={+props.timerEnd}/>
                </h1>
            </div>
        </div>
    )
}

export default Timer;