import s from "../NewLot.module.css";
import React, {useEffect, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import config from "../../../config/config";

export default function LotInfo({auctionInfo}) {

    const OwnershipType = ({type}) => {
        switch (type) {
            case 'COMMUNAL' : return <h5 className={s.value}>Комунальна</h5>
            case 'STATE' : return <h5 className={s.value}>Державна</h5>
            case 'PRIVATE' : return <h5 className={s.value}>Приватна</h5>
            default : return <h5/>
        }
    }

    return (
        <div className={s.lotInfo}>
            <h1 className={s.title}>Опис лота</h1>
            <div className={s.lotDetails}>
                <div className={s.gridItem}>
                    <p className={s.label}>Кадастровий номер:</p>
                    <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.cadastral_number}</h5>
                </div>
                <div className={s.gridItem}>
                    <p className={s.label}>Площа:</p>
                    <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.area} га</h5>
                </div>
                <div className={s.gridItem}>
                    <p className={s.label}>Форма власності:</p>
                    <OwnershipType type={auctionInfo.lot && auctionInfo.lot.ownership_type}/>
                </div>
            </div>

            <div className={s.textItem}>
                <p className={s.label}>Категорія земель:</p>
                <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.category}</h5>
            </div>
            <div className={s.textItem}>
                <p className={s.label}>Цільове призначення:</p>
                <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.purpose.description}</h5>
            </div>
            <div className={s.textItem}>
                <p className={s.label}>Опис:</p>
                <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.description}</h5>
            </div>
            <div className={s.textItem}>
                <p className={s.label}>Співвласник:</p>
                <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.co_owners}</h5>
            </div>
            <div className={s.textItem}>
                <p className={s.label}>Обтяження прав на земельну ділянку:</p>
                <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.restrictions}</h5>
            </div>
            <div className={s.textItem}>
                <p className={s.label}>Інженерні мережі:</p>
                <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.engineering_networks}</h5>
            </div>
            <div className={s.line}/>
            <div className={s.textItem}>
                <p className={s.label}>Наявність суб’єкта переважного права:</p>
                <h5 className={s.value}>{auctionInfo.lot && auctionInfo.lot.has_preference_subjects?'Так':'Ні'}</h5>
            </div>
            {/*TODO add preference subjects*/}
            {auctionInfo.lot && auctionInfo.lot.has_preference_subjects &&
                <>
                    <div className={s.textItem}>
                        <p className={s.label}>Прізвище, ім’я, по батькові суб’єкта переважного права:</p>
                        <h5 className={s.value}>Бойко Ігор Валерійович</h5>
                    </div>
                    <div className={s.textItem}>
                        <p className={s.label}>Черга:</p>
                        <h5 className={s.value}>Суб’єкт переважного права 1 черги</h5>
                    </div>
                    <div className={s.textItem}>
                        <p className={s.label}>Підстава:</p>
                        <h5 className={s.value}>Спецдозвіл на видобування корисних копалин загальнодержавного значення</h5>
                    </div>
                    <div className={s.line}/>
                </>
            }
            <h5>Місцезнаходження</h5>
        </div>
    )
}