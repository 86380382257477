import React, {useState} from "react";
import Modal from "../Modal/Modal";

function SignComponent({isVisible, onSign, onClose, transparentBackground}) {
    const [file, setFile] = useState(null)
    const fileChangeHandler = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0])
    }

    const signHandler = () => {
        onSign(file);
        onClose()
    }

    return(
        <Modal transparentBackground={transparentBackground} isVisible={isVisible} onClose={onClose}>
            <div>
                <h1>Підписати документ</h1>
                <input type="file" onChange={fileChangeHandler}/>
                <button onClick={signHandler}>Підписати</button>
            </div>
        </Modal>
    )
}

export default SignComponent;