import React, {useState, useContext} from 'react';
import s from './Confirm.module.css';
import {useHttp} from '../../hooks/HttpHook';
import {NavLink, useHistory} from 'react-router-dom';
import config from '../../config/config.js';
import {AuthContext} from '../../context/AuthContext';
import InputMask from 'react-input-mask';
import ErrorHandler from "../../utils/ErrorLoger";
function Confirm(){
    const history = useHistory();
    const auth = useContext(AuthContext);
    const {request, requestErrors} = useHttp();
    const [sms_code, setSmsCode] = useState(null);
    const changeHandler = (e) => {
        setSmsCode(e.target.value.replace(/ /g, ''));
    }
    const resendCode = async (e) => {
        let data;
        try{
            data = await request(config.baseUrl + '/api/v1/user/sms/resend/', 'POST');
        }catch(e){
            ErrorHandler(e)
        }
    }
    const confirmHandler = async (e) => {
        e.preventDefault();
        let data;
        try{
            data = await request(config.baseUrl + '/api/v1/user/sms/verify/', 'POST', {sms_code});
        }catch(e){
            ErrorHandler(e)
        }
        if(data && data.token && data.token.access){
            auth.login(data.token.access, data.token.refresh);
            switch(data.next_page){
                case 'home': history.push('/'); break;
                case 'verify_sms_code': history.push('/verify/'); break;
                case "sms_reset_password": history.push('/reset/'); break;
            }
        }
    }
    return(
        <div className={s.Confirm}>
            <div className={s.background}>
            </div >
            <div className={s.confirmForm}>
            <form>
                <h1>Підтвердження</h1>
                {requestErrors?<h2 data-testid="register-errros" className={s.error}>{requestErrors[0]}</h2>:null}
                <InputMask mask="9 9 9 9" maskChar="_" onChange={changeHandler}/>
                <div className={s.bottomBar}>
                    <a onClick={resendCode}>Надіслати ще раз</a>
                    <button onClick={confirmHandler}>Підтвердити</button>
                </div>
            </form>
            </div>
        </div>
    )
}

export default Confirm;