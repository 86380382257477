import s from "../OrganizerLot.module.css";
import checkmarkIcon from "../../../assets/img/checkmark-blue.png";
import React, {useContext, useEffect, useState} from "react";
import config from "../../../config/config";
import ErrorHandler from "../../../utils/ErrorLoger";
import {useHttp} from "../../../hooks/HttpHook";
import Modal from "../../../components/Modal/Modal";
import {AuthContext} from "../../../context/AuthContext";
import SignComponent from "../../../components/SignComponent/SignComponent";

export default function ContractComponent({item, onUpdate}){
    const [contracts, setContracts] = useState([])
    const [modalType, setModalType] = useState(null);
    const {request} = useHttp();
    const getContracts = async () => {
        try{
            let data = await request(config.baseUrl + `/api/v1/auctions/auction-contracts/${item.id}/`,'GET');
            if(data) setContracts(data)
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const localizeStatus = (status) => {
        switch (status) {
            case "AWAITING_WINNER_SIGNATURE": return 'Очікує підписання переможцем'
            case "AWAITING_ORGANIZER_SIGNATURE": return 'Очікує підписання організатором'
            case "WINNER_NOT_SIGNED": return 'Переможець не підписав'
            case "TOTALY_SIGNED": return 'Підписано'
            case "DECLINED": return 'Відмовлено'

        }
    }

    const DocumentItem = ({date, number, name, status}) => {
        return(
            <div className={s.documentItem}>
                <table>
                    <tbody>
                    <tr>
                        <th className={s.tableDate}>Дата і час  розміщення:</th>
                        <th className={s.tableNumber}>Номер документа:</th>
                        <th className={s.tableName}>Назва документу</th>
                        <th className={s.tableStatus}>Статус:</th>
                    </tr>
                    <tr>
                        <td>{date}</td>
                        <td>{number}</td>
                        <td>
                            <p>Протокол про проведення аукціону</p>
                            <a className={s.document}>
                                <i className="far fa-file-alt"/>
                                <p>{name}</p>
                            </a>
                        </td>
                        <td>{status}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const AuctionContracts = () => {
        return (
            <>
                <h2 className={s.title}>Документи земельних торгів</h2>
                <h4 className={s.subtitle}>Договір</h4>
                {contracts.length > 0 ?
                <>
                    {contracts.map((elem, i) => {
                        const fileParts = elem.file.split('/');
                        const filename = fileParts[fileParts.length-1];
                        return <DocumentItem
                            status={localizeStatus(elem.contract_status)}
                            name={filename}
                            date={elem.created_at}
                            number={elem.id}
                            key={i}
                        />
                    })}
                </>:
                    <p className={s.documentsNotFound}>Договорів не знайдено</p>
                }
            </>
        )
    }

    const FileSelector = () => {
        const [file, setFile] = useState(null);
        const auth = useContext(AuthContext);
        const contractUploadHandler = (e) => {
            setFile(e.target.files[0]);
        }

        const sendFile = () => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${auth.token}`);
            const formData = new FormData();
            formData.append("file", file, file.name);
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            };
            fetch(`https://private.rynok-zemli.com.ua/api/v1/auctions/auction-contracts/generate-new-contract/${item.id}/`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setModalType(null)
                })
                .catch(error => console.log('error', error));
        }

        return(
            <>
                <input type="file" onChange={contractUploadHandler}/>
                <button className={s.selectFileButton} onClick={(e)=>{e.target.parentNode.children[1].click()}}>Обрати файл</button>
                {file && <p>{file.name}</p>}
                <button className={s.uploadFileButton} onClick={sendFile}>Завантажити</button>
            </>
        )
    }

    const Actions = () => {
        const [modalType, setModalType] = useState(null);
        const [signVisible, setSignVisible] = useState(false);
        const auth = useContext(AuthContext);
        const currentContract = contracts[contracts.length-1];
        const ActionItem = ({text, complete, onClick, disabled}) => {
            return (
                <a onClick={complete || disabled?()=>{}:onClick} className={complete?s.actionItem:disabled?s.actionItemDisabled:s.actionItem}>
                    {complete?
                        <img src={checkmarkIcon}/>:
                        <div className={s.circle}/>
                    }
                    <p>{text}</p>
                    <i className="fas fa-long-arrow-alt-right"/>
                </a>
            )
        }

        const checkHandler = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/auction-contracts/${currentContract.id}/mark-as-checked-by-organizer/`,'PUT');
                if(data) getContracts();
            }catch (e) {
                ErrorHandler(e)
            }
        }

        const signHandler = async (file) => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${auth.token}`);
            const formData = new FormData();
            formData.append("file", file, file.name);
            const requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            };
            fetch(`https://private.rynok-zemli.com.ua/api/v1/auctions/auction-contracts/sign/${currentContract.id}/organizer/`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    getContracts()
                })
                .catch(error => console.log('error', error));
        }


        const publishHandler = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/auction-contracts/publish/${currentContract.id}/`,'PUT');
                if(data) {
                    getContracts();
                    onUpdate();
                }
            }catch (e) {
                ErrorHandler(e)
            }
        }

        return(
            <div className={s.actions}>

                {modalType === 'check_signature' &&
                <Modal onClose={()=>{setModalType(null)}} isVisible={true}>
                    <div className={s.protocolCheckModal}>
                        <h1>Перевірити підпис</h1>
                        <p>Натисніть на файл, щоб переглянути його</p>
                        <a target="_blank" href={config.baseUrl + currentContract.file}>{currentContract.file.split('/')[currentContract.file.split('/').length-1]}</a>
                        <button onClick={checkHandler}>Готово</button>
                    </div>
                </Modal>}

                {modalType === 'sign_and_publish' &&
                <Modal onClose={()=>{setModalType(null)}} isVisible={true}>
                    <div className={s.protocolCheckModal}>
                        <h1>Підписати і оприлюднити договір</h1>
                        <p>Натисніть на файл, щоб переглянути його</p>
                        <a target="_blank" href={config.baseUrl + currentContract.file}>{currentContract.file.split('/')[currentContract.file.split('/').length-1]}</a>
                        <button
                            style={currentContract && currentContract.contract_status === 'TOTALY_SIGNED'?{backgroundColor:'#6b6e75'}:{}}
                            disabled={currentContract && currentContract.contract_status === 'TOTALY_SIGNED'}
                            onClick={()=>{
                                setSignVisible(true)
                            }}
                        >Підписати</button>
                        <button onClick={publishHandler}>Оприлюднити</button>
                        <SignComponent transparentBackground={true} onClose={()=>setSignVisible(false)} isVisible={signVisible} onSign={signHandler}/>
                    </div>
                </Modal>}


                <h5 className={s.actionsTitle}>Доступні дії:</h5>
                <ActionItem
                    complete={currentContract && currentContract.signature_is_cheked_by_organizer}
                    disabled={currentContract?currentContract.contract_status !== 'AWAITING_ORGANIZER_SIGNATURE':true}
                    text="Перевірити підпис"
                    onClick={()=>{
                        setModalType('check_signature')
                    }}
                />
                <ActionItem
                    complete={currentContract && currentContract.is_published}
                    disabled={
                        currentContract?
                            currentContract.contract_status === 'AWAITING_ORGANIZER_SIGNATURE'?
                                !currentContract.signature_is_cheked_by_organizer:
                                currentContract.contract_status === 'TOTALY_SIGNED'?
                                    currentContract.is_published:
                                    true:
                            true

                    }
                    text="Підписати і оприлюднити протокол"
                    onClick={()=>{
                        setModalType('sign_and_publish')
                    }}
                />
                <ActionItem
                    complete={false}
                    disabled={true}
                    text="Сформувати акт переможця про відмову від підписання протоколу"
                    onClick={()=>{
                        alert('test');
                    }}
                />
                <ActionItem
                    complete={false}
                    disabled={true}
                    text="Сформувати рішення організатора про відмову  від підписання протоколу"
                    onClick={()=>{
                        alert('test');
                    }}
                />
            </div>
        )
    }

    useEffect(()=>{
        getContracts();
    },[])

    return(
        <div className={s.ContractComponent}>
            {modalType === "upload_contract" &&
            <Modal isVisible={true} onClose={() => setModalType(null)}>
                <div className={s.uploadContractModal}>
                    <h1>Завантажити договір</h1>
                    <FileSelector/>
                </div>
            </Modal>
            }

            <h2 className={s.title}>Договір</h2>
            <div className={s.buttonsTop}>
                <button className={s.buttonDisabled}><i className="far fa-file-alt"/>Сформувати договір</button>
                <p>або</p>
                <button
                    disabled={item.auction_status!=='AWAITING_CONTRACT_SIGN'}
                    className={item.auction_status!=='AWAITING_CONTRACT_SIGN'?s.buttonDisabled:null}
                    onClick={() => {
                        setModalType("upload_contract")
                    }}
                ><i className="fas fa-download"/>Завантажити договір
                </button>
            </div>
            {/*<button className={s.bottomButton}>Надіслати договір для підпису переможцем</button>*/}
            <AuctionContracts/>
            <Actions/>
        </div>
    )
}