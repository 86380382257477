import OutsideClickHandler from "react-outside-click-handler";
import s from "../Home.module.css";
import InputRange from "react-input-range";
import React, {useEffect, useState} from "react";


export default function Slider ({onClose, onChange, minValue, maxValue, defaultValue}){
    const [options, setOptions] = useState({min:defaultValue.min, max:defaultValue.max});

    return (
        <OutsideClickHandler onOutsideClick={(e)=> e.target.classList[1]!=='fa-chevron-up' && onClose()}>
            <div  className={s.filterDropdown}>
                <div className={s.sliderFilter}>
                    <div className={s.sliderInputs}>
                        <input
                            max={maxValue}
                            onChange={(e)=>{
                                if(e.target.value<=maxValue) {
                                    setOptions({...options, min: +e.target.value});
                                   onChange({...options, min: +e.target.value});
                                }
                            }}
                            value={options.min}
                            type="number"
                        />
                        <div className={s.sliderLine}/>
                        <input max={maxValue}
                               onChange={(e)=>{
                                   if(e.target.value<=maxValue) {
                                       setOptions({...options, max: +e.target.value});
                                       onChange({...options, max: +e.target.value});
                                   }
                               }}
                               value={options.max}
                               type="number"
                        />
                    </div>
                    <InputRange
                        maxValue={maxValue}
                        step={maxValue/100}
                        minValue={minValue}
                        formatLabel={() => ''}
                        onChange={(value)=>{
                            setOptions(value)
                            console.log('onChange',value)
                        }}
                        onChangeComplete={(value)=>{
                            //setOptions(value)
                            console.log('onComplete',value)
                            onChange(value)

                        }}
                        value={options}
                    />
                </div>
            </div>
        </OutsideClickHandler>
    )
}
