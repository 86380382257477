import React, {useState, useCallback, Fragment, useMemo, useEffect} from "react";
import s from './Participation.module.css';
import checkmarkGreen from '../../assets/img/checkmark-green.svg';
import CustomSelect from "../CustomSelect/CustomSelect";
import FileSign from "../FileSign/FileSign";
import FileSignMultiple from "../FileSignMultiple/FileSignMultiple";
import {useHistory} from 'react-router-dom'
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import Loading from "../Loading/Loading";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import ErrorHandler from "../../utils/ErrorLoger";

function Participation(props) {
    const [userType, setUserType] = useState();
    const [currentStage, setCurrentStage] = useState(1);
    const [mainForm, setMainForm] = useState({});
    const history = useHistory();
    const userTypes = [
        {name:"Фізична особа", id:'INDIVIDUAL'},
        {name:"Фізична особа-підприємець", id:'INDIVIDUAL_ENTREPRENEUR' },
        {name:"Юридична особа", id:'LEGAL_ENTITY'},
    ]

    function formChangeHandler(e, state, setState) {
        setState({...state, [e.target.name]: e.target.value});
    }

    async function validateForm(request, form, onSuccess) {
        let data = await request(config.baseUrl + `/api/v1/auctions/${props.lotId}/participate/validation/`, 'POST', {...form, legal_form:userType});
        if(data && data.status==='OK') onSuccess();
    }



    const FirstStage =()=> {

        async function validateFirstForm(form, onSuccess) {
            let data = await request(config.baseUrl + `/api/v1/auctions/${props.lotId}/participate/validation/`, 'POST',{...form, legal_form:userType});
             if(data && data.status==='OK') onSuccess();
        }
        const [firstStageForm, setFirstStageForm] = useState({
            first_name:'',
            second_name: '',
            last_name: '',
            is_citizen: '',
            has_tax_number:true,
            tax_number:'',
            tax_number_document: null
        });
        const [firstStageFormLegal, setFirstStageFormLegal] = useState({
            legal_entity_name:'',
            legal_entity_tax_number:''
        });
        const {requestErrors, request, loading} = useHttp();
        const firstStageLegalSubmitHandler = () => {
            validateFirstForm(firstStageFormLegal, ()=>{
                const newForm = {}

                setMainForm( Object.assign({}, mainForm, firstStageFormLegal))
                setCurrentStage(2)
            },)
        }

        const firstStageSubmitHandler = () => {
            validateFirstForm(firstStageForm, ()=>{
                setMainForm( Object.assign({}, mainForm, firstStageForm))
                setCurrentStage(2)
            },)
        }
        const inputPlaceholder = userType==='INDIVIDUAL_ENTREPRENEUR'?'Фізична особа-підприємець':
            userType==='INDIVIDUAL'?'Фізична особа':
                userType==='LEGAL_ENTITY'?'Юридична особа': '';
        console.log(inputPlaceholder)
        return(
            <div>
                {loading?<Loading/>:null}
                <div className={s.inputItem}>
                    <label>Оберіть організаційно-правову форму*</label>
                    <CustomSelect placeholder={inputPlaceholder} onSelect={(value,id)=>{setUserType(id)}} data={userTypes}/>
                </div>
                {userType==='LEGAL_ENTITY'?<Fragment>
                    <div className={s.inputItem}>
                        <label>Найменування юридичної особи*</label>
                        <input onChange={(e)=>{formChangeHandler(e, firstStageFormLegal, setFirstStageFormLegal)}} name="legal_entity_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Код ЄДРПОУ*</label>
                        <input onChange={(e)=>{formChangeHandler(e, firstStageFormLegal, setFirstStageFormLegal)}} name="legal_entity_tax_number"/>
                    </div>
                    {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                    <button onClick={firstStageLegalSubmitHandler} className={s.nextStageButton}>Далі</button>
                </Fragment>:null}

                {userType==='INDIVIDUAL_ENTREPRENEUR' || userType==='INDIVIDUAL'?<Fragment>
                    <div className={s.inputItem}>
                        <label>Прізвище*</label>
                        <input onChange={(e)=>{formChangeHandler(e, firstStageForm, setFirstStageForm)}} name="last_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Ім'я*</label>
                        <input onChange={(e)=>{formChangeHandler(e, firstStageForm, setFirstStageForm)}} name="first_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>По батькові*</label>
                        <input onChange={(e)=>{formChangeHandler(e, firstStageForm, setFirstStageForm)}} name="second_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Громадянство України*</label>
                        <CustomSelect onSelect={(value,id)=>{
                            setFirstStageForm({...firstStageForm,is_citizen:id})
                        }} data={[{name:'Так', id:true},{name:'Ні', id:false}]}/>
                    </div>
                    <div className={s.rnokpp}>
                        <div className={s.inputItem}>
                            <label>РНОКПП</label>
                            <input onChange={(e)=>{formChangeHandler(e, firstStageForm, setFirstStageForm)}} name="tax_number"/>
                        </div>
                        <div className={s.checkboxItem}>
                            <input onChange={(e)=>{setFirstStageForm(e.target.checked?{...firstStageForm, has_tax_number: false}:{...firstStageForm, has_tax_number: true})}} name="has_tax_number" type='checkbox'/>
                            <p>Я відмовився від РНОКПП через свої релігійні переконання та маю про це відмітку в паспорті</p>
                        </div>
                    </div>
                    <FileSign
                        documentType='TAX_NUMBER'
                        onSelect={(id)=>{setFirstStageForm({...firstStageForm,  tax_number_document: id})}}
                        className={s.fileSign}
                        description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
                        title="Завантажити РНОКПП"
                    />
                    {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                    <button onClick={firstStageSubmitHandler} className={s.nextStageButton}>Далі</button>
                </Fragment>:null}
            </div>
        )
    }

    function SecondStage(){
        const[secondStageForm, setSecondStageForm] = useState({

            postal_code:'',
            region:'',
            settlement:'',
            street:'',
            building_number:'',
            building_block_number:'',
            apartment_or_office_number:'',
            country:'',
        });
        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            validateForm(request, secondStageForm, ()=>{
                setMainForm( Object.assign({}, mainForm, secondStageForm))
                setCurrentStage(3);
            })
        }
        return(
            <div>
                {loading?<Loading/>:null}
                <div className={s.inputItem}>
                    <label>Оберіть країну*</label>
                    <CustomSelect customId="code" onSelect={(value,id)=>{
                        setSecondStageForm({...secondStageForm,country:id})
                    }} url="/api/v1/location/countries/"/>
                </div>
                <div className={s.inputItem}>
                    <label>Поштовий індекс*</label>
                    <input onChange={(e)=>{formChangeHandler(e, secondStageForm, setSecondStageForm)}} name='postal_code'/>
                </div>
                <div className={s.inputItem}>
                    <label>Оберіть регіон*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setSecondStageForm({...secondStageForm,region:id})
                    }} url="/api/v1/location/regions/"/>
                </div>
                <div className={s.inputItem}>
                    <label>Оберіть населений пункт*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setSecondStageForm({...secondStageForm,settlement:id})
                    }} url="/api/v1/location/settlements/"/>
                </div>
                <div className={s.inputItem}>
                    <label>Вулиця*</label>
                    <input onChange={(e)=>{formChangeHandler(e, secondStageForm, setSecondStageForm)}} name="street"/>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Номер будинку*</label>
                        <input onChange={(e)=>{formChangeHandler(e, secondStageForm, setSecondStageForm)}} name="building_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер корпусу</label>
                        <input onChange={(e)=>{formChangeHandler(e, secondStageForm, setSecondStageForm)}} name="building_block_number"/>
                    </div>
                </div>
                <div className={s.inputItem}>
                    <label>Номер квартири/офісу*</label>
                    <input onChange={(e)=>{formChangeHandler(e, secondStageForm, setSecondStageForm)}} name="apartment_or_office_number"/>
                </div>
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }

    function ThirdStage() {
        const[thirdStageForm, setThirdStageForm] = useState({
            identity_document_type:'',
            identity_document_serial_number:'',
            identity_document_number:'',
            identity_document_issue_date:null,
            identity_document: null,
            email:'',
            phone_number:'',
        });

        const numberChangeHandler = (e) =>{
            const number = e.target.value.replace(/[^0-9]/g,'');
            setThirdStageForm({...thirdStageForm, 'phone_number': '+' + number});
        }

        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            validateForm(request, thirdStageForm, ()=>{
                setMainForm( Object.assign({}, mainForm, thirdStageForm))
                setCurrentStage(4);
            })
        }
        return(
            <div>
                {loading?<Loading/>:null}
                <div className={s.inputItem}>
                    <label>Документ що посвідчує особу*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setThirdStageForm({...thirdStageForm,identity_document_type:id})
                    }} data={[
                        {name:'паспорт громадянина України (ГУ)', id:'PASSPORT'},
                        {name:'паспорт ГУ у формі картки', id:'PASSPORT_AS_CARD'},
                        {name:'посвідка на тимчасове проживання', id:'TEMPORARY_RESIDENCE_PERMIT'},
                        {name:'посвідка на постійне проживання', id:'PERMANENT_RESIDENCE_PERMIT'},
                        {name:'інший документ', id:'ANOTHER_DOCUMENT'},
                    ]}/>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Серія</label>
                        <input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="identity_document_serial_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер</label>
                        <input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="identity_document_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Дата видачі</label>
                        {/*<DatePicker*/}
                        {/*    name="identity_document_issue_date"*/}
                        {/*    dateFormat="yyyy-MM-dd"*/}
                        {/*    onChange={(date)=>{console.log(date)}}*/}
                        {/*    locale="ua-Uk"*/}
                        {/*/>*/}
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={thirdStageForm.identity_document_issue_date_timestamp}
                            onChange={(date)=>{
                                console.log(date)
                                const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                setThirdStageForm({
                                    ...thirdStageForm,
                                    identity_document_issue_date_timestamp: +date,
                                    identity_document_issue_date: stringDate
                                })
                            }}
                            locale="ua-Uk"
                        />
                        {/*<input placeholder="2020-02-08" onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="identity_document_issue_date"/>*/}
                    </div>
                </div>
                <FileSign
                    documentType='IDENTITY_DOCUMENT'
                    onSelect={(id)=>{setThirdStageForm({...thirdStageForm,  identity_document: id})}}
                    className={s.fileSign}
                    description="Документ, що посвідчує особу. Максимальний розмір файлу 50 Мб"
                    title="Завантажити документ"
                />
                <div className={s.line +' '+s.gap}/>
                <div className={s.inputItem}>
                    <label>Email*</label>
                    <input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="email"/>
                </div>
                <div className={s.inputItem}>
                    <label>Номер телефону*</label>
                    <InputMask onChange={numberChangeHandler} mask="+38 (099) 999-99-99" name="phone_number"/>
                    {/*<input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="phone_number"/>*/}
                </div>
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button style={{marginTop:'150px'}} onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }

    function FourthStage(){
        const [specialUsePreference, setSpecialUsePreference] = useState(null);
        const [renterPreference, setRenterPreference] = useState(null);
        const [additionalDocuments, setAdditionalDocuments] = useState([]);
        const [fourthStageForm, setFourthStageForm] = useState({
            initial_bid_amount:'',
            refund_iban:'',
            has_preference:false,
            personal_data_process_consent:'',
        });
        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{

            let preferences = [];
            if(specialUsePreference) preferences.push(specialUsePreference)
            if(renterPreference) preferences.push(renterPreference)
            let validateDate = fourthStageForm.has_preference?
                {...fourthStageForm, preferences}:
                {...fourthStageForm}
            if(additionalDocuments.length>0 && fourthStageForm.has_preference) validateDate ={...validateDate, additional_documents:additionalDocuments}
            validateForm(request, validateDate, async ()=>{
                setMainForm( Object.assign({}, mainForm, validateDate))
                let data;
                try{
                    data = await request(config.baseUrl + `/api/v1/auctions/${props.lotNumber}/participate/`,'POST', {...mainForm, ...validateDate, legal_form:userType});
                    if(data.status === 'OK') setCurrentStage(0);
                }catch(e){
                    ErrorHandler(e)
                }
            })
        }

        return(
            <div>
                {loading?<Loading/>:null}
                <h4>Розмір мінімальної ставки: {props.lot?.min_price} грн</h4>
                <div className={s.inputItem}>
                    <label>Розмір закритої цінової пропозиції*</label>
                    <input onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} name="initial_bid_amount"/>
                </div>
                <div className={s.inputItem}>
                    <label>Номер банківського рахунку - IBAN*</label>
                    {/*<input onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} name="refund_iban"/>*/}
                    <InputMask onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} mask="UA999999999999999999999999999" name="refund_iban"/>
                </div>
                <div className={s.inputItem}>
                    <label>Суб'єкт переважного права*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setFourthStageForm({...fourthStageForm,has_preference:JSON.parse(id)});
                    }} data={[{name:'Так', id:'true'},{name:'Ні', id:'false'}]}/>
                </div>
                {fourthStageForm.has_preference?<>
                    <div className={s.checkboxRow}>
                        <div className={s.checkboxItem}>
                            <input name="preference_type" onChange={(e)=>{
                                if(e.target.checked){
                                    setRenterPreference(null)
                                    setSpecialUsePreference({
                                        type:'SPECIAL_USE_PREFERENCE',
                                        document_number:'',
                                        document_date:'',
                                        document_expire_at:'',
                                        document:null,
                                    })
                                }else{
                                    setSpecialUsePreference(null)
                                }


                            }} type='radio'/>
                            <p>Спецдозвіл на видобування корисних копалин<br/> загальнодержавного значення (1 черга)</p>
                        </div>
                        <div className={s.checkboxItem}>
                            <input name="preference_type" onChange={(e)=>{
                                if(e.target.checked){
                                    setSpecialUsePreference(null)
                                    setRenterPreference({
                                        type:'RENTER_PREFERENCE',
                                        document_number:'',
                                        document_date:'',
                                        document_expire_at:'',
                                        document:null,
                                    })
                                }else{
                                    setRenterPreference(null)
                                }

                            }} type='radio'/>
                            <p>Договір оренди земельної ділянки (2 черга)</p>
                        </div>
                    </div>
                    {specialUsePreference?<>
                        <h4 style={{'marginTop':'20px'}}>Спецдозвіл на видобування корисних копалин загальнодержавного значення (1 черга)</h4>
                        <div className={s.inputItem}>
                            <label>Номер документа*</label>
                            <input onChange={(e)=>{formChangeHandler(e, specialUsePreference, setSpecialUsePreference)}} name="document_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Дата документа*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={specialUsePreference.document_date_timestamp}
                                onChange={(date)=>{
                                    console.log(date)
                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setSpecialUsePreference({
                                        ...specialUsePreference,
                                        document_date_timestamp: +date,
                                        document_date: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                            {/*<input onChange={(e)=>{formChangeHandler(e, specialUsePreference, setSpecialUsePreference)}} name="document_date"/>*/}
                        </div>
                        <div className={s.inputItem}>
                            <label>Термін дії до*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={specialUsePreference.document_expire_at_timestamp}
                                onChange={(date)=>{
                                    console.log(date)
                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setSpecialUsePreference({
                                        ...specialUsePreference,
                                        document_expire_at_timestamp: +date,
                                        document_expire_at: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                        </div>

                        <div className={s.itemRow + ' ' + s.fileUploadRow}>
                            <FileSign
                                documentType='PREFERENCE_DOCUMENT'
                                onSelect={(id)=>{setSpecialUsePreference({...specialUsePreference,  document: id})}}
                                className={s.fileSign}
                                description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                title="Завантажити документ"
                            />
                        </div>
                    </>:null}

                    {renterPreference?<>
                        <h4 style={{'marginTop':'20px'}}>Договір оренди земельної ділянки (2 черга)</h4>
                        <div className={s.inputItem}>
                            <label>Номер документа*</label>
                            <input onChange={(e)=>{formChangeHandler(e, renterPreference, setRenterPreference)}} name="document_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Дата документа*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={renterPreference.document_date_timestamp}
                                onChange={(date)=>{
                                    console.log(date)
                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setRenterPreference({
                                        ...renterPreference,
                                        document_date_timestamp: +date,
                                        document_date: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                            {/*<input onChange={(e)=>{formChangeHandler(e, renterPreference, setRenterPreference)}} name="document_date"/>*/}
                        </div>
                        <div className={s.inputItem}>
                            <label>Термін дії до*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={renterPreference.document_expire_at_timestamp}
                                onChange={(date)=>{
                                    console.log(date)
                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setRenterPreference({
                                        ...renterPreference,
                                        document_expire_at_timestamp: +date,
                                        document_expire_at: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                        </div>

                        <div className={s.itemRow + ' ' + s.fileUploadRow}>
                            <FileSign
                                documentType='PREFERENCE_DOCUMENT'
                                onSelect={(id)=>{setRenterPreference({...renterPreference,  document: id})}}
                                className={s.fileSign}
                                description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                title="Завантажити документ"
                            />
                        </div>

                    </>:null}
                    {specialUsePreference || renterPreference?

                    <FileSignMultiple
                        documentType='ADDITIONAL_DOCUMENT'
                        onSelect={(id)=>{setFourthStageForm({...fourthStageForm,  additional_documents: id})}}
                        className={s.fileSign}
                        description="Додаткові документи (за необхідності). Максимальний розмір файлу 50 Мб "
                        title="Завантажити документ"
                    />:null}
                </>:<div className={s.line +' '+s.gap}/>}
                <div style={{padding:'0px', marginTop:'50px'}} className={s.checkboxItem}>
                    <input name="personal_data_process_consent" onChange={(e)=>{
                        e.target.checked?
                            setFourthStageForm({...fourthStageForm,personal_data_process_consent:true}):
                            setFourthStageForm({...fourthStageForm,personal_data_process_consent:false})
                    }} type='checkbox'/>
                    <p>Надаю згоду на обробку моїх персональних даних відповідно до Закону України «Про захист персональних даних»</p>
                </div>
                <div style={{padding:'0px', marginTop:'20px'}} className={s.checkboxItem}>
                    <input type='checkbox'/>
                    <p>Зберегти особисту інформацію для наступних аукціонів</p>
                </div>
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.finishButton}>Вступити у аукціон</button>
            </div>
        )
    }

    function LegalSecondStage() {
        const [isAddressDifferent, setIsAddressDifferent] = useState(false);
        const [legalSecondStageForm, setLegalSecondStageForm] = useState({
            country:'',
            postal_code:'',
            region:'',
            settlement:'',
            street:'',
            building_number:'',
            building_block_number:'',
            apartment_or_office_number:'',
            legal_entity_address_and_postal_address_is_different:false,
            email:'',
            phone_number:''
        });

        const [postalForm, setPostalForm] = useState({
            postal_address_country:'',
            postal_address_postal_code:'',
            postal_address_region:'',
            postal_address_settlement:'',
            postal_address_street:'',
            postal_address_building_number:'',
            postal_address_building_block_number:'',
            postal_address_apartment_or_office_number:'',
        });

        const numberChangeHandler = (e) =>{
            const number = e.target.value.replace(/[^0-9]/g,'');
            setLegalSecondStageForm({...legalSecondStageForm, 'phone_number': '+' + number});
        }

        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            if(isAddressDifferent){
                validateForm(request, {...legalSecondStageForm,...postalForm}, async ()=>{
                    setMainForm( Object.assign({}, mainForm, {...legalSecondStageForm,...postalForm}))
                    setCurrentStage(3);
                })
            }else {
                validateForm(request, legalSecondStageForm, async ()=>{
                    setMainForm( Object.assign({}, mainForm, legalSecondStageForm))
                    setCurrentStage(3);
                })
            }

        }
        return(
            <div>
                {loading?<Loading/>:null}
                <div className={s.inputItem}>
                    <label>Оберіть країну*</label>
                    <CustomSelect customId="code" onSelect={(value,id)=>{
                        setLegalSecondStageForm({...legalSecondStageForm,country:id})
                    }} url="/api/v1/location/countries/"/>
                </div>
                <div className={s.inputItem}>
                    <label>Поштовий індекс*</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalSecondStageForm, setLegalSecondStageForm)}} name="postal_code"/>
                </div>
                <div className={s.inputItem}>
                    <label>Оберіть регіон*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setLegalSecondStageForm({...legalSecondStageForm,region:id})
                    }} url="/api/v1/location/regions/"/>
                </div>
                <div className={s.inputItem}>
                    <label>Оберіть населений пункт*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setLegalSecondStageForm({...legalSecondStageForm,settlement:id})
                    }} url="/api/v1/location/settlements/"/>
                </div>
                <div className={s.inputItem}>
                    <label>Вулиця*</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalSecondStageForm, setLegalSecondStageForm)}} name="street"/>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Номер будинку*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSecondStageForm, setLegalSecondStageForm)}} name="building_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер корпусу</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSecondStageForm, setLegalSecondStageForm)}} name="building_block_number"/>
                    </div>
                </div>
                <div className={s.inputItem}>
                    <label>Номер квартири/офісу*</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalSecondStageForm, setLegalSecondStageForm)}} name="apartment_or_office_number"/>
                </div>
                <div className={s.inputItem}>
                    <label>Email*</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalSecondStageForm, setLegalSecondStageForm)}} name="email"/>
                </div>
                <div className={s.inputItem}>
                    <label>Номер телефону*</label>
                    <InputMask onChange={numberChangeHandler} mask="+38 (099) 999-99-99" name="phone_number"/>
                    {/*<input onChange={(e)=>{formChangeHandler(e, legalSecondStageForm, setLegalSecondStageForm)}} name="phone_number"/>*/}
                </div>
                <div style={{padding:'0px', marginTop:'50px'}} className={s.checkboxItem}>
                    <input name="personal_data_process_consent" onChange={(e)=>{
                        if(e.target.checked){
                            setLegalSecondStageForm({...legalSecondStageForm,legal_entity_address_and_postal_address_is_different:true})
                            setIsAddressDifferent(true);
                        }else{
                            setLegalSecondStageForm({...legalSecondStageForm,legal_entity_address_and_postal_address_is_different:false})
                            setIsAddressDifferent(false);
                        }
                    }} type='checkbox'/>
                    <p>Юридична адреса та адреса для листування юридичної особи відрізняються</p>
                </div>
                {isAddressDifferent?<>
                    <div className={s.inputItem}>
                        <label>Оберіть країну*</label>
                        <CustomSelect customId="code" onSelect={(value,id)=>{
                            setPostalForm({...postalForm,postal_address_country:id})
                        }} url="/api/v1/location/countries/"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Поштовий індекс*</label>
                        <input onChange={(e)=>{formChangeHandler(e, postalForm, setPostalForm)}} name="postal_address_postal_code"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Оберіть регіон*</label>
                        <CustomSelect onSelect={(value,id)=>{
                            setPostalForm({...postalForm,postal_address_region:id})
                        }} url="/api/v1/location/regions/"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Оберіть населений пункт*</label>
                        <CustomSelect onSelect={(value,id)=>{
                            setPostalForm({...postalForm,postal_address_settlement:id})
                        }} url="/api/v1/location/settlements/"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Вулиця*</label>
                        <input onChange={(e)=>{formChangeHandler(e, postalForm, setPostalForm)}} name="postal_address_street"/>
                    </div>
                    <div className={s.itemRow}>
                        <div className={s.inputItem}>
                            <label>Номер будинку*</label>
                            <input onChange={(e)=>{formChangeHandler(e, postalForm, setPostalForm)}} name="postal_address_building_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Номер корпусу</label>
                            <input onChange={(e)=>{formChangeHandler(e, postalForm, setPostalForm)}} name="postal_address_building_block_number"/>
                        </div>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер квартири/офісу*</label>
                        <input onChange={(e)=>{formChangeHandler(e, postalForm, setPostalForm)}} name="postal_address_apartment_or_office_number"/>
                    </div>
                </>:null}

                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }

    function LegalThirdStage() {
        const[legalThirdStageForm, setLegalThirdStageForm] = useState({
            beneficial_owner:null,
            beneficial_owner_absence_reason:''
        });
        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            if(legalThirdStageForm.beneficial_owner) {
                validateForm(request, {...legalThirdStageForm, beneficial_owner_absence_reason: ''}, () => {
                    setMainForm(Object.assign({}, mainForm, {...legalThirdStageForm, beneficial_owner_absence_reason: ''}))
                    setCurrentStage(4);
                })
            }else {
                validateForm(request, legalThirdStageForm, () => {
                    setMainForm(Object.assign({}, mainForm, legalThirdStageForm))
                    setCurrentStage(4);
                })
            }
        }
        return(
            <div>
                {loading?<Loading/>:null}
                <h4 className={s.subHeader}>Кінцевий бенефіціарний власник:</h4>
                {/*<div className={s.inputItem}>*/}
                {/*    <label>Прізвище*</label>*/}
                {/*    <input/>*/}
                {/*</div>*/}
                {/*<div className={s.inputItem}>*/}
                {/*    <label>Ім'я*</label>*/}
                {/*    <input/>*/}
                {/*</div>*/}
                <div className={s.inputItem}>
                    <label>Бенефіціарний власник</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalThirdStageForm, setLegalThirdStageForm)}} name="beneficial_owner" placeholder="Прізвище Ім'я По-батькові"/>
                </div>
                <div className={s.inputItem}>
                    <label>Причини відсутності кінцевого бенефіціарного власника:</label>
                    <textarea onChange={(e)=>{formChangeHandler(e, legalThirdStageForm, setLegalThirdStageForm)}} name="beneficial_owner_absence_reason"/>
                </div>
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }

    function LegalFourthStage() {
        const[legalFourthStageForm, setLegalFourthStageForm] = useState({
            legal_entity_chief_first_name:'',
            legal_entity_chief_second_name:'',
            legal_entity_chief_last_name:'',
            legal_entity_chief_document_name:'',
            legal_entity_chief_document_date:null,
            legal_entity_chief_document_number:'',
            legal_entity_documents:null,
        });
        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            validateForm(request, legalFourthStageForm, ()=>{
                setMainForm( Object.assign({}, mainForm, legalFourthStageForm))
                setCurrentStage(5);
            })
        }
        return(
            <div>
                {loading?<Loading/>:null}
                <h4 className={s.subHeader}>Керівник юридичної особи:</h4>
                <div className={s.inputItem}>
                    <label>Ім'я керівника*</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalFourthStageForm, setLegalFourthStageForm)}} name="legal_entity_chief_first_name"/>
                </div>
                <div className={s.inputItem}>
                    <label>Прізвище керівника*</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalFourthStageForm, setLegalFourthStageForm)}} name="legal_entity_chief_last_name"/>
                </div>
                <div className={s.inputItem}>
                    <label>По батькові керівника*</label>
                    <input onChange={(e)=>{formChangeHandler(e, legalFourthStageForm, setLegalFourthStageForm)}} name="legal_entity_chief_second_name"/>
                </div>
                <div className={s.inputItem}>
                    <label>Назва документу, на підставі якого діє керівник*</label>
                    <textarea onChange={(e)=>{formChangeHandler(e, legalFourthStageForm, setLegalFourthStageForm)}} name="legal_entity_chief_document_name"/>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label onClick={()=>{console.log(legalFourthStageForm.legal_entity_chief_document_date_timestamp)}}>Дата документа</label>
                        <DatePicker
                            placeholder={legalFourthStageForm.legal_entity_chief_document_date}
                            dateFormat="yyyy-MM-dd"
                            name="legal_entity_chief_document_date"
                            selected={legalFourthStageForm.legal_entity_chief_document_date_timestamp}
                            onChange={(date)=>{
                                console.log(date)
                                const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                setLegalFourthStageForm({
                                    ...legalFourthStageForm,
                                    legal_entity_chief_document_date_timestamp: +date,
                                    legal_entity_chief_document_date: stringDate
                                })
                            }}
                            locale="ua-Uk"
                        />
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер документа</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalFourthStageForm, setLegalFourthStageForm)}} name="legal_entity_chief_document_number"/>
                    </div>
                </div>
                <FileSignMultiple
                    documentType='LEGAL_ENTITY_DOCUMENT'
                    onSelect={(id)=>{setLegalFourthStageForm({...legalFourthStageForm,  legal_entity_documents: id})}}
                    className={s.fileSign}
                    description="Завантажити документи юридичної особи. Максимальний розмір файлу 50 Мб"
                    title="Завантажити документ ЮО"
                />
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }

    function LegalFifthStage() {
        const[legalFifthStageForm, setLegalFifthStageForm] = useState({
            legal_entity_chief_tax_number: '',
            legal_entity_chief_has_tax_number: true,
            legal_entity_chief_tax_number_document: null,
            legal_entity_chief_identity_document_type: '',
            legal_entity_chief_identity_document_serial_number: '',
            legal_entity_chief_identity_document_number: '',
            legal_entity_chief_identity_document_issue_date: null,
            legal_entity_chief_identity_document: null
        });
        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            validateForm(request, legalFifthStageForm, ()=>{
                setMainForm( Object.assign({}, mainForm, legalFifthStageForm))
                setCurrentStage(6);
            })
        }
        return(
            <div>
                {loading?<Loading/>:null}
                <div className={s.rnokpp}>
                    <div className={s.inputItem}>
                        <label>РНОКПП керівника*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalFifthStageForm, setLegalFifthStageForm)}} name="legal_entity_chief_tax_number"/>
                    </div>
                    <div className={s.checkboxItem}>
                        <input name="legal_entity_chief_has_tax_number" onChange={(e)=>{
                            e.target.checked?
                                setLegalFifthStageForm({...legalFifthStageForm,legal_entity_chief_has_tax_number:true}):
                                setLegalFifthStageForm({...legalFifthStageForm,legal_entity_chief_has_tax_number:false})
                        }} type='checkbox'/>
                        <p>Керівник відмовився від РНОКПП через свої релігійні переконання та має про це відмітку в паспорті</p>
                    </div>
                </div>
                <FileSign
                    documentType='LEGAL_ENTITY_CHIEF_TAX_NUMBER'
                    onSelect={(id)=>{setLegalFifthStageForm({...legalFifthStageForm,  legal_entity_chief_tax_number_document: id})}}
                    className={s.fileSign}
                    description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
                    title="Завантажити РНОКПП"
                />
                <div className={s.gap}/>
                <div className={s.inputItem}>
                    <label>Документ що посвідчує особу*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setLegalFifthStageForm({...legalFifthStageForm,legal_entity_chief_identity_document_type:id})
                    }} data={[
                        {name:'паспорт громадянина України (ГУ)', id:'PASSPORT'},
                        {name:'паспорт ГУ у формі картки', id:'PASSPORT_AS_CARD'},
                    ]}/>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Серія</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalFifthStageForm, setLegalFifthStageForm)}} name="legal_entity_chief_identity_document_serial_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalFifthStageForm, setLegalFifthStageForm)}} name="legal_entity_chief_identity_document_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Дата видачі</label>
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={legalFifthStageForm.legal_entity_chief_identity_document_issue_date_timestamp}
                            onChange={(date)=>{
                                console.log(date)
                                const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                setLegalFifthStageForm({
                                    ...legalFifthStageForm,
                                    legal_entity_chief_identity_document_issue_date_timestamp: +date,
                                    legal_entity_chief_identity_document_issue_date: stringDate
                                })
                            }}
                            locale="ua-Uk"
                        />
                        {/*<input onChange={(e)=>{formChangeHandler(e, legalFifthStageForm, setLegalFifthStageForm)}} placeholder="2020-05-03" name="legal_entity_chief_identity_document_issue_date"/>*/}
                    </div>
                </div>
                <FileSign
                    documentType='LEGAL_ENTITY_CHIEF_IDENTITY_DOCUMENT'
                    onSelect={(id)=>{setLegalFifthStageForm({...legalFifthStageForm,  legal_entity_chief_identity_document: id})}}
                    className={s.fileSign}
                    description="Документ, що посвідчує особу. Максимальний розмір файлу 50 Мб"
                    title="Завантажити документ"
                />
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }

    function LegalSixthStage() {
        const [personType, setPersonType] = useState();
        const [legalSixthStageForm, setLegalSixthStageForm] = useState({
            legal_entity_authorized_person_type:'',
            legal_entity_authorized_person_last_name:'',
            legal_entity_authorized_person_first_name:'',
            legal_entity_authorized_person_second_name:'',
            legal_entity_authorized_person_authorization_document_name:'',
            legal_entity_authorized_person_authorization_document_number:'',
            legal_entity_authorized_person_authorization_document_issue_date:null,
            legal_entity_authorized_person_authorization_document_issuer:'',
            legal_entity_authorized_person_authorization_document:null,
            legal_entity_authorized_person_tax_number:'',
            legal_entity_authorized_person_has_tax_number:true,
            legal_entity_authorized_person_tax_number_document:null,
            legal_entity_authorized_person_identity_document_type:'',
            legal_entity_authorized_person_identity_document_serial_number:'',
            legal_entity_authorized_person_identity_document_number:'',
            legal_entity_authorized_person_identity_document_issue_date:null,
            legal_entity_authorized_person_identity_document:null,
            legal_entity_authorized_person_country:'',
            legal_entity_authorized_person_postal_code:'',
            legal_entity_authorized_person_region:'',
            legal_entity_authorized_person_settlement:'',
            legal_entity_authorized_person_street:'',
            legal_entity_authorized_person_building_number:'',
            legal_entity_authorized_person_building_block_number:'',
            legal_entity_authorized_person_apartment_or_office_number:'',
            legal_entity_authorized_person_email:'',
            legal_entity_authorized_person_phone_number:''
        });

        const numberChangeHandler = (e) =>{
            const number = e.target.value.replace(/[^0-9]/g,'');
            setLegalSixthStageForm({...legalSixthStageForm, 'legal_entity_authorized_person_phone_number': '+' + number});
        }

        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            if(personType==='REPRESENTATIVE'){
                validateForm(request, {...legalSixthStageForm, legal_entity_authorized_person_type:personType}, ()=>{
                    setMainForm( Object.assign({}, mainForm, {...legalSixthStageForm, legal_entity_authorized_person_type:personType}))
                    setCurrentStage(7);
                })
            }else{
                validateForm(request, {legal_entity_authorized_person_type:personType}, ()=>{
                    setMainForm( Object.assign({}, mainForm, {legal_entity_authorized_person_type:personType}))
                    setCurrentStage(7);
                })
            }

        }
        return(
            <div>
                {loading?<Loading/>:null}
                <div className={s.inputItem}>
                    <label>Особа, уповноважена діяти від імені юридичної особи*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setPersonType(id)
                    }} data={[{name:'Представник', id:'REPRESENTATIVE'},{name:'Керівник', id:'CHIEF'}]}/>
                </div>
                {personType === 'REPRESENTATIVE'?
                <>
                    <div className={s.inputItem}>
                        <label>Прізвище*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_last_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Ім'я*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_first_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>По батькові*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_second_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Назва документа що підтверджує повноваження*</label>
                        <textarea onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_authorization_document_name"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>№*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_authorization_document_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Дата видачі*</label>
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={legalSixthStageForm.legal_entity_authorized_person_authorization_document_issue_date_timestamp}
                            onChange={(date)=>{
                                console.log(date)
                                const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                setLegalSixthStageForm({
                                    ...legalSixthStageForm,
                                    legal_entity_authorized_person_authorization_document_issue_date_timestamp: +date,
                                    legal_entity_authorized_person_authorization_document_issue_date: stringDate
                                })
                            }}
                            locale="ua-Uk"
                        />
                        {/*<input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_authorization_document_issue_date"/>*/}
                    </div>
                    <div className={s.inputItem}>
                        <label>Ким видано*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_authorization_document_issuer"/>
                    </div>
                    <FileSign
                        documentType='LEGAL_ENTITY_AUTHORIZED_PERSON_AUTHORIZATION_DOCUMENT'
                        onSelect={(id)=>{setLegalSixthStageForm({...legalSixthStageForm,  legal_entity_authorized_person_authorization_document: id})}}
                        className={s.fileSign}
                        description="Завантажити документи юридичної особи. Максимальний розмір файлу 50 Мб"
                        title="Завантажити документ"
                    />
                    <div className={s.line + ' ' +s.gap}/>
                    <div className={s.rnokpp}>
                        <div className={s.inputItem}>
                            <label>РНОКПП*</label>
                            <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_tax_number"/>
                        </div>
                        <div className={s.checkboxItem}>
                            <input name="legal_entity_authorized_person_has_tax_number" onChange={(e)=>{
                                e.target.checked?
                                    setLegalSixthStageForm({...legalSixthStageForm,legal_entity_authorized_person_has_tax_number:true}):
                                    setLegalSixthStageForm({...legalSixthStageForm,legal_entity_authorized_person_has_tax_number:false})
                            }} type='checkbox'/>
                            <p>Я відмовився від РНОКПП через свої релігійні переконання та маю про це відмітку в паспорті</p>
                        </div>
                    </div>
                    <FileSign
                        documentType='LEGAL_ENTITY_AUTHORIZED_PERSON_TAX_NUMBER'
                        onSelect={(id)=>{setLegalSixthStageForm({...legalSixthStageForm,  legal_entity_authorized_person_tax_number_document: id})}}
                        className={s.fileSign}
                        description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
                        title="Завантажити РНОКПП"
                    />
                    <div className={s.gap}/>
                    <div className={s.inputItem}>
                        <label>Документ що посвідчує особу*</label>
                        <CustomSelect onSelect={(value,id)=>{
                            setLegalSixthStageForm({...legalSixthStageForm,legal_entity_authorized_person_identity_document_type:id})
                        }} data={[
                            {name:'паспорт громадянина України (ГУ)', id:'PASSPORT'},
                            {name:'паспорт ГУ у формі картки', id:'PASSPORT_AS_CARD'},
                        ]}/>
                    </div>
                    <div className={s.itemRow}>
                        <div className={s.inputItem}>
                            <label>Серія</label>
                            <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_identity_document_serial_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Номер</label>
                            <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_identity_document_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Дата видачі</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={legalSixthStageForm.legal_entity_authorized_person_identity_document_issue_date_timestamp}
                                onChange={(date)=>{
                                    console.log(date)
                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setLegalSixthStageForm({
                                        ...legalSixthStageForm,
                                        legal_entity_authorized_person_identity_document_issue_date_timestamp: +date,
                                        legal_entity_authorized_person_identity_document_issue_date: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                            {/*<input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} placeholder="2020-05-05" name="legal_entity_authorized_person_identity_document_issue_date"/>*/}
                        </div>
                    </div>
                    <FileSign
                        documentType='LEGAL_ENTITY_AUTHORIZED_PERSON_IDENTITY_DOCUMENT'
                        onSelect={(id)=>{setLegalSixthStageForm({...legalSixthStageForm,  legal_entity_authorized_person_identity_document: id})}}
                        className={s.fileSign}
                        description="Документ, що посвідчує особу. Максимальний розмір файлу 50 Мб"
                        title="Завантажити документ"
                    />
                    <div className={s.line + ' ' +s.gap}/>
                    <div className={s.inputItem}>
                        <label>Оберіть країну*</label>
                        <CustomSelect customId="code" onSelect={(value,id)=>{
                            setLegalSixthStageForm({...legalSixthStageForm,legal_entity_authorized_person_country:id})
                        }} url="/api/v1/location/countries/"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Поштовий індекс*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_postal_code"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Оберіть регіон*</label>
                        <CustomSelect onSelect={(value,id)=>{
                            setLegalSixthStageForm({...legalSixthStageForm,legal_entity_authorized_person_region:id})
                        }} url="/api/v1/location/regions/"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Оберіть населений пункт*</label>
                        <CustomSelect onSelect={(value,id)=>{
                            setLegalSixthStageForm({...legalSixthStageForm,legal_entity_authorized_person_settlement:id})
                        }} url="/api/v1/location/settlements/"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Вулиця*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_street"/>
                    </div>
                    <div className={s.itemRow}>
                        <div className={s.inputItem}>
                            <label>Номер будинку*</label>
                            <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_building_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Номер корпусу</label>
                            <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_building_block_number"/>
                        </div>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер квартири/офісу*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_apartment_or_office_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Email*</label>
                        <input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_email"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер телефону*</label>
                        <InputMask onChange={numberChangeHandler} mask="+38 (099) 999-99-99" name="phone_number"/>
                        {/*<input onChange={(e)=>{formChangeHandler(e, legalSixthStageForm, setLegalSixthStageForm)}} name="legal_entity_authorized_person_phone_number"/>*/}
                    </div>
                </>:<>
                    <div className={s.gap}/>
                    <div className={s.gap}/>
                    <div className={s.gap}/>
                    </>}
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }


    function FinishStage() {
        const [invoices, setInvoices] = useState([])
        useEffect(()=>{
            invoiceHandler();
        },[])
        const {requestErrors, request, loading} = useHttp();
        const invoiceHandler = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/${props.lotId}/invoices/`);
                if(data.length>0) setInvoices(data);
            }catch (e) {
                console.log(e);
            }
        }

        return(
            <div className={s.Finish}>
                {loading?<Loading/>:null}
                <div className={s.finishContent}>
                    <h2>ДЯКУЄМО ЗА вступ У АУКЦІОН</h2>
                    <p className={s.grey}>Ваші дані находяться в процесі перевірки. Наступним кроком є  оплата<br/> реєстраційного і гарантійного внесків </p>
                    <a target="_blank" href = {invoices.length>0?config.baseUrl + invoices[0].url: null} onClick={invoiceHandler} className={s.invoiceButton}>Рахунок для оплати реєстраційного внеску</a>
                    <a target="_blank" href = {invoices.length>0?config.baseUrl + invoices[1].url: null} className={s.invoiceButton}>Рахунок для оплати гарантійного внеску</a>
                    <button onClick={()=>{history.push('/mylots/auction/')}} style={{width:'60%'}} className={s.finishButton}>Перейти до особистого кабінету</button>
                </div>
            </div>
        )
    }

    const Stage = useCallback((props)=>{

        // 'INDIVIDUAL'   # Фізична особа
        // 'INDIVIDUAL_ENTREPRENEUR'     # Фізична особа-підприємець
        // 'LEGAL_ENTITY'   # Юридична особа
        if(currentStage==1) return <FirstStage/>
        switch (userType) {
            case 'INDIVIDUAL':
            case 'INDIVIDUAL_ENTREPRENEUR': {
                switch (+props.stageNumber) {
                    case 1: return <FirstStage/>
                    case 2: return <SecondStage/>
                    case 3: return <ThirdStage/>
                    case 4: return <FourthStage/>
                    default: return null;
                }
            }
            case 'LEGAL_ENTITY': {
                switch (+props.stageNumber) {
                    case 1: return <FirstStage/>
                    case 2: return <LegalSecondStage/>
                    case 3: return <LegalThirdStage/>
                    case 4: return <LegalFourthStage/>
                    case 5: return <LegalFifthStage/>
                    case 6: return <LegalSixthStage/>
                    case 7: return <FourthStage/>

                    default: return null;
                }
            }
            default : return null
        }

    },[currentStage, userType])

    const StagesProgress = ()=>{
        const stagesNumbers = [];
        const maxStages = userType==='LEGAL_ENTITY'?7:userType==='INDIVIDUAL_ENTREPRENEUR' || userType==='INDIVIDUAL'?4:0;
        if(!userType) return <div style={{height:'109px'}}/>
            for(let i=0; i<maxStages; i++){

                if(i+1<currentStage){
                    stagesNumbers.push(<img className={s.completedStage} src={checkmarkGreen}/>)
                    if(i!==maxStages-1)stagesNumbers.push(<div className={s.completedLine}/>)
                }else if(i+1===currentStage){
                    stagesNumbers.push(<p className={s.activeStage}>{i+1}</p>)
                    if(i!==maxStages-1)stagesNumbers.push(<div className={s.line}/>)
                }else {
                    stagesNumbers.push(<p>{i+1}</p>)
                    if(i!==maxStages-1)stagesNumbers.push(<div className={s.line}/>)
                }


            }

        return(
            <div className={s.stages}>
                {stagesNumbers}
            </div>
        )
    }

    return(
        <>
        {currentStage===0?
            <FinishStage/>:
            <div className={s.Participation}>
                    <h1>Вступ у аукціон</h1>
                    <div className={s.content}>
                        <h5 onClick={()=>{console.log(mainForm)}} className={s.contentTitle}>Шановний користувачу!</h5>
                        <p className={s.contentSubtitle}>Для участі в аукціоні Вам необхідно заповнити відповідні поля форми, що наведена нижче та сплатити реєстраційний і гарантійний внески.</p>
                        <StagesProgress/>
                        <Stage stageNumber={currentStage}/>
                    </div>
            </div>}
            </>
    )
}

export default Participation;