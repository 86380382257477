import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import config from "../../../config/config";
import s from "../RegisterRedesign.module.css";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import React, {useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import {AuthContext} from "../../../context/AuthContext";
import Modal from "../../../components/Modal/Modal";
import {useHistory} from 'react-router-dom';
import Loading from "../../../components/Loading/Loading";
import ErrorHandler from "../../../utils/ErrorLoger";

const ThirdStage = ({mainForm}) => {
        const auth = useContext(AuthContext)
        const history = useHistory();
        const [tokens, setTokens] = useState(null);
        const {request, requestErrors, loading} = useHttp()
        const formValidationSchema = Yup.object().shape({
            rule_accepted: Yup.bool()
                .oneOf([true], 'Поле обов\'язкове'),
            personal_data_process_consent: Yup.bool()
                .oneOf([true], 'Поле обов\'язкове'),
            actual_address: Yup.object().shape({
                postal_code: Yup.string()
                    .required('Поле обов\'язкове'),
                region_id: Yup.string()
                    .required('Поле обов\'язкове'),
                settlement_id: Yup.string()
                    .required('Поле обов\'язкове'),
                district_id: Yup.string()
                    .required('Поле обов\'язкове'),
                address: Yup.string()
                    .required('Поле обов\'язкове'),
            }),
        });


        return (
            <>
                {loading && <Loading/>}
            <Formik
                validationSchema={formValidationSchema}
                initialValues={{}}
                onSubmit={async (values)=>{
                    try{
                        let data = await request(config.baseUrl + '/api/v1/user/registration/complete/','PUT', {...mainForm,...values});
                        if(data && data.token && data.token.access) {
                            setTokens({access:data.token.access, refresh:data.token.refresh});
                        }

                    }catch(e){
                        ErrorHandler(e)
                    }
                }}
            >
                {props =>
                    <Form>
                        {tokens &&
                            <Modal isVisible={true} onClose={()=>{
                                auth.login(tokens.access, tokens.refresh);
                                history.push('/');
                            }}>
                                <div className={s.successModal}>
                                    <h1>Користувача успішно зареєстровано</h1>
                                    <button onClick={()=>{
                                        auth.login(tokens.access, tokens.refresh)
                                        history.push('/');
                                    }}>Готово</button>
                                </div>
                            </Modal>
                        }
                        <h3 className={s.subtitle}>Місцезнаходження {
                            mainForm.profile_type === 'LEGAL_ENTITY'? 'юридичної особи':
                            mainForm.profile_type === 'INDIVIDUAL'? 'фізичної особи':
                            mainForm.profile_type === 'INDIVIDUAL_ENTREPRENEUR'? 'фізичної особи - підприємця':'особи'
                        }</h3>
                        <p className={s.grayText}>ЮРИДИЧНА АДРЕСА</p>
                        <div className={s.itemRow}>
                            <div className={s.inputItem + ' '+ s.short}>
                                <label>Область:</label>
                                <CustomSelect
                                    onSelect={(value,id) => {
                                        props.setFieldValue("actual_address.region_id",id,false);
                                    }}
                                    url="/api/v1/location/regions/"
                                />
                                <p className={s.error}>
                                    {props.errors.actual_address?.region_id}
                                </p>
                            </div>
                            <div className={s.inputItem + ' '+ s.short}>
                                <label>Район:</label>
                                <CustomSelect
                                    onSelect={(value,id) => {
                                        props.setFieldValue("actual_address.district_id",id,false);
                                    }}
                                    url="/api/v1/location/districts/"
                                />
                                <p className={s.error}>
                                    {props.errors.actual_address?.district_id}
                                </p>
                            </div>
                        </div>

                        <div className={s.itemRow}>
                            <div className={s.inputItem + ' '+ s.short}>
                                <label>Населений пункт:</label>
                                <CustomSelect
                                    onSelect={(value,id) => {
                                        props.setFieldValue("actual_address.settlement_id",id,false);
                                    }}
                                    url="/api/v1/location/settlements/"
                                />
                                <p className={s.error}>
                                    {props.errors.actual_address?.settlement_id}
                                </p>
                            </div>
                            <div className={s.inputItem + ' '+ s.short}>
                                <label htmlFor="actual_address_address">Адреса:</label>
                                <Field id="actual_address_address" name="actual_address.address"/>
                                <p className={s.error}>
                                    {props.errors.actual_address?.address}
                                </p>
                            </div>
                        </div>

                        <div className={s.inputItem + ' '+ s.short}>
                            <label htmlFor="actual_address_postal_code">Індекс:</label>
                            <Field id="actual_address_postal_code" name="actual_address.postal_code"/>
                            <p className={s.error}>
                                {props.errors.actual_address?.postal_code}
                            </p>
                        </div>

                        <div className={s.checkboxItem}>
                            <Field name="address_is_different" type="checkbox"/>
                            <p>Юридична адреса знаходження юридичної особи збігається з її фактичною адресою</p>
                        </div>

                        {!props.values.address_is_different &&
                        <>
                            <h3 className={s.subtitle}>Місцезнаходження {
                                mainForm.profile_type === 'LEGAL_ENTITY'? 'юридичної особи':
                                    mainForm.profile_type === 'INDIVIDUAL'? 'фізичної особи':
                                        mainForm.profile_type === 'INDIVIDUAL_ENTREPRENEUR'? 'фізичної особи - підприємця':'особи'
                            }</h3>
                            <p className={s.grayText}>ЮРИДИЧНА АДРЕСА</p>
                            <div className={s.itemRow}>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Область:</label>
                                    <CustomSelect
                                        onSelect={(value,id) => {
                                            props.setFieldValue("legal_address.region_id",id,false);
                                        }}
                                        url="/api/v1/location/regions/"
                                    />
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Район:</label>
                                    <CustomSelect
                                        onSelect={(value,id) => {
                                            props.setFieldValue("legal_address.district_id",id,false);
                                        }}
                                        url="/api/v1/location/districts/"
                                    />
                                </div>
                            </div>

                            <div className={s.itemRow}>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label>Населений пункт:</label>
                                    <CustomSelect
                                        onSelect={(value,id) => {
                                            props.setFieldValue("legal_address.settlement_id",id,false);
                                        }}
                                        url="/api/v1/location/settlements/"
                                    />
                                </div>
                                <div className={s.inputItem + ' '+ s.short}>
                                    <label htmlFor="legal_address_address">Адреса:</label>
                                    <Field id="legal_address_address" name="legal_address.address"/>
                                </div>
                            </div>

                            <div className={s.inputItem + ' '+ s.short}>
                                <label htmlFor="legal_address_postal_code">Індекс:</label>
                                <Field id="legal_address_postal_code" name="legal_address.postal_code"/>
                            </div>
                        </>
                        }
                        <div className={s.checkboxItem}>
                            <Field name="rule_accepted" type="checkbox"/>
                            <p>Погоджуюся з правилами електронного майданчика</p>
                        </div>
                        <div className={s.checkboxItem}>
                            <Field name="personal_data_process_consent" type="checkbox"/>
                            <p>Даю згоду на обробку персональних даних</p>
                        </div>
                        {requestErrors && <p className={s.error}>{requestErrors[0]}</p>}
                        <button
                            className={s.registerButton}
                            type="submit"
                        >Зареєструватися</button>
                    </Form>
                }
            </Formik>
                </>
        )
    }

    export default ThirdStage;