import React, {Fragment, useRef, useState, useEffect} from 'react';
import s from './PaymentSign.module.css';
import fileImg from '../../assets/img/document-icon.png';
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import loading from '../../assets/img/loading.svg';
import ErrorHandler from "../../utils/ErrorLoger";
function PaymentSign(props) {
    const inputRef = useRef();
    const [isLoading, setLoading] = useState(false);
    const [fileId, setFileId] = useState();
    const [document, setDocument] = useState();
    const {request} = useHttp();
    function getBase64(file) {
        const reader = new FileReader()
        const fileExtension = file.name.split('.').pop();
        const fileName = file.name.split('.')[0];
        return new Promise(resolve => {
            reader.onload = ev => {
                    resolve({
                        "file_extension": fileExtension,
                        "encoded_file": ev.target.result,
                        "filename": fileName,
                        "payment_type": props.paymentType
                    })
            }
            reader.readAsDataURL(file)
        })
    }


    const deleteHandler = async () => {
        try{
            let data = await request(config.baseUrl + `/api/v1/user/documents/${fileId}/delete/`, 'DELETE');
            if(data.status == 'OK') {
                setDocument(null);
                setFileId(null);
                props.onSelect(null);
            }
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const documentsHandler = async (fileList) => {
        if(fileList.length){
            console.log(fileList);
            setLoading(true);
            const documentObj = await getBase64(fileList[0]);
            setDocument(documentObj);
            let data;
            try{
                data = await request(config.baseUrl + `/api/v1/auctions/${props.auctionId}/receipt/upload/`,'POST', documentObj);

                if(data.file_id) {
                    setFileId(data.file_id);
                    props.onSelect(data.file_id);
                }
            }catch(e){
                ErrorHandler(e)
            }
            setLoading(false);
        }
    }

    useEffect(()=>{
        if(props.file) setDocument(props.file);
    },[props])

    return(
        <>
        <div className={props.className}>
            <div style={{'width':'40%'}}>
                <input ref={inputRef} style={{display:'none'}} onChange={(e)=>documentsHandler(e.target.files)} type="file" accept=".jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx"/>
                <div className={s.fileUpload} onClick={(e)=>{inputRef.current.click()}}>
                    <div className={s.fileUploadPlus}>
                        <p>+</p>
                    </div>
                    <p>{props.title}</p>
                </div>
            </div>
            {document || isLoading?<div className={s.uploadedFile}>
                <div className={s.uploadedFileTitle}>
                    <img src={isLoading?loading:fileImg}/>
                    <p>{document?document.file_extension?document.filename+'.'+document.file_extension:document.filename:null}</p>
                </div>
            </div>:null}
        </div>
            <p className={s.fileUploadDescription}>{props.description}</p>
            </>
    )
}

export default PaymentSign;