import React, {useState, useEffect, useRef} from 'react';
import s from './CustomSelect.module.css';
import {useHttp} from '../../hooks/HttpHook';
import config from '../../config/config.js';
import Select from "react-dropdown-select";
import './CustomSelect.css';
import ErrorHandler from "../../utils/ErrorLoger";

function CustomSelect(props){
    const [optionsList, setOptionsList] = useState([]);
    const [labelName, setLabelName] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const {request} = useHttp();
//TODO rewrite customSelect component

    useEffect( async ()=>{

        const getOptions = async (url) => {
            let data;
            try{
                data = await request(config.baseUrl + url, 'GET');
            }catch(e){
                ErrorHandler(e)
            }
            return data;
        }

        const setupSelect = async () => {
            let options;
            if(props.data) options = props.data;
            else if(props.url) options = await getOptions(props.url);
            let label = null;
            try {
                Object.keys(options[0]).map((key)=>{
                    if(!label) label = key==='name'?'name':key==='description'?'description':key==='display_name'?'display_name':key==='option_display_name'?'option_display_name':null;
                })
            }catch (e) {
                ErrorHandler(e)
                console.error('error in:', props.data || props.url, e)
            }
            setLabelName(label);
            setOptionsList(options);
            if('defaultValueId' in props){
                options.forEach((el)=>{
                    if(props.customId){
                        if(props.defaultValueId.toString()===el[props.customId].toString()) {
                            setSelectedValue(el)
                        }
                    }else {
                        if(props.defaultValueId.toString()===el.id.toString()) {
                            setSelectedValue(el)
                        }
                    }
                })
            }
            if(props.defaultValueByName){
                options.forEach((el)=>{
                    if(props.defaultValueByName === el[label]) setSelectedValue(el)
                })
            }
        }

        await setupSelect();
    },[])


    const noDataRenderer = () => {
        return <p>Не знайдено</p>
    }

    
    return(
    <Select
            disabled={props.disabled}
            className={props.disabled?s.select+' '+'select-disabled':s.select}
            values={props.defaultValue?props.defaultValue:selectedValue?[selectedValue]:[]}
            color={'white'}
            noDataRenderer={noDataRenderer}
            options={optionsList}
            labelField={labelName?labelName:null}
            placeholder={props.placeholder?props.placeholder:''}
            addPlaceholder=""
            searchBy={labelName?labelName:null}
            onChange={(values) => {if(values.length>0) {
                if(props.customId) props.onSelect(values, values[0][props.customId])
                else props.onSelect(values, values[0].id)
            }}}
        />
    )
}

export default CustomSelect;