import React, {Fragment, useState, useRef, useContext, useEffect} from 'react';
import s from './Header.module.css'
import {NavLink, Link, useLocation} from 'react-router-dom';
import logo from '../../assets/img/logo2.svg';
import {AuthContext} from '../../context/AuthContext';
import  userIcon from '../../assets/img/userIcon.png';
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import ErrorHandler from "../../utils/ErrorLoger";


function Header(props){
    const {request} = useHttp();
    const [navStyle, setNavStyle] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [sideBar, setSideBar] = useState(false);
    const [userMenuShown, setUserMenuShown] = useState(false);
    const [newNotificationsLength, setNewNotificationsLength] = useState(0);
    const location = useLocation()
    const sideBarButton = useRef(null);
    const {logout} = useContext(AuthContext);
    const closeSideBar = () => {
        sideBarButton.current.checked = false;
    }


    useEffect(()=>{
        const getData = async () => {
            let data;
            let notifications;
            try{
                if(props.isLogined) data = await request(config.baseUrl + '/api/v1/user/info/', 'GET');
                if(data && data.phone_number) setUserInfo(data);
                if(props.isLogined) notifications = await request(config.baseUrl + '/api/v1/notifications/?is_read=False', 'GET');
                if(notifications && notifications.results && notifications.results.length>0) setNewNotificationsLength(notifications.results.length);
                else setNewNotificationsLength(0);
            }catch(e){
                ErrorHandler(e)
            }
        }

        getData()
    },[props])
    return(
        <Fragment>
                <nav className={s.navigation} style={navStyle}>
                <Link to="/auction/" className={s.logo}><img src={logo} alt="logo" /></Link>
                <ul className={s.list}>
                    <div className={s.bottomButtons}>
                    {props.isLogined?
                        <li><Link className={s.sellLotBut} to="/create/lot/">Продати</Link></li>:
                        <li><Link className={s.loginBut} to="/login/">Увійти</Link></li>}
                    {props.isLogined?
                        <Link to="/mylots/auction" className={s.userBar}>
                            {userInfo?<img alt="img" src={userInfo.photo_url?(config.baseUrl+userInfo.photo_url):userIcon} className={s.userIcon}/>:null}
                            <p className={s.cabinetText}>Мій кабінет</p>
                            <div style={!newNotificationsLength?{visibility:'hidden'}:{}} className={s.notificationCircle}><span>{newNotificationsLength>9?'9+':newNotificationsLength}</span></div>
                        </Link>:null}
                    </div>
                </ul>
                </nav>
                <div className={s.fakeDiv}></div>
        </Fragment>
    )
}

export default Header;