import React from 'react';
import s from './PreviewItem.module.css';
import docIcon from '../../assets/img/document-icon.png'
import isImageUrl from 'is-image-url';
import config from '../../config/config';
function PreviewItem(props){
    const isImage = props.item.encoded_file.split('/')[0]=="data:image";
    const isImageLink = isImageUrl(config.baseUrl + props.item.encoded_file)
    return(
        <div className={s.PreviewItem}>
            {isImage || isImageLink?
            <img className={s.image} src={isImageLink?config.baseUrl + props.item.encoded_file:props.item.encoded_file}/>:
            <div className={s.document}>
                <img src={docIcon}/>
            </div>}
            <div className={s.PreviewItemText}>
                <h5>{props.item.filename}</h5>
                <p className={s.deleteButton} onClick={()=>{props.onDelete(props.id)}}>Видалити</p>
            </div>
        </div>
    )
}

export default PreviewItem;