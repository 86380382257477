import React, {Fragment, useEffect, useRef, useState} from 'react';
import s from './FileSignMultiple.module.css';
import fileImg from '../../assets/img/document-icon.png';
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import loading from '../../assets/img/loading.svg';
import ErrorHandler from "../../utils/ErrorLoger";
function FileSignMultiple(props) {
    const inputRef = useRef();
    const [isLoading, setLoading] = useState(false);
    const [documentIds, setDocumentIds] = useState([]);
    const [previewDocuments, setPreviewDocuments] = useState([]);
    const {request, loading} = useHttp();

    const deleteDocumentsHandler = async (id) => {
        let oldIds = [...documentIds];
        const documentId = oldIds.splice(id, 1);
        setDocumentIds(oldIds);

        let oldPreviews = [...previewDocuments];
        oldPreviews.splice(id, 1);
        setPreviewDocuments(oldPreviews);
        try{
            await request(config.baseUrl + `/api/v1/user/documents/${documentId}/delete/`, 'DELETE');
        }catch (e) {
            ErrorHandler(e)
        }
        props.onSelect(oldIds);
    }

    const documentsHandler = async (files) => {
        const documents = await fileListToBase64(files);
        let promises = [];
        let ids = [];
        let preview = [];
        documents.map((document)=>{
            if(!previewDocuments.some((el)=>el.encoded_file === document.encoded_file)){
                const promise = request(config.baseUrl + '/api/v1/user/documents/upload/','POST', document);
                promise.then((data)=>{
                    ids.push(data.file_id);
                    preview.push(document);
                })
                promises.push(promise);
            }
        })
        Promise.all(promises).then(()=>{
            setDocumentIds([...documentIds,...ids]);
            setPreviewDocuments([...previewDocuments,...preview]);
            props.onSelect([...documentIds,...ids]);
        })
        //request(config.baseUrl + '/api/v1/land-market/lands/documents/upload/','POST', document);
    }

    async function fileListToBase64(fileList) {
        function getBase64(file) {
            const reader = new FileReader()
            const fileExtension = file.name.split('.').pop();
            const fileName = file.name.split('.')[0];
            return new Promise(resolve => {
                reader.onload = ev => {
                    resolve({
                        "file_extension": fileExtension,
                        "encoded_file": ev.target.result,
                        "filename": fileName,
                        "is_main_picture": false,
                        "document_type":props.documentType
                    })
                }
                reader.readAsDataURL(file)
            })
        }
        const promises = []
        for (let i = 0; i < fileList.length; i++) {
            promises.push(getBase64(fileList[i]))
        }
        return await Promise.all(promises)
    }

    useEffect(()=>{
       if(props.defaultValues){
           setDocumentIds(props.defaultValues.map(el=>el.id));
           setPreviewDocuments(props.defaultValues);
       }
    },[props.defaultValues])

    return(
        <div className={props.className}>
            {previewDocuments.map((el,i)=>{
                return <div className={s.uploadedFile}>
                    <div className={s.uploadedFileTitle}>
                        <img src={isLoading?loading:fileImg}/>
                        <p>{el.file_extension?el.filename+'.'+el.file_extension:el.filename}</p>
                    </div>
                    <i onClick={()=>{deleteDocumentsHandler(i)}} className="fas fa-times"/>
                </div>
            })}
            <input ref={inputRef} style={{display:'none'}} onChange={(e)=>documentsHandler(e.target.files)} type="file" multiple accept=".jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx"/>
            <div className={s.fileUpload} onClick={(e)=>{inputRef.current.click()}}>
                <div className={s.fileUploadPlus}>
                    <p>+</p>
                </div>
                <p>{props.title}</p>
            </div>
            <p className={s.fileUploadDescription}>{props.description}</p>
        </div>
    )
}

export default FileSignMultiple;