import React, {useState, useContext} from 'react';
import s from './MyLotsCard.module.css';
import {Link} from 'react-router-dom';
import config from '../../config/config.js';
import locationIcon from '../../assets/img/combined-shape_2.png';
import typeIcon from '../../assets/img/combined-shape_3.png';
import timeIcon from '../../assets/img/combined-shape_4.png';
import {useHttp} from '../../hooks/HttpHook';
import jwt from "jwt-decode";
import {AuthContext} from "../../context/AuthContext";
import ErrorHandler from "../../utils/ErrorLoger";
function MyLotsCard(props){
    const auth = useContext(AuthContext)
    const {request} = useHttp();
    const [deleteModal, setDeleteModal] = useState(false);
    const item = props.item;
    let userId;
    if(auth.token){
        userId = jwt(auth.token).user_id;
    }
    const location = item.location;
    const linkUrl = item.auction ? `/lot/${item.auction.id}`:`/land/${item.id}`;
    const [applicationsModal, setApplicationsModal] = useState(false);
    const [applications, setApplications] = useState([]);
    const applicationsHandler = async (e) => {
        e.preventDefault();
        console.log(item);
        let data;
            try {
                if(item.auction) data = await request(config.baseUrl + `/api/v1/auctions/${item.auction.id}/buy-requests/`, 'GET');
                else data = await request(config.baseUrl + `/api/v1/land-market/lands/${item.id}/buy-requests/`, 'GET');
                setApplications(data.results);
                setApplicationsModal(true);
            } catch (e) {
                ErrorHandler(e)
            }
    }

    return(
        <Link to={item.status!="IN_MODERATION" && item.status!="REJECTED"?linkUrl:"#"} className={s.Card}>
            <img alt="img" className={s.mainImg} src={config.baseUrl + item.photo_url}/>
            <div className={s.cardContent}>
                <div className={s.left}>
                    <h5>Кадастровий номер:</h5>
                    <p href="/">{item.cadastral_number}</p>
                    <p className={s.description}>{item.description}
                    </p>
                    <div className={s.descContent}>
                        <div className={s.type}>
                            <img alt="img" className={s.icon} src={typeIcon}/>
                            <p className={s.itemDescription}>Цільове призначення:</p>
                        </div>
                        <p>{item.purpose.description}</p>
                        <div className={s.time}>
                            <img alt="img" className={s.icon} src={timeIcon}/>
                            <p className={s.itemDescription}>Дата створення:</p>
                        </div>
                        <p>{item.created_at}</p>
                        <div className={s.location}>
                            <img alt="img" className={s.icon} src={locationIcon}/>
                            <p className={s.itemDescription}>Розташування:</p>
                        </div>
                        <p>{`${location.street?location.street.name+',':null} ${location.settlement.name}, ${location.region.name}, ${location.district.name} район`}</p>

                    </div>
                </div>
                <div className={s.right}>
                    <div className={s.saveBtn}><i className="far fa-star"></i><p>Зберегти</p></div>
                    {item.auction?<div className={s.startingPrice}><p>Стартова ціна</p><h4>{item.auction.min_price}грн</h4></div>:
                        <h4>{item.auction?item.auction.price:item.price}</h4>}
                    <p>{item.area} га</p>
                    {/*{isAuction?<div><p>Завершується:</p><p>28.02.21 14:33 </p></div>:null}*/}
                </div>
            </div>
            {item.status==="IN_MODERATION"?<div className={s.moderationShadow}>
                <i className="fas fa-ellipsis-h"></i>
                <h1>Модерується</h1>
            </div>:null}

            {item.status==="REJECTED"?<div className={s.rejectedShadow}>
                <i className="fas fa-lock"></i>
                <h1>Заблоковано</h1>
            </div>:null}

            {userId==item.user.id?<div className={s.controlBox}>
                {item.status==="IN_MODERATION" && item.status!=="REJECTED"?<i onClick={(e)=>{e.preventDefault(); setDeleteModal(true)}} className={s.trash + " far fa-trash-alt"}></i>:null}
                {item.status==="IN_MODERATION" && item.status!=="REJECTED"?<i onClick={(e)=>{e.preventDefault(); props.onEditClick(props.item)}} className={s.pen + " fas fa-pen"}></i>:null}
                {item.status!=="IN_MODERATION" && item.status!=="REJECTED"?<p onClick={applicationsHandler} className={s.controlApplications}>
                    <div className={s.controlMail}>
                        <i className={s.mailEnvelope + " far fa-envelope"}></i>
                        <i className={s.mailCircle + " fas fa-circle"}></i>
                    </div>
                    Заявки
                </p>:null}
            </div>:<h3 className={s.auctionMember}>Учасник аукціону</h3>}

            {deleteModal?<div className={s.deleteModal}>
                <div className={s.deleteModalContent}>
                    <h1>Видалити лот</h1>
                    <p>Ви справді бажаєте видалити даний лот?</p>
                    <div>
                        <button className={s.closeDeleteModal} onClick={(e)=>{e.preventDefault(); setDeleteModal(false)}}>Відмінити</button>
                        <button className={s.confirmDeleteModal} onClick={(e)=>{e.preventDefault(); props.onDeleteClick(props.item); setDeleteModal(false)}}>Видалити</button>
                    </div>
                </div>
            </div>:null}

            {applicationsModal?<div className={s.deleteModal}>
                <div className={s.deleteModalContent}>
                    <h1>Заявки на купівлю</h1>
                    {applications.length>0?applications.map((el)=>{
                        return <div className={s.applicationItem}>
                            <p>{el.name}</p>
                            <p>{el.email}</p>
                            <p>{el.phone_number}</p>
                        </div>
                    }):<p>Заявок поки немає</p>}
                    <div>
                        <button className={s.closeDeleteModal} onClick={(e)=>{e.preventDefault(); setApplicationsModal(false)}}>Закрити</button>
                    </div>
                </div>
            </div>:null}
        </Link>
    )
}

export default MyLotsCard;