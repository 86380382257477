import s from "../../../pages/Auction/Auction.module.css";
import React from "react";

const SearchFilter = ({value, onChange}) => {
    
    return(
        <div className={s.filterItem + ' ' + s.filterItemSearch}>
            <label>Пошук</label>
            <div className={s.filterInput}>
                <i className="fas fa-search"></i>
                {/*//TODO focus is blured when typing text*/}
                <input
                    value={value}
                    onChange={onChange}
                    // placeholder="Кадастровий номер, номер аукціону"
                />
            </div>
        </div>
    )
}

export default SearchFilter;