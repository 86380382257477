import React from 'react';
import s from './Modal.module.css';
import OutsideClickHandler from "react-outside-click-handler";


function Modal(props) {
    return(
        <>
            {props.isVisible &&
                <div style={props.style} className={props.transparentBackground?s.ModalTransparent:s.Modal}>
                    <OutsideClickHandler onOutsideClick={props.onClose}>
                      <div className={s.ModalContent}>
                          <i onClick={props.onClose} className={"fas fa-times " + s.close}/>
                          {props.children}
                      </div>
                    </OutsideClickHandler>
                </div>}
        </>
    )
}

export default Modal;