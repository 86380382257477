import React, {useContext, useEffect, useState} from 'react';
import s from './Contracts.module.css';
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {useHttp} from "../../hooks/HttpHook";
import config from '../../config/config'
import icon from '../../assets/img/document-icon.png';
import {FileDrop} from "react-file-drop";
import PreviewItem from "../../components/PreviewItem/PreviewItem";
import OutsideClickHandler from 'react-outside-click-handler';
import ErrorHandler from "../../utils/ErrorLoger";
import SignComponent from "../../components/SignComponent/SignComponent";
import {AuthContext} from "../../context/AuthContext";

import ContractItem from "./components/ContractItem";
function Contracts() {
    const [winnerContracts, setWinnerContracts] = useState([]);
    const [organizerContracts, setOrganizerContracts] = useState([]);
    const {request} = useHttp();

    const getData = async () => {
        try{
            let _winnerContracts = await request(config.baseUrl + '/api/v1/auctions/auction-contracts/contracts/winner/','GET');
            let _organizerContracts = await request(config.baseUrl + '/api/v1/auctions/auction-contracts/contracts/organizer/','GET');
            if(_winnerContracts) setWinnerContracts(_winnerContracts)
            if(_organizerContracts) setOrganizerContracts(_organizerContracts)
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const WinnerContracts = () => {
        return (
            <div className={s.monthDiv}>
                {winnerContracts.length>0?
                    winnerContracts.map((elem,i)=> <ContractItem onUpdate={getData} type={"winner"} key={i} item={elem}/>):
                    <h3 className={s.emptyInvoices}>Немає доступних протоколів</h3>}
            </div>
        )
    }

    const OrganizerContracts = () => {
        return (
            <div className={s.monthDiv}>
                {organizerContracts.length>0?
                    organizerContracts.map((elem,i)=> <ContractItem onUpdate={getData} type={"organizer"} key={i} item={elem}/>):
                    <h3 className={s.emptyInvoices}>Немає доступних протоколів</h3>}
            </div>
        )
    }

    useEffect( async ()=>{
        await getData();
    },[])

    return(
        <div style={s.Protocols}>
            <div className={s.headerBox}>
                <h1>Договори</h1>
            </div>
            {/*<h2 className={s.typeTitle}>Переможець</h2>*/}
            <WinnerContracts/>
            {/*<h2 className={s.typeTitle}>Організатор</h2>*/}
            {/*<OrganizerContracts/>*/}
        </div>
    )
}

export default Contracts;