import React from 'react';
import s from './LotItem.module.css';
import image from '../../assets/img/topSearchBackground.jpg';
import {Link} from "react-router-dom";
import checkmarkIcon from '../../assets/img/checkmark-blue.png';
import config from '../../config/config';
import ContractComponent from "./components/ContractComponent";
import DocumentsComponent from "./components/DocumentsComponent";
import {useHttp} from "../../hooks/HttpHook";
import ErrorHandler from "../../utils/ErrorLoger";
function LotItem({onClose, item}) {

    const CardComponent = () => {
        return(
            <div className={s.CardComponent}>
                <div className={s.cardTop}>
                    <p className={s.backButton} onClick={onClose}>
                        <i className="fas fa-long-arrow-alt-left"/>
                        Назад
                    </p>
                    <p>Номер земельних торгів</p>
                </div>
                <div className={s.cardContent}>
                    <img src={config.baseUrl + item.photo_url}/>
                    <div className={s.cardContentText}>
                        <div className={s.cardContentTop}>
                            <p>Оголошення про проведення земельних торгів:</p>
                            <Link to="/">Ділянка під забудову в межах міста у місті Тернопіль .Назва у два рядки</Link>
                        </div>
                        <div className={s.cardContentBottom}>
                            <Link to={`/edit/${item.number}/`} className={s.viewButton}>Переглянути</Link>
                            <button className={s.cancelButton}>Скасувати торги</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    const PaymentComponent = () => {
        const {request} = useHttp();
        const confirmPayment = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/${item.id}/mark-as-payed/`,'PUT');
            }catch (e) {
                ErrorHandler(e)
            }
        }

        return(
            <div className={s.PaymentComponent}>
                <h2 className={s.title}>Оплата</h2>
                <p className={s.paymentStatus}>Статус:</p>
                <p className={s.paymentStatusText}>Очікується</p>
                    <h5 className={s.actionsTitle}>Доступні дії:</h5>
                    <div className={s.buttonsTop}>
                        <button onClick={confirmPayment}><img src={checkmarkIcon}/>Підтвердити оплату</button>
                        <p>або</p>
                        <button><i className="fas fa-times"/>Сформувати акт про несплату</button>
                    </div>
            </div>
        )
    }

    return(
        <div className={s.LotItem}>
            <CardComponent/>
            <DocumentsComponent item={item}/>
            <ContractComponent item={item}/>
            {item.auction_status === "AWAITING_PAYMENT" && <PaymentComponent/>}
        </div>
    )
}

export default LotItem;