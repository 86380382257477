import React, {useEffect, useState} from 'react';
import s from './Invoices.module.css';
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {useHttp} from "../../hooks/HttpHook";
import config from '../../config/config'
import icon from '../../assets/img/document-icon.png';
import file from '../../assets/img/pdf-file.png';
import {FileDrop} from "react-file-drop";
import PreviewItem from "../../components/PreviewItem/PreviewItem";
import OutsideClickHandler from 'react-outside-click-handler';
import ErrorHandler from "../../utils/ErrorLoger";
function Invoices() {
    const [invoices, setInvoices] = useState([]);
    const {request} = useHttp();




    useEffect( async ()=>{
        let data;
        try{
            data = await request(config.baseUrl + '/api/v1/user/invoices/','GET');
            if(data && data.results){
                setInvoices(data.results)
            }
        }catch (e) {
            ErrorHandler(e)
        }
    },[])

    return(
        <div style={s.Documents}>
            <div className={s.headerBox}>
                <h1>Рахунки</h1>
            </div>
            <div className={s.monthDiv}>
                {invoices.length>0?invoices.map((elem,i)=> {
                    return <div className={s.monthCard} key={i}>
                        <div>
                            <p className={s.date}>23.08.2021</p>
                            <div className={s.cardContent}>
                                <img src={file}/>
                                <p>{elem.filename}</p>
                            </div>
                        </div>
                        <a documentId={elem.id} download={elem.filename} target="_blank" href={config.baseUrl + elem.url} className={s.cardDelete}>Переглянути</a>
                    </div>
                }):<h3 className={s.emptyInvoices}>Немає доступних рахунків</h3>}
            </div>
        </div>
    )
}

export default Invoices;