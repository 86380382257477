// module.exports = {
//     "baseUrl": process.env.REACT_SERVER_URL || "http://rynok-zemli.com.ua",
//     "baseWsUrl": "ws://rynok-zemli.com.ua"
// }

module.exports = {
    //"baseUrl": "http://15f12a904fc0.ngrok.io",
    "baseUrl": process.env.REACT_APP_BASE_URL || "https://private.rynok-zemli.com.ua",
    "baseWsUrl": process.env.REACT_APP_WEBSOCKET_BASE_URL || "ws://private.rynok-zemli.com.ua",
    "googleClientID": process.env.REACT_APP_GOOGLE_CLIENT_ID || "967914968431-vtk6g2ll64tfk55mgib13dcbsqgkvg4c.apps.googleusercontent.com",
    "sentryDSN": "https://5acd8bec053e4c5481dc0b24551ada06@o1121914.ingest.sentry.io/6159012"
}