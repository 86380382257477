import React, {useEffect, useState, Fragment, useMemo, useCallback} from 'react';
import s from './Home.module.css';
import Card from '../../components/Card/Card'
import {Link, NavLink, useHistory} from 'react-router-dom';
import config from "../../config/config";
import {useHttp} from '../../hooks/HttpHook';
import backgroundImage from '../../assets/img/topSearchBackground2.jpg';
import OutsideClickHandler from "react-outside-click-handler";
import InputRange from "react-input-range";
import FixedMarket from "../FixedMarket/FixedMarket";
import Auction from "../Auction/Auction";
import Loading from "../../components/Loading/Loading";
import Slider from "./components/Slider";
import ErrorHandler from "../../utils/ErrorLoger";
function Home(){
    const history = useHistory();
    const [filterTypes, setFilterTypes] = useState();
    const [filterCategories, setFilterCategories] = useState();
    const [auctionsCount, setAuctionsCount] = useState();
    const [propsFilter, setPropsFilter] = useState({});
    const [priceShown, setPriceShown] = useState(false);
    const [areaShown, setAreaShown] = useState(false);
    const [filter, setFilter] = useState({
        area_min:0,
        area_max:1000,
        price_min:0,
        price_max:1000000000
    });
    const {request, loading} = useHttp();


    const typesHandler = (e) => {
        const id = e.target.getAttribute('name');
        if(+id!==filter.type_id) setFilter({...filter, type_id:+id})
        else if(filter && filter.type_id){
            let oldFilter = filter;
            delete oldFilter.type_id;
            setFilter({...oldFilter});
        }
    }

    const categoriesHandler = (e) => {
        sel();
        const id = e.target.getAttribute('name');
        if (+id !== filter.category_id) setFilter({...filter, category_id: +id})
        else if (filter && filter.category_id) {
            let oldFilter = filter;
            delete oldFilter.category_id;
            setFilter({...oldFilter});
        }
    }

    const FilterTypes = () => {
        return (
            <div className={s.filterTypes}>
                {filterTypes?filterTypes.map((el, id)=>{
                    return <p key={id} onClick={typesHandler} className={filter.type_id===id+1?s.filterTypeAcive:null} name={el.id}>{el.name}</p>
                }):null}
            </div>
        )
    }

    const FilterCategories = () => {
        return (
            <div className={s.filterCategories}>
                {filterCategories?filterCategories.map((el, id)=>{
                    return <p key={id} onClick={categoriesHandler} className={filter.category_id===id+1?s.filterTypeAcive:null} name={el.id}>{el.main_page_filter_display_name}</p>
                }):null}
            </div>
        )
    }

    const SearchFilter = useCallback(() => {
        return (
            <div className={s.filterInput}>
                <i className="fas fa-search"/>
                <input
                    onChange={(e)=>{
                        setFilter({
                            ...filter,
                            search:e.target.value
                        })}
                    }
                    placeholder="Кадастровий номер, номер аукціону"/>
            </div>
        )
    },[])

    const PriceFilter = useCallback(() => {
        return (
            <div className={s.filterBox}>
                <div onClick={(e)=>{
                    setPriceShown(!priceShown)
                }} className={s.filterBoxContent}>
                    <p>Ціна</p>
                    {priceShown?
                        <i className="fas fa-chevron-up"/>:
                        <i className="fas fa-chevron-down"/>
                    }
                </div>
                {priceShown &&
                    <Slider
                        isVisable={priceShown}
                        onChange={(price)=>{
                            console.log('change top', price)
                            setFilter({
                                ...filter,
                                price_min: price.min,
                                price_max: price.max
                            })
                        }}
                        onClose={()=>{
                            setPriceShown(false);
                        }}
                        minValue={0}
                        maxValue={1000000000}
                        defaultValue={{min:filter.price_min, max:filter.price_max}}
                    />}
            </div>
        )
    },[priceShown])

    const AreaFilter = useCallback(() => {
        return (
            <div className={s.filterBox}>
                <div onClick={(e)=>{
                    setAreaShown(!areaShown)
                }} className={s.filterBoxContent}>
                    <p>Площа</p>
                    {areaShown?
                        <i className="fas fa-chevron-up"/>:
                        <i className="fas fa-chevron-down"/>
                    }
                </div>
                {areaShown &&
                <Slider
                    isVisable={areaShown}
                    onChange={(area)=>{
                        console.log('change top', area)
                        setFilter({
                            ...filter,
                            area_min: area.min,
                            area_max: area.max
                        })
                    }}
                    onClose={()=>{
                        setAreaShown(false);
                    }}
                    minValue={0}
                    maxValue={1000}
                    defaultValue={{min:filter.area_min, max:filter.area_max}}
                />}
            </div>
        )
    },[areaShown])

    useEffect(async ()=>{
        try{
            const types = await request(config.baseUrl + `/api/v1/land-market/lands/types/`,'GET');
            const categories = await request(config.baseUrl + `/api/v1/land-market/lands/categories/`,'GET');

            if(types) setFilterTypes(types);
            if(categories) setFilterCategories(categories);
        }catch(e){
            ErrorHandler(e)
        }
    },[])

    useEffect(async ()=>{
        const params = new URLSearchParams(propsFilter);
        if(params) {
            const auctions = await request(config.baseUrl + `/api/v1/auctions/count/?${params}`);
            if(auctions) setAuctionsCount(auctions.count);
        }
    },[propsFilter])

    return(
        <Fragment>
            {loading && <Loading/>}
            <div className={s.Auction}>
                <div className={s.topSearchBar}>

                    <img className={s.backgroundImage} src={backgroundImage}/>
                    <div className={s.topFiltersBoxBackground}/>
                    <div className={s.topFiltersBox}>
                        <FilterTypes/>
                        <FilterCategories/>
                        <div className={s.filterInputs}>
                            <SearchFilter/>
                            <PriceFilter/>
                            <AreaFilter/>
                            <button
                                onClick={()=>{setPropsFilter({...filter})}}
                                className={s.filterSearchButton}
                            >Знайти</button>
                        </div>
                        <Link to="/map" className={s.mapFilter}><i className="far fa-map"/><p>Знайти на карті</p></Link>
                    </div>
                </div>
                <div className={s.topBar}>
                    <ul className={s.list}>
                        <li>
                            <NavLink activeClassName={s.active} exact to="/auction/">
                                <div className={s.listItem}>
                                    <h3>Аукціони</h3>
                                    <div className={s.count}>
                                        <p>{auctionsCount}</p>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName={s.active} to="/saved/">
                                <div className={s.listItem}>
                                    <h3>Збережені</h3>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                    </div>
                </div>
            <Auction filter={JSON.stringify(propsFilter)}/>
        </Fragment>
    )
}

export default Home;