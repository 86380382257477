import React, {Fragment, useEffect, useState} from "react";
import s from "./EditForm.module.css";
import {Formik, Field, Form, useFormik} from "formik";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import FileSign from "../../components/FileSign/FileSign";
import InputMask from "react-input-mask";
import FileSignMultiple from "../../components/FileSignMultiple/FileSignMultiple";
import {useHttp} from "../../hooks/HttpHook";
import config from "../../config/config";
import {useParams} from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import DatePicker from "react-datepicker";
import ErrorHandler from "../../utils/ErrorLoger";
function EditForm() {
    const [specialUsePreference, setSpecialUsePreference] = useState(null);
    const [renterPreference, setRenterPreference] = useState(null);
    const [preference, setPreference] = useState(null);


    const [data, setData] = useState({});
    const id = useParams().params;
    const {request,requestErrors} = useHttp();
    function formChangeHandler(e, state, setState) {
        setState({...state, [e.target.name]: e.target.value});
    }

    useEffect(async ()=>{
        console.log(id)
        let data;
        try{
            data = await request(config.baseUrl + `/api/v1/auctions/${id}/user-entrants/form/`);
            if(data && data.legal_form) {

                setData(data);
                if(data.preferences) data.preferences.map(el=>{
                    console.log(el);
                    if(el.type === 'SPECIAL_USE_PREFERENCE') setSpecialUsePreference({
                        ...el,
                        document_info: el.document,
                        document: el.document.id
                    })

                    if(el.type === 'RENTER_PREFERENCE') setRenterPreference({
                        ...el,
                        document_info: el.document,
                        document: el.document.id
                    })
                })
            }

        }catch (e) {
            ErrorHandler(e)
        }
    },[])


    const IndividualForm = (componentProps) => {
        const {request,requestErrors} = useHttp();
        return(
            <Formik
                initialValues={{
                    ...data,
                    identity_document_info: data.identity_document,
                    identity_document: data.identity_document.id,
                    tax_number_document_info: data.tax_number_document,
                    tax_number_document: data.tax_number_document.id
                }}
                onSubmit={async (values) => {

                        let data;
                        try{
                            //data = await request(config.baseUrl + `/api/v1/auctions/${id}/participate/validation/`,'POST',{...values, preferences:[specialUsePreference,renterPreference]});
                            const pref = [];
                            if(renterPreference) pref.push(renterPreference);
                            if(specialUsePreference) pref.push(specialUsePreference);
                            data = await request(config.baseUrl + `/api/v1/auctions/${id}/user-entrants/edit/`,'PATCH',values);

                        }catch (e) {
                            ErrorHandler(e)
                        }
                }}
            >
                {props => (
                    <Form className={s.form}>
                        {/*<Field name="test"/>*/}
                        {/*<p onClick={()=>{props.setFieldValue("test2",[2],false)}}>test</p>*/}
                        {/*<div className={s.inputItem}>*/}
                        {/*    <label>Прізвище*</label>*/}
                        {/*    <Field*/}
                        {/*        name="last_name"*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={s.inputItem}>
                            <label onClick={()=>{console.log(props.values)}}>Ім'я*</label>
                            <Field
                                name="first_name"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>По батькові*</label>
                            <Field
                                name="second_name"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Громадянство України*</label>
                            <CustomSelect
                                defaultValueId={props.values.is_citizen}
                                onSelect={(values, id) => {
                                    props.setFieldValue("is_citizen",id,false);
                                }}
                                data={[
                                    { name: "Так", id: true },
                                    { name: "Ні", id: false },
                                ]}
                            />
                        </div>
                        <div className={s.rnokpp}>
                            <div className={s.inputItem}>
                                <label>РНОКПП</label>
                                <Field
                                    name="tax_number"
                                />
                            </div>
                            <div className={s.checkboxItem}>
                                <input
                                    onChange={(e) => {
                                        if(e.target.checked) props.setFieldValue("has_tax_number",false,false)
                                        else props.setFieldValue("has_tax_number",true,false)
                                    }}
                                    name="has_tax_number"
                                    type="checkbox"
                                />
                                <p>
                                    Я відмовився від РНОКПП через свої релігійні переконання та маю
                                    про це відмітку в паспорті
                                </p>
                            </div>
                        </div>
                        <FileSign
                            defaultDocument={props.values.tax_number_document_info}
                            documentType="TAX_NUMBER"
                            onSelect={(id) => {
                                props.setFieldValue("tax_number_document",id,false)
                            }}
                            className={s.fileSign}
                            description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
                            title="Завантажити РНОКПП"
                        />
                        <div className={s.inputItem}>
                            <label>Оберіть країну*</label>
                            <CustomSelect
                                customId="code"
                                defaultValueId={props.values.country}
                                onSelect={(value,id) => {
                                    props.setFieldValue("country",id,false);
                                }}
                                url="/api/v1/location/countries/"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Поштовий індекс*</label>
                            <Field
                                name="postal_code"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Оберіть регіон*</label>
                            <CustomSelect
                                defaultValueId={props.values.region}
                                onSelect={(value,id) => {
                                    props.setFieldValue("region",id,false);
                                }}
                                url="/api/v1/location/regions/"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Оберіть населений пункт*</label>
                            <CustomSelect
                                defaultValueId={props.values.settlement}
                                onSelect={(value,id) => {
                                    props.setFieldValue("settlement",id,false);
                                }}
                                url="/api/v1/location/settlements/"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Вулиця*</label>
                            <Field
                                name="street"
                            />
                        </div>
                        <div className={s.itemRow}>
                            <div className={s.inputItem}>
                                <label>Номер будинку*</label>
                                <Field
                                    name="building_number"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Номер корпусу</label>
                                <Field
                                    name="building_block_number"
                                />
                            </div>
                        </div>
                        <div className={s.inputItem}>
                            <label>Номер квартири/офісу*</label>
                            <Field
                                name="apartment_or_office_number"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Документ що посвідчує особу*</label>
                            <CustomSelect
                                defaultValueId={props.values.identity_document_type}
                                onSelect={(value,id) => {
                                    props.setFieldValue("identity_document_type",id,false);
                                }}
                                data={[
                                    { name: "паспорт громадянина України (ГУ)", id: "PASSPORT" },
                                    { name: "паспорт ГУ у формі картки", id: "PASSPORT_AS_CARD" },
                                    {
                                        name: "посвідка на тимчасове проживання",
                                        id: "TEMPORARY_RESIDENCE_PERMIT",
                                    },
                                    {
                                        name: "посвідка на постійне проживання",
                                        id: "PERMANENT_RESIDENCE_PERMIT",
                                    },
                                    { name: "інший документ", id: "ANOTHER_DOCUMENT" },
                                ]}
                            />
                        </div>
                        <div className={s.itemRow}>
                            <div className={s.inputItem}>
                                <label>Серія</label>
                                <Field
                                    name="identity_document_serial_number"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Номер</label>
                                <Field
                                    name="identity_document_number"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Дата видачі</label>
                                <Field
                                    placeholder="2020-02-08"
                                    name="identity_document_issue_date"
                                />
                            </div>
                        </div>
                        <FileSign
                            defaultDocument={props.values.identity_document_info}
                            documentType="IDENTITY_DOCUMENT"
                            onSelect={(id) => {
                                props.setFieldValue("identity_document",id,false);
                            }}
                            className={s.fileSign}
                            description="Документ, що посвідчує особу. Максимальний розмір файлу 50 Мб"
                            title="Завантажити документ"
                        />
                        <div className={s.line + " " + s.gap} />
                        <div className={s.inputItem}>
                            <label>Email*</label>
                            <Field
                                name="email"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Номер телефону*</label>
                            <InputMask
                                onChange={(e)=>{props.setFieldValue("phone_number",'+' + e.target.value.replace(/[^0-9]/g,''),false)}}
                                mask="+38 (099) 999-99-99"
                                name="phone_number"
                                value={props.values.phone_number}
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Розмір закритої цінової пропозиції*</label>
                            <Field
                                name="initial_bid_amount"
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Номер банківського рахунку - IBAN*</label>
                            {/*<input onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} name="refund_iban"/>*/}
                            <InputMask
                                onChange={(e) => {
                                    props.setFieldValue("refund_iban",e.target.value,false);
                                }}
                                mask="UA999999999999999999999999999"
                                name="refund_iban"
                                value={props.values.refund_iban}
                            />
                        </div>
                        <div className={s.inputItem}>
                            <label>Суб'єкт переважного права*</label>
                            <CustomSelect
                                defaultValueId={props.values.has_preference.toString()}
                                onSelect={(value, id) => {
                                    props.setFieldValue("has_preference",id,false);
                                    if(id=="false") props.setFieldValue("preferences",[],false)
                                }}
                                data={[
                                    { name: "Так", id: "true" },
                                    { name: "Ні", id: "false" },
                                ]}
                            />
                        </div>
                        {console.log(preference)}
                        {props.values && props.values.has_preference == "true" ? (
                            <>
                                <div className={s.checkboxRow}>
                                    <div className={s.checkboxItem}>
                                        <input
                                            name="preference_type"
                                            onChange={(e) => {
                                                if(e.target.checked){
                                                    props.setFieldValue("preferences",[{
                                                        type: "SPECIAL_USE_PREFERENCE",
                                                        document_number: "",
                                                        document_date: "",
                                                        document_expire_at: "",
                                                        document: null,
                                                    }],false)
                                                }
                                            }}
                                            type="radio"
                                        />
                                        <p>
                                            Спецдозвіл на видобування корисних копалин
                                            <br /> загальнодержавного значення (1 черга)
                                        </p>
                                    </div>
                                    <div className={s.checkboxItem}>
                                        <input
                                            name="preference_type"
                                            onChange={(e) => {
                                                if(e.target.checked){
                                                    props.setFieldValue("preferences",[{
                                                        type: "RENTER_PREFERENCE",
                                                        document_number: "",
                                                        document_date: "",
                                                        document_expire_at: "",
                                                        document: null,
                                                    }],false)
                                                }
                                            }}
                                            type="radio"
                                        />
                                        <p>Договір оренди земельної ділянки (2 черга)</p>
                                    </div>
                                </div>
                                {props.values.preferences[0] ? (
                                    <>
                                        {props.values.preferences[0].type==="SPECIAL_USE_PREFERENCE"?
                                            <>
                                            <h4 style={{ marginTop: "20px" }}>
                                                Спецдозвіл на видобування корисних копалин
                                                загальнодержавного значення (1 черга)
                                            </h4>
                                                <div className={s.inputItem}>
                                                    <label onClick={()=>console.log(props.values)}>Номер документа*</label>
                                                    <input
                                                        defaultValue={props.values.preferences[0].document_number}
                                                        onChange={(e) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document_number:e.target.value}])
                                                        }}
                                                        name="document_number"
                                                    />
                                                </div>
                                                <div className={s.inputItem}>
                                                    <label>Дата документа*</label>
                                                    <input
                                                        defaultValue={props.values.preferences[0].document_date}
                                                        onChange={(e) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document_date:e.target.value}])

                                                        }}
                                                        name="document_date"
                                                    />
                                                </div>
                                                <div className={s.inputItem}>
                                                    <label>Термін дії до*</label>
                                                    <input
                                                        defaultValue={props.values.preferences[0].document_expire_at}
                                                        onChange={(e) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document_expire_at:e.target.value}])
                                                        }}
                                                        name="document_expire_at"
                                                    />
                                                </div>

                                                <div className={s.itemRow + " " + s.fileUploadRow}>
                                                    <FileSign
                                                        defaultDocument={props.values.preferences[0].document}
                                                        documentType="PREFERENCE_DOCUMENT"
                                                        onSelect={(id) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document:id}])
                                                        }}
                                                        className={s.fileSign}
                                                        description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                                        title="Завантажити документ"
                                                    />
                                                </div>
                                                <FileSignMultiple
                                                    documentType="ADDITIONAL_DOCUMENT"
                                                    onSelect={(id) => {
                                                        props.setFieldValue("preferences",[{...props.values.preferences[0],additional_documents:id}])
                                                    }}
                                                    className={s.fileSign}
                                                    description="Додаткові документи (за необхідності). Максимальний розмір файлу 50 Мб "
                                                    title="Завантажити документ"
                                                />
                                                </>
                                                :
                                            <>
                                            <h4 style={{ marginTop: "20px" }}>
                                                Договір оренди земельної ділянки (2 черга)
                                            </h4>
                                                <div className={s.inputItem}>
                                                    <label onClick={()=>console.log(props.values)}>Номер документа*</label>
                                                    <input
                                                        defaultValue={props.values.preferences[0].document_number}
                                                        onChange={(e) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document_number:e.target.value}])
                                                        }}
                                                        name="document_number"
                                                    />
                                                </div>
                                                <div className={s.inputItem}>
                                                    <label>Дата документа*</label>
                                                    <input
                                                        defaultValue={props.values.preferences[0].document_date}
                                                        onChange={(e) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document_date:e.target.value}])

                                                        }}
                                                        name="document_date"
                                                    />
                                                </div>
                                                <div className={s.inputItem}>
                                                    <label>Термін дії до*</label>
                                                    <input
                                                        defaultValue={props.values.preferences[0].document_expire_at}
                                                        onChange={(e) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document_expire_at:e.target.value}])
                                                        }}
                                                        name="document_expire_at"
                                                    />
                                                </div>

                                                <div className={s.itemRow + " " + s.fileUploadRow}>
                                                    <FileSign
                                                        defaultDocument={props.values.preferences[0].document}
                                                        documentType="PREFERENCE_DOCUMENT"
                                                        onSelect={(id) => {
                                                            props.setFieldValue("preferences",[{...props.values.preferences[0],document:id}])
                                                        }}
                                                        className={s.fileSign}
                                                        description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                                        title="Завантажити документ"
                                                    />
                                                </div>
                                                <FileSignMultiple
                                                    documentType="ADDITIONAL_DOCUMENT"
                                                    onSelect={(id) => {
                                                        props.setFieldValue("preferences",[{...props.values.preferences[0],additional_documents:id}])
                                                    }}
                                                    className={s.fileSign}
                                                    description="Додаткові документи (за необхідності). Максимальний розмір файлу 50 Мб "
                                                    title="Завантажити документ"
                                                />
                                                </>
                                        }
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <div className={s.line + " " + s.gap} />
                        )}





                        {/*<label htmlFor="firstName">First Name</label>*/}
                        {/*<Field id="firstName" name="firstName" placeholder="Jane" />*/}

                        {/*<label htmlFor="lastName">Last Name</label>*/}
                        {/*<Field id="lastName" name="lastName" placeholder="Doe" />*/}

                        {/*<label htmlFor="email">Email</label>*/}
                        {/*<Field*/}
                        {/*    id="email"*/}
                        {/*    name="email"*/}
                        {/*    placeholder="jane@acme.com"*/}
                        {/*    type="email"*/}
                        {/*/>*/}
                        {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                        <button type="submit">Submit</button>
                    </Form>
                )}
            </Formik>
        )
    }

    const LegalForm = () => {
        const {request,requestErrors} = useHttp();
            return(
                <Formik
                    initialValues={{
                        ...data,
                        additional_documents_info: data.additional_documents,
                        additional_documents: data.additional_documents.map(el=>el.id),
                        legal_entity_chief_identity_document_info: data.legal_entity_chief_identity_document,
                        legal_entity_chief_identity_document: data.legal_entity_chief_identity_document.id,
                        legal_entity_chief_tax_number_document_info: data.legal_entity_chief_tax_number_document,
                        legal_entity_chief_tax_number_document: data.legal_entity_chief_tax_number_document.id,
                        legal_entity_documents_info: data.legal_entity_documents,
                        legal_entity_documents: data.legal_entity_documents.map(el=>el.id),
                    }}
                    onSubmit={async (values) => {
                        let data;
                        try{
                            //data = await request(config.baseUrl + `/api/v1/auctions/${id}/participate/validation/`,'POST',{...values, preferences:[specialUsePreference,renterPreference]});

                            const pref = [];
                            if(renterPreference) pref.push(renterPreference);
                            if(specialUsePreference) pref.push(specialUsePreference);
                            data = await request(config.baseUrl + `/api/v1/auctions/${id}/user-entrants/edit/`,'PATCH',{...values, preferences:pref});
                        }catch (e) {
                            ErrorHandler(e)
                        }
                    }}
                >
                    {props => (
                        <Form className={s.form}>
                            <div className={s.inputItem}>
                                <label onClick={()=>{console.log(props.values)}}>Найменування юридичної особи*</label>
                                <Field name="legal_entity_name"/>
                            </div>
                            <div className={s.inputItem}>
                                <label>Код ЄДРПОУ*</label>
                                <Field name="legal_entity_tax_number"/>
                            </div>
                            <div className={s.inputItem}>
                                <label>Оберіть країну*</label>
                                <CustomSelect
                                    customId="code"
                                    defaultValueId={props.values.country}
                                    onSelect={(value,id) => {
                                        props.setFieldValue("country",id,false);
                                    }}
                                    url="/api/v1/location/countries/"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Поштовий індекс*</label>
                                <Field
                                    name="postal_code"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Оберіть регіон*</label>
                                <CustomSelect
                                    defaultValueId={props.values.region}
                                    onSelect={(value,id) => {
                                        props.setFieldValue("region",id,false);
                                    }}
                                    url="/api/v1/location/regions/"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Оберіть населений пункт*</label>
                                <CustomSelect
                                    defaultValueId={props.values.settlement}
                                    onSelect={(value,id) => {
                                        props.setFieldValue("settlement",id,false);
                                    }}
                                    url="/api/v1/location/settlements/"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Вулиця*</label>
                                <Field
                                    name="street"
                                />
                            </div>
                            <div className={s.itemRow}>
                                <div className={s.inputItem}>
                                    <label>Номер будинку*</label>
                                    <Field
                                        name="building_number"
                                    />
                                </div>
                                <div className={s.inputItem}>
                                    <label>Номер корпусу</label>
                                    <Field
                                        name="building_block_number"
                                    />
                                </div>
                            </div>
                            <div className={s.inputItem}>
                                <label>Номер квартири/офісу*</label>
                                <Field
                                    name="apartment_or_office_number"
                                />
                            </div>
                            <div style={{padding:'0px', marginTop:'50px'}} className={s.checkboxItem}>
                                <input checked={!!props.values.legal_entity_address_and_postal_address_is_different} name="personal_data_process_consent" onChange={(e)=>{
                                    if(e.target.checked){
                                        props.setFieldValue("legal_entity_address_and_postal_address_is_different",true,false);
                                    }else{
                                        props.setFieldValue("legal_entity_address_and_postal_address_is_different",false,false);
                                    }
                                }} type='checkbox'/>
                                <p>Юридична адреса та адреса для листування юридичної особи відрізняються</p>
                            </div>
                            {props.values.legal_entity_address_and_postal_address_is_different?
                                <>
                                    <div className={s.inputItem}>
                                        <label>Оберіть країну*</label>
                                        <CustomSelect
                                            customId="code"
                                            defaultValueId={props.values.postal_address_country}
                                            onSelect={(value,id) => {
                                                props.setFieldValue("postal_address_country",id,false);
                                            }}
                                            url="/api/v1/location/countries/"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Поштовий індекс*</label>
                                        <Field
                                            name="postal_address_postal_code"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Оберіть регіон*</label>
                                        <CustomSelect
                                            defaultValueId={props.values.postal_address_region}
                                            onSelect={(value,id) => {
                                                props.setFieldValue("postal_address_region",id,false);
                                            }}
                                            url="/api/v1/location/regions/"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Оберіть населений пункт*</label>
                                        <CustomSelect
                                            defaultValueId={props.values.postal_address_settlement}
                                            onSelect={(value,id) => {
                                                props.setFieldValue("postal_address_settlement",id,false);
                                            }}
                                            url="/api/v1/location/settlements/"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Вулиця*</label>
                                        <Field
                                            name="postal_address_street"
                                        />
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Номер будинку*</label>
                                            <Field
                                                name="postal_address_building_number"
                                            />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Номер корпусу</label>
                                            <Field
                                                name="postal_address_building_block_number"
                                            />
                                        </div>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Номер квартири/офісу*</label>
                                        <Field
                                            name="postal_address_apartment_or_office_number"
                                        />
                                    </div>
                                </>
                                :null}
                                <div className={s.gap}></div>
                            <h4 className={s.subHeader}>Кінцевий бенефіціарний власник:</h4>
                            <div className={s.inputItem}>
                                <label>Бенефіціарний власник</label>
                                <Field name="beneficial_owner" placeholder="Прізвище Ім'я По-батькові"/>
                            </div>
                            <div className={s.inputItem}>
                                <label>Причини відсутності кінцевого бенефіціарного власника:</label>
                                <Field name="beneficial_owner_absence_reason"/>
                            </div>
                            <div className={s.gap}></div>
                            <h4 className={s.subHeader}>Керівник юридичної особи:</h4>
                            <div className={s.inputItem}>
                                <label>Ім'я керівника*</label>
                                <Field name="legal_entity_chief_first_name"/>
                            </div>
                            <div className={s.inputItem}>
                                <label>Прізвище керівника*</label>
                                <Field name="legal_entity_chief_last_name"/>
                            </div>
                            <div className={s.inputItem}>
                                <label>По батькові керівника*</label>
                                <Field name="legal_entity_chief_second_name"/>
                            </div>
                            <div className={s.inputItem}>
                                <label>Назва документу, на підставі якого діє керівник*</label>
                                <Field name="legal_entity_chief_document_name"/>
                            </div>
                            <div className={s.itemRow}>
                                <div className={s.inputItem}>
                                    <label>Дата документа</label>
                                    <Field placeholder="2020-05-06" name="legal_entity_chief_document_date"/>
                                </div>
                                <div className={s.inputItem}>
                                    <label>Номер документа</label>
                                    <Field name="legal_entity_chief_document_number"/>
                                </div>
                            </div>
                            <FileSignMultiple
                                defaultValues={props.values.legal_entity_documents_info}
                                documentType='LEGAL_ENTITY_DOCUMENT'
                                onSelect={(id)=>{props.setFieldValue("legal_entity_documents",id,false)}}
                                className={s.fileSign}
                                description="Завантажити документи юридичної особи. Максимальний розмір файлу 50 Мб"
                                title="Завантажити документ ЮО"
                            />
                            <div className={s.rnokpp}>
                                <div className={s.inputItem}>
                                    <label>РНОКПП керівника*</label>
                                    <Field name="legal_entity_chief_tax_number"/>
                                </div>
                                <div className={s.checkboxItem}>
                                    <input checked={!props.values.legal_entity_chief_has_tax_number} name="legal_entity_chief_has_tax_number" onChange={(e)=>{
                                        e.target.checked?
                                            props.setFieldValue("legal_entity_chief_has_tax_number",true,false):
                                            props.setFieldValue("legal_entity_chief_has_tax_number",false,false)
                                    }} type='checkbox'/>
                                    <p>Керівник відмовився від РНОКПП через свої релігійні переконання та має про це відмітку в паспорті</p>
                                </div>
                            </div>
                            <FileSign
                                defaultDocument={props.values.legal_entity_chief_tax_number_document_info}
                                documentType="LEGAL_ENTITY_CHIEF_TAX_NUMBER"
                                onSelect={(id) => {
                                    props.setFieldValue("legal_entity_chief_tax_number_document",id,false);
                                }}
                                className={s.fileSign}
                                description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
                                title="Завантажити РНОКПП"
                            />

                            <div className={s.gap}/>
                            <div className={s.inputItem}>
                                <label>Документ що посвідчує особу*</label>
                                <CustomSelect
                                    defaultValueId={props.values.legal_entity_chief_identity_document_type}
                                    onSelect={(value,id) => {
                                        props.setFieldValue("legal_entity_chief_identity_document_type",id,false);
                                    }}
                                    data={[
                                        {name:'паспорт громадянина України (ГУ)', id:'PASSPORT'},
                                        {name:'паспорт ГУ у формі картки', id:'PASSPORT_AS_CARD'},
                                    ]}
                                />

                            </div>
                            <div className={s.itemRow}>
                                <div className={s.inputItem}>
                                    <label>Серія</label>
                                    <Field name="legal_entity_chief_identity_document_serial_number"/>
                                </div>
                                <div className={s.inputItem}>
                                    <label>Номер</label>
                                    <Field name="legal_entity_chief_identity_document_number"/>
                                </div>
                                <div className={s.inputItem}>
                                    <label>Дата видачі</label>
                                    <Field name="legal_entity_chief_identity_document_issue_date"/>
                                </div>
                            </div>
                            <FileSign
                                defaultDocument={props.values.legal_entity_chief_identity_document_info}
                                documentType="LEGAL_ENTITY_CHIEF_IDENTITY_DOCUMENT"
                                onSelect={(id) => {
                                    props.setFieldValue("legal_entity_chief_identity_document",id,false);
                                }}
                                className={s.fileSign}
                                description="Документ, що посвідчує особу. Максимальний розмір файлу 50 Мб"
                                title="Завантажити документ"
                            />
                            <div className={s.inputItem}>
                                <label>Особа, уповноважена діяти від імені юридичної особи*</label>
                                <CustomSelect
                                    onSelect={(value,id)=>{
                                    props.setFieldValue("legal_entity_authorized_person_type",id,false);
                                }}
                                    defaultValueId={props.values.legal_entity_authorized_person_type}
                                    data={[{name:'Представник', id:'REPRESENTATIVE'},{name:'Керівник', id:'CHIEF'}]}/>
                            </div>

                            {props.values && props.values.legal_entity_authorized_person_type=== 'REPRESENTATIVE'?
                                <>
                                    <div className={s.inputItem}>
                                        <label>Прізвище*</label>
                                        <Field name="legal_entity_authorized_person_last_name"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Ім'я*</label>
                                        <Field name="legal_entity_authorized_person_first_name"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>По батькові*</label>
                                        <Field name="legal_entity_authorized_person_second_name"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Назва документа що підтверджує повноваження*</label>
                                        <Field name="legal_entity_authorized_person_authorization_document_name"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>№*</label>
                                        <Field name="legal_entity_authorized_person_authorization_document_number"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Дата видачі*</label>
                                        <Field name="legal_entity_authorized_person_authorization_document_issue_date"/>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Ким видано*</label>
                                        <Field name="legal_entity_authorized_person_authorization_document_issuer"/>
                                    </div>

                                    <FileSign
                                        defaultDocument={props.values.legal_entity_authorized_person_authorization_document_info}
                                        documentType="LEGAL_ENTITY_AUTHORIZED_PERSON_AUTHORIZATION_DOCUMENT"
                                        onSelect={(id) => {
                                            props.setFieldValue("legal_entity_authorized_person_authorization_document",id,false);
                                        }}
                                        className={s.fileSign}
                                        description="Завантажити документи юридичної особи. Максимальний розмір файлу 50 Мб"
                                        title="Завантажити документ"
                                    />
                                    <div className={s.line + ' ' +s.gap}/>
                                    <div className={s.rnokpp}>
                                        <div className={s.inputItem}>
                                            <label>РНОКПП*</label>
                                            <Field name="legal_entity_authorized_person_tax_number"/>
                                        </div>
                                        <div className={s.checkboxItem}>
                                            <input checked={props.values.legal_entity_authorized_person_has_tax_number} name="legal_entity_authorized_person_has_tax_number" onChange={(e)=>{
                                                e.target.checked?
                                                    props.setFieldValue("legal_entity_authorized_person_has_tax_number",true,false):
                                                    props.setFieldValue("legal_entity_authorized_person_has_tax_number",false,false)
                                            }} type='checkbox'/>
                                            <p>Я відмовився від РНОКПП через свої релігійні переконання та маю про це відмітку в паспорті</p>
                                        </div>
                                    </div>
                                    <FileSign
                                        defaultDocument={props.values.legal_entity_authorized_person_tax_number_document_info}
                                        documentType="LEGAL_ENTITY_AUTHORIZED_PERSON_TAX_NUMBER"
                                        onSelect={(id) => {
                                            props.setFieldValue("legal_entity_authorized_person_tax_number_document",id,false);
                                        }}
                                        className={s.fileSign}
                                        description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
                                        title="Завантажити РНОКПП"
                                    />
                                    <div className={s.gap}/>
                                    <div className={s.inputItem}>
                                        <label>Документ що посвідчує особу*</label>
                                        <CustomSelect
                                            onSelect={(value,id)=>{
                                                props.setFieldValue("legal_entity_authorized_person_identity_document_type",id,false);
                                            }}
                                            defaultValueId={props.values.legal_entity_authorized_person_identity_document_type}
                                            data={[
                                                {name:'паспорт громадянина України (ГУ)', id:'PASSPORT'},
                                                {name:'паспорт ГУ у формі картки', id:'PASSPORT_AS_CARD'},
                                            ]}/>
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Серія</label>
                                            <Field name="legal_entity_authorized_person_identity_document_serial_number"/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Номер</label>
                                            <Field name="legal_entity_authorized_person_identity_document_number"/>
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Дата видачі</label>
                                            <Field name="legal_entity_authorized_person_identity_document_issue_date"/>
                                        </div>
                                    </div>
                                    <FileSign
                                        defaultDocument={props.values.legal_entity_authorized_person_identity_document_info}
                                        documentType="LEGAL_ENTITY_AUTHORIZED_PERSON_IDENTITY_DOCUMENT"
                                        onSelect={(id) => {
                                            props.setFieldValue("legal_entity_authorized_person_identity_document",id,false);
                                        }}
                                        className={s.fileSign}
                                        description="Документ, що посвідчує особу. Максимальний розмір файлу 50 Мб"
                                        title="Завантажити документ"
                                    />
                                    <div className={s.line + ' ' +s.gap}/>
                                    <div className={s.inputItem}>
                                        <label>Оберіть країну*</label>
                                        <CustomSelect
                                            customId="code"
                                            defaultValueId={props.values.legal_entity_authorized_person_country}
                                            onSelect={(value,id) => {
                                                props.setFieldValue("legal_entity_authorized_person_country",id,false);
                                            }}
                                            url="/api/v1/location/countries/"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Поштовий індекс*</label>
                                        <Field
                                            name="legal_entity_authorized_person_postal_code"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Оберіть регіон*</label>
                                        <CustomSelect
                                            defaultValueId={props.values.legal_entity_authorized_person_region}
                                            onSelect={(value,id) => {
                                                props.setFieldValue("legal_entity_authorized_person_region",id,false);
                                            }}
                                            url="/api/v1/location/regions/"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Оберіть населений пункт*</label>
                                        <CustomSelect
                                            defaultValueId={props.values.legal_entity_authorized_person_settlement}
                                            onSelect={(value,id) => {
                                                props.setFieldValue("legal_entity_authorized_person_settlement",id,false);
                                            }}
                                            url="/api/v1/location/settlements/"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Вулиця*</label>
                                        <Field
                                            name="legal_entity_authorized_person_street"
                                        />
                                    </div>
                                    <div className={s.itemRow}>
                                        <div className={s.inputItem}>
                                            <label>Номер будинку*</label>
                                            <Field
                                                name="legal_entity_authorized_person_building_number"
                                            />
                                        </div>
                                        <div className={s.inputItem}>
                                            <label>Номер корпусу</label>
                                            <Field
                                                name="legal_entity_authorized_person_building_block_number"
                                            />
                                        </div>
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Номер квартири/офісу*</label>
                                        <Field
                                            name="legal_entity_authorized_person_apartment_or_office_number"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Email*</label>
                                        <Field
                                            name="legal_entity_authorized_person_email"
                                        />
                                    </div>
                                    <div className={s.inputItem}>
                                        <label>Номер телефону*</label>
                                        <InputMask
                                            onChange={(e)=>{props.setFieldValue("phone_number",'+' + e.target.value.replace(/[^0-9]/g,''),false)}}
                                            mask="+38 (099) 999-99-99"
                                            name="phone_number"
                                            value={props.values.phone_number}
                                        />
                                    </div>
                                </>:null}
                            <div className={s.inputItem}>
                                <label>Розмір закритої цінової пропозиції*</label>
                                <Field
                                    name="initial_bid_amount"
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Номер банківського рахунку - IBAN*</label>
                                {/*<input onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} name="refund_iban"/>*/}
                                <InputMask
                                    onChange={(e) => {
                                        props.setFieldValue("refund_iban",e.target.value,false);
                                    }}
                                    mask="UA999999999999999999999999999"
                                    name="refund_iban"
                                    value={props.values.refund_iban}
                                />
                            </div>
                            <div className={s.inputItem}>
                                <label>Суб'єкт переважного права*</label>
                                <CustomSelect
                                    defaultValueId={props.values.has_preference.toString()}
                                    onSelect={(value, id) => {
                                        props.setFieldValue("has_preference",id,false);
                                    }}
                                    data={[
                                        { name: "Так", id: "true" },
                                        { name: "Ні", id: "false" },
                                    ]}
                                />
                            </div>
                            {props.values && props.values.has_preference == "true" ? (
                                <>
                                    <div className={s.checkboxRow}>
                                        <div className={s.checkboxItem}>
                                            <input
                                                name="preference_type"
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setSpecialUsePreference({
                                                            type: "SPECIAL_USE_PREFERENCE",
                                                            document_number: "",
                                                            document_date: "",
                                                            document_expire_at: "",
                                                            document: null,
                                                        })
                                                        : setSpecialUsePreference(null);
                                                }}
                                                type="checkbox"
                                            />
                                            <p>
                                                Спецдозвіл на видобування корисних копалин
                                                <br /> загальнодержавного значення (1 черга)
                                            </p>
                                        </div>
                                        <div className={s.checkboxItem}>
                                            <input
                                                name="preference_type"
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setRenterPreference({
                                                            type: "RENTER_PREFERENCE",
                                                            document_number: "",
                                                            document_date: "",
                                                            document_expire_at: "",
                                                            document: null,
                                                        })
                                                        : setRenterPreference(null);
                                                }}
                                                type="checkbox"
                                            />
                                            <p>Договір оренди земельної ділянки (2 черга)</p>
                                        </div>
                                    </div>
                                    {specialUsePreference ? (
                                        <>
                                            <h4 style={{ marginTop: "20px" }}>
                                                Спецдозвіл на видобування корисних копалин
                                                загальнодержавного значення (1 черга)
                                            </h4>
                                            <div className={s.inputItem}>
                                                <label>Номер документа*</label>
                                                <input
                                                    onChange={(e) => {
                                                        formChangeHandler(
                                                            e,
                                                            specialUsePreference,
                                                            setSpecialUsePreference
                                                        );
                                                    }}
                                                    name="document_number"
                                                />
                                            </div>
                                            <div className={s.inputItem}>
                                                <label>Дата документа*</label>
                                                <input
                                                    onChange={(e) => {
                                                        formChangeHandler(
                                                            e,
                                                            specialUsePreference,
                                                            setSpecialUsePreference
                                                        );
                                                    }}
                                                    name="document_date"
                                                />
                                            </div>
                                            <div className={s.inputItem}>
                                                <label>Термін дії до*</label>
                                                {console.log(specialUsePreference)}
                                                <input
                                                    value={specialUsePreference.document_expire_at}
                                                    onChange={(e) => {
                                                        formChangeHandler(
                                                            e,
                                                            specialUsePreference,
                                                            setSpecialUsePreference
                                                        );
                                                    }}
                                                    name="document_expire_at"
                                                />
                                            </div>

                                            <div className={s.itemRow + " " + s.fileUploadRow}>
                                                <FileSign
                                                    documentType="PREFERENCE_DOCUMENT"
                                                    onSelect={(id) => {
                                                        setSpecialUsePreference({
                                                            ...specialUsePreference,
                                                            document: id,
                                                        });
                                                    }}
                                                    className={s.fileSign}
                                                    description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                                    title="Завантажити документ"
                                                />
                                            </div>
                                        </>
                                    ) : null}

                                    {renterPreference ? (
                                        <>
                                            <h4 style={{ marginTop: "20px" }}>
                                                Договір оренди земельної ділянки (2 черга)
                                            </h4>
                                            <div className={s.inputItem}>
                                                <label>Номер документа*</label>
                                                <input
                                                    onChange={(e) => {
                                                        formChangeHandler(
                                                            e,
                                                            renterPreference,
                                                            setRenterPreference
                                                        );
                                                    }}
                                                    name="document_number"
                                                />
                                            </div>
                                            <div className={s.inputItem}>
                                                <label>Дата документа*</label>
                                                <input
                                                    onChange={(e) => {
                                                        formChangeHandler(
                                                            e,
                                                            renterPreference,
                                                            setRenterPreference
                                                        );
                                                    }}
                                                    name="document_date"
                                                />
                                            </div>
                                            <div className={s.inputItem}>
                                                <label>Термін дії до*</label>
                                                <input
                                                    value={renterPreference.document_expire_at}
                                                    onChange={(e) => {
                                                        formChangeHandler(
                                                            e,
                                                            renterPreference,
                                                            setRenterPreference
                                                        );
                                                    }}
                                                    name="document_expire_at"
                                                />
                                            </div>

                                            <div className={s.itemRow + " " + s.fileUploadRow}>
                                                <FileSign
                                                    documentType="PREFERENCE_DOCUMENT"
                                                    onSelect={(id) => {
                                                        setRenterPreference({
                                                            ...renterPreference,
                                                            document: id,
                                                        });
                                                    }}
                                                    className={s.fileSign}
                                                    description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                                    title="Завантажити документ"
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                    {specialUsePreference || renterPreference ? (
                                        <FileSignMultiple
                                            documentType="ADDITIONAL_DOCUMENT"
                                            onSelect={(id) => {
                                                setPreferenceForm({
                                                    ...preferenceForm,
                                                    additional_documents: id,
                                                });
                                            }}
                                            className={s.fileSign}
                                            description="Додаткові документи (за необхідності). Максимальний розмір файлу 50 Мб "
                                            title="Завантажити документ"
                                        />
                                    ) : null}
                                </>
                            ) : (
                                <div className={s.line + " " + s.gap} />
                            )}




                            {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                            <button type="submit">Submit</button>
                        </Form>
                    )}
                </Formik>
            )
    }

  return (
    <div className={s.Edit}>
        <h1 onClick={()=>{console.log(specialUsePreference, renterPreference)}}>Редагувати заяву</h1>
        {data && data.legal_form?
            data.legal_form==='LEGAL_ENTITY'?
                <LegalForm initialValues={data}/>:
            <IndividualForm initialValues={data}/>

        :<Loading/>}

    </div>
  );
}

export default EditForm;
