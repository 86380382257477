import React, {useEffect, useState} from 'react';
import s from './ApplicationItem.module.css';
import {Link, useHistory} from 'react-router-dom';
import timeClock from '../../assets/img/clock.png';
import FileSign from "../FileSign/FileSign";
import completedClock from "../../assets/img/checkmark-green.png";
import PaymentSign from "../PaymentSign/PaymentSign";
import fileIcon from '../../assets/img/document-icon.png';
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import ErrorHandler from "../../utils/ErrorLoger";
function ApplicationItem({item, onClose, onUpdate}) {
    const [registrationPayment,setRegistrationPayment] = useState();
    const [guaranteePayment,setGuaranteePayment] = useState();
    const {request} = useHttp();
    const history = useHistory();
    const statusNames = {
        AWAITING_PAYMENT: 1,
        AWAITING_APPROVAL: 2,
        AWAITING_SIGNATURE: 3,
        AWAITING_WAIT_CONSENT: 4,
        AWAITING_SUBMISSION: 5,
        ACCEPTED: 6,
        CANCELED: 7,
        EDITED: 8,
        REJECTED: 9,
    }

    const signHandler = async () => {
        let data;
        try{
            data = await request(config.baseUrl + `/api/v1/auctions/${item.auction.id}/user-entrants/sign-application-file/`, 'POST');
            if(data && data.status==='OK') onUpdate(item);
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const submitHandler = async () => {
        let data;
        try{
            data = await request(config.baseUrl + `/api/v1/auctions/${item.auction.id}/user-entrants/submit/`, 'POST');
            if(data && data.status==='OK') onUpdate(item);
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const waitConsentHandler = async () => {
        let data;
        try{
            data = await request(config.baseUrl + `/api/v1/auctions/${item.auction.id}/user-entrants/grant-wait-consent/`, 'POST');
            if(data && data.status==='OK') onUpdate(item);
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const cancelHandler = async () => {
        let data;
        try{
            data = await request(config.baseUrl + `/api/v1/auctions/${item.auction.id}/user-entrants/cancel/`, 'POST');
            if(data && data.status==='OK') onUpdate(item);
        }catch (e) {
            ErrorHandler(e)
        }
    }

    const StageTitle = ({title,statusName}) => {
        let isConfirmed = false;
        if(statusNames[item.status]>statusNames[statusName]) isConfirmed = true;
        return(
            <div className={s.stageItemTitle}>
                <img src={isConfirmed?completedClock:timeClock}/>
                <h4>{title}</h4>
                <p>{isConfirmed?'Підтверджено':'Очікується'}</p>
            </div>
        )
    }

    useEffect(()=>{
        if(item.payment?.receipts) item.payment?.receipts.map((el)=>{
            if(el.type === 'REGISTRATION_FEE') setRegistrationPayment(el);
            else if(el.type === 'GUARANTEE_PLEDGE') setGuaranteePayment(el);
        })
    },[])

    return(
        <div className={s.ApplicationItem}>
            <p className={s.backButton} onClick={onClose}><i className="fas fa-long-arrow-alt-left"></i>Назад</p>
            <Link className={s.cadastNum} to={`/lot/${item.auction.id}/`}>{item.auction.cadastral_number}</Link>
            <div className={s.stageItem}>
                <div className={s.stageItemTitle}>
                    <img src={item.application_form.status ==='APPROVED'?completedClock:timeClock}/>
                    <h4 onClick={onUpdate}>Обробка даних на вступ у аукціон</h4>
                    <p>{item.application_form.status ==='APPROVED'?'Підтвержено':'Очікується'}</p>
                </div>
            </div>
            <div className={s.stageItem}>
                <StageTitle statusName="AWAITING_PAYMENT" title="Зарахування оплати реєстраційного внеску"/>
                <PaymentSign
                    file={registrationPayment?registrationPayment:null}
                    auctionId={item.auction.id}
                    isPayment={true}
                    filesToRight={true}
                    paymentType='REGISTRATION_FEE'
                    className={s.fileSign}
                    description="Завантажити документ, що підтверджує сплату реєстраційного внеску"
                    title="Завантажити документ"
                />
            </div>
            <div className={s.stageItem}>
                <StageTitle statusName="AWAITING_PAYMENT" title="Зарахування оплати гарантійного внеску"/>
                <PaymentSign
                    file={guaranteePayment?guaranteePayment:null}
                    auctionId={item.auction.id}
                    isPayment={true}
                    filesToRight={true}
                    paymentType='GUARANTEE_PLEDGE'
                    className={s.fileSign}
                    description="Завантажити документ, що підтверджує сплату гарантійного внеску"
                    title="Завантажити документ"
                />
            </div>
            <div className={s.stageItem}>
                <StageTitle statusName="AWAITING_SIGNATURE" title="Заява про участь в земельних торгах"/>
                {statusNames[item.status]>=statusNames['AWAITING_SIGNATURE']?
                    <div className={s.landSign}>
                        {item.application_file?.is_signed?
                            <button className={s.signedButton}>Підписано</button>:
                            <button onClick={signHandler}>Підписати КЕП</button>
                        }
                        {item.application_file && item.application_file.url?
                            <div className={s.landSignFile}>
                                <img src={fileIcon}/>
                                <a target="_blank" href={config.baseUrl + item.application_file.url}>{item.application_file.filename}</a>
                            </div>:null
                        }
                    </div>:null
                }


            </div>
            <div className={s.stageItem}>
                <StageTitle statusName="AWAITING_WAIT_CONSENT" title="Попередня згода на очікування"/>
                {statusNames[item.status]===statusNames['AWAITING_WAIT_CONSENT']?<>
                <p className={s.waitingText}>Погоджуюсь на очікування повернення сплаченого мною гарантійного внеску до здійснення  переможцем торгів повної оплати за придбаний лот та зміни статусу земельних торгів на аукціон, що завершився.</p>

                <div className={s.landSign}>
                    <button onClick={waitConsentHandler}>Надати згоду</button>
                    <button className={s.withdrawButton}>Відкликати згоду</button>
                </div>
                   </> :<button className={s.recallBtn}>Відкликати згоду</button>}
            </div>
            {statusNames[item.status]===statusNames['AWAITING_SUBMISSION']?
                <button onClick={submitHandler} className={s.finishBtn}>Подати заяву</button>:
                statusNames[item.status]===statusNames['ACCEPTED']?
                    <div className={s.acceptedButtons}>
                        <button onClick={()=>{history.push(`/form/edit/${item.auction.id}/`)}} className={s.editBtn}>Редагувати</button>
                        <button onClick={cancelHandler} className={s.cancelBtn}>Анулювати</button>
                    </div>:
                    statusNames[item.status]===statusNames['CANCELED']?
                        <h2 className={s.canceledText}>Заяву скасовано</h2>:null
            }

        </div>
    )
}

export default ApplicationItem;