import React, {useState, useContext, useRef} from 'react';
import PreviewItem from '../../components/PreviewItem/PreviewItem';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import s from './AddLot.module.css';
import {useHistory} from 'react-router-dom';
import {useHttp} from '../../hooks/HttpHook.js';
import config from '../../config/config.js';
import { FileDrop } from 'react-file-drop'
import { AuthContext } from '../../context/AuthContext';
import InputMask from 'react-input-mask';
import Loading from '../../components/Loading/Loading'
function AddProduct(){
    const history = useHistory();
    const {request, requestErrors} = useHttp();
    const auth = useContext(AuthContext);
    const [form, setForm] = useState({});
    const [location, setLocation] = useState({});
    const [immediateBuy, setImmediateBuy] = useState(false);
    const [auctionForm, setAuctionForm] = useState({});
    const [lotType, setLotType] = useState('fixed');
    const [auctionType, setAuctionType] = useState('PROGRESSIVE');
    const [previewImages, setPreviewImages] = useState([]);
    const [previewDocuments, setPreviewDocuments] = useState([]);
    const [documentIds, setDocumentIds] = useState([]);
    const [photoIds, setPhotoIds] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [successShown, setSuccessShown] = useState(false);

    const submitHandler = async () => {
        let data;
        if(lotType=='fixed'){
            try{
                data = await request(config.baseUrl + '/api/v1/land-market/lands/create/', 'POST', 
                {...form,
                    location:location,
                    document_ids:documentIds,
                    photo_ids:photoIds
                }
                );
            }catch(e){
                console.log(e);
            }
        }else{
            try{
                data = await request(config.baseUrl + '/api/v1/auctions/create/', 'POST', 
                {
                    ...auctionForm,
                    buy_now:immediateBuy,
                    auction_type:"PROGRESSIVE",
                    land:{...form,
                        location:location,
                        document_ids:documentIds,
                        photo_ids:photoIds},
                    }
                );
            }catch(e){
                console.log(e);
            }
             
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
         if((data && data.auction_id) || (data && data.land_id)){
                 setLoading(true);
                 setTimeout(()=>{
                    setLoading(false);
                    setSuccessShown(true);
                 },1000)
         }
    }

    const deletePhotosHandler = async (id) => {
        let oldIds = [...photoIds];
        const photoId = oldIds.splice(id, 1);
        setPhotoIds(oldIds);
        let oldPreviews = [...previewImages];
        oldPreviews.splice(id, 1);
        setPreviewImages(oldPreviews);
        try{
            await request(config.baseUrl + `/api/v1/land-market/lands/photos/${photoId}/delete/`, 'DELETE');
        }catch (e) {
            console.log(e);
        }

    }

    const deleteDocumentsHandler = async (id) => {
        let oldIds = [...documentIds];
        const documentId = oldIds.splice(id, 1);
        setDocumentIds(oldIds);

        let oldPreviews = [...previewDocuments];
        oldPreviews.splice(id, 1);
        setPreviewDocuments(oldPreviews);
        try{
            await request(config.baseUrl + `/api/v1/user/documents/${documentId}/delete/`, 'DELETE');
        }catch (e) {
            console.log(e);
        }
    }
    const formChangeHandler = (e) => {
         setForm({...form, [e.target.name]: e.target.value});
    }

    const photosHandler = async (files) => {
        const images = await fileListToBase64(files);
        let promises = [];
        let ids = [];
        let preview = [];
        images.map((image)=>{
            if(!previewImages.some((el)=>el.encoded_file === image.encoded_file)){
                const promise = request(config.baseUrl + '/api/v1/land-market/lands/photos/upload/','POST', image);
                promise.then((data)=>{
                    ids.push(data.file_id);
                    preview.push(image);
                })
                promises.push(promise);
            }
        })
        Promise.all(promises).then(()=>{
            setPhotoIds([...photoIds,...ids]);
            setPreviewImages([...previewImages,...preview]);
        })
        
    }

    const documentsHandler = async (files) => {
        const documents = await fileListToBase64(files);
        let promises = [];
        let ids = [];
        let preview = [];
        documents.map((document)=>{
            if(!previewDocuments.some((el)=>el.encoded_file === document.encoded_file)){
                const promise = request(config.baseUrl + '/api/v1/land-market/lands/documents/upload/','POST', document);
                promise.then((data)=>{
                    ids.push(data.file_id);
                    preview.push(document);
                })
                promises.push(promise);
            }
        })
        Promise.all(promises).then(()=>{
            setDocumentIds([...documentIds,...ids]);
            setPreviewDocuments([...previewDocuments,...preview]);
        })
        //request(config.baseUrl + '/api/v1/land-market/lands/documents/upload/','POST', document);
    }

    async function fileListToBase64(fileList) {
        function getBase64(file) {
          const reader = new FileReader()
          const fileExtension = file.name.split('.').pop();
          const fileName = file.name.split('.')[0];
          return new Promise(resolve => {
            reader.onload = ev => {
              resolve({
                "file_extension": fileExtension,
                "encoded_file": ev.target.result,
                "filename": fileName,
                "is_main_picture": false
            })
            }
            reader.readAsDataURL(file)
          })
        }
        const promises = []
        for (let i = 0; i < fileList.length; i++) {
          promises.push(getBase64(fileList[i]))
        }
        return await Promise.all(promises)
      }

    const lotTypeHandler = (e) => {
        setLotType(e.target.id);
    }

    const auctionTypeHandler = (e) => {
        setAuctionType(e.target.id);
    }
    
    return(
        <div className={s.AddLot}>
            <div className={s.content}>
                <div className={s.title}>
                    <h1>Нове оголошення</h1>
                </div>
                <div className={s.lotType}>
                {requestErrors?<h2 className={s.error}>{requestErrors[0]}</h2>:null}
                    <h2>Тип оголошення</h2>
                    <div className={s.radioButtons} onChange={lotTypeHandler}>
                        <div>
                        <input name="lot-type" type="radio" id="fixed" defaultChecked/>
                        <label htmlFor="fixed">Фіксована ціна</label>
                        </div>
                        <div>
                        <input name="lot-type" type="radio" id="auction"/>
                        <label htmlFor="auction">Аукціон</label>
                        </div>
                    </div>
                </div>
                {lotType=='auction'?<div className={s.lotInfo}>
                    <h2>Дані аукціону</h2>
                    <div className={s.colons}>
                        <div className={s.colonItem}>
                            {/* <div className={s.infoBox}>
                                <label>Дата початку</label>
                                <select>
                                    <option defaultValue>01.03.2021</option>
                                </select>
                            </div> */}
                            <div className={s.infoBox}>
                                <label>Початкова ціна (грн) <span className={s.error}>*</span></label>
                                <input onWheel={(e) => e.target.blur()} type="number" min="0" max="999999999999" onChange={(e)=>{setAuctionForm({...auctionForm, "min_price":e.target.value})}} placeholder="125 000"/>
                            </div>
                            {/* 
                            
                            <div className={s.auctionType}>
                                <h2>Тип аукціону</h2>
                                <div onChange={auctionTypeHandler} className={s.auctionTypeContent}>
                                    <input name="auction-type" type="radio" id="PROGRESSIVE" defaultChecked/>
                                    <label htmlFor="PROGRESSIVE">Прогресивний</label>
                                    <input name="auction-type" type="radio" id="REGRESSIVE"/>
                                    <label htmlFor="REGRESSIVE">Регресивний</label>
                                </div>
                            </div> */}
                        </div>
                        
                        <div className={s.colonItem}>
                            {/* <div className={s.infoBox}>
                                <label>Дата кінця</label>
                                <select>
                                    <option defaultValue>01.05.2021</option>
                                </select>
                            </div> */}
                            <div className={s.infoBox}>
                                <label>Мінімальна ціна продажу (грн) <span className={s.error}>*</span></label>
                                <input onWheel={(e) => e.target.blur()} type="number" min="0" max="999999999999" onChange={(e)=>{setAuctionForm({...auctionForm, "buy_now_price":e.target.value})}} placeholder="325 000"/>
                            </div>
                        </div>
                    </div>
                    <div className={s.switchBox}>
                        <label>Можливість купити зразу</label>
                        <label className={s.switch}>
                            <input onChange={(e)=>{setImmediateBuy(e.target.checked)}} type="checkbox"/>
                            <span className={s.slider + ' ' + s.round}></span>
                        </label>
                    </div>
                    
                </div>:null}
                
                <div className={s.lotInfo}>
                    <h2>Загальна інформація</h2>
                    <div className={s.colons}>
                        <div className={s.colonItem}>
                            <div className={s.infoBox}>
                                <label>Кадастровий номер <span className={s.error}>*</span></label>
                                <InputMask mask="9999999999:99:999:9999" onChange={formChangeHandler} name="cadastral_number" placeholder="6310138500:10:012:0045"/>
                            </div>
                            <div className={s.infoBox}>
                                <label>Площа (га) <span className={s.error}>*</span></label>
                                <input onChange={formChangeHandler} onWheel={(e) => e.target.blur()} min="0" type="number" name="area" placeholder="2.12"/>
                            </div>
                            <div className={s.infoBox}>
                                <label>Відчуження земельної ділянки <span className={s.error}>*</span></label>
                                <CustomSelect name="type_id" onSelect={(value,id)=>{setForm({...form, "type_id":id})}} url="/api/v1/land-market/lands/types/"/>
                            </div>
                        </div>
                        
                        <div className={s.colonItem}>
                            <div className={s.infoBox}>
                                <label>Тип/призначення ділянки <span className={s.error}>*</span></label>
                                <CustomSelect name="purpose" onSelect={(value,id)=>{setForm({...form, "purpose_id":id})}} url="/api/v1/land-market/lands/purposes/"/>
                            </div>
                            {lotType!='auction'?<div className={s.infoBox}>
                                <label>Ціна (грн) <span className={s.error}>*</span></label>
                                <input onChange={formChangeHandler} onWheel={(e) => e.target.blur()} min="0" name="price" type="number" placeholder="125 000"/>
                            </div>:null}
                        </div>
                    </div>
                    <div className={s.description}>
                        <label>Опис ділянки <span className={s.error}>*</span></label>
                        <textarea onChange={formChangeHandler} name="description" placeholder="Текст опису вашої ділянки"></textarea>
                    </div>
                </div>

                <div className={s.location}>
                    <h2>Локація</h2>
                    <div className={s.infoBox}>
                        <label>Область <span className={s.error}>*</span></label>
                        <CustomSelect name="region" onSelect={(value,id)=>{setLocation({...location, "region_id":id})}} url="/api/v1/location/regions/"/>
                    </div>
                    <div className={s.infoBox}>
                        <label>Район <span className={s.error}>*</span></label>
                        <CustomSelect name="district" onSelect={(value,id)=>{setLocation({...location, "district_id":id})}} url="/api/v1/location/districts/"/>
                    </div>
                    <div className={s.infoBox}>
                        <label>Населений пункт <span className={s.error}>*</span></label>
                        <CustomSelect name="settlement" onSelect={(value,id)=>{setLocation({...location, "settlement_id":id})}} url="/api/v1/location/settlements/"/>
                    </div>
                    <div className={s.infoBox}>
                        <label>Вулиця</label>
                        <CustomSelect name="street" onSelect={(value,id)=>{setLocation({...location, "street_id":id})}} url="/api/v1/location/streets/"/>
                    </div>
                </div>


                <div className={s.documents}>
                    <h2>Документи ділянки</h2>
                    <p>Доступні формати: .jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx . Максимальний розмір файлу не повинен перевищувати 20 Mb. </p>
                    <FileDrop onDrop={documentsHandler} className={s.dropBox} name="document">
                        <div>
                            <h3>Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері</h3>
                            <input onChange={(e)=>documentsHandler(e.target.files)} type="file" multiple accept=".jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx"/>
                            <button onClick={(e)=>{e.target.parentElement.children[1].click()}}>Обрати файл</button>
                        </div>
                    </FileDrop>
                    <div className={s.documentsItems}>
                        {previewDocuments.map((item, i)=>{
                            return <PreviewItem onDelete={deleteDocumentsHandler} id={i}  key={item.encoded_file} item={item}/>
                        })} 
                    </div>
                </div>


                 <div className={s.photos}>
                    <h2>Фото ділянки</h2>
                    <p>Доступні формати: .jpg, .jpeg, .tiff, .png . Максимальний розмір файлу не повинен перевищувати 10 Mb.</p>
                    <FileDrop onDrop={photosHandler} className={s.dropBox} name="document">
                        <div>
                            <h3>Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері</h3>
                            <input onChange={(e)=>photosHandler(e.target.files)} type="file" multiple accept=".jpg, .jpeg, .tiff, .png"/>
                            <button onClick={(e)=>{e.target.parentElement.children[1].click()}}>Обрати файл</button>
                        </div>
                    </FileDrop>
                    <div className={s.photosItems}>
                        {previewImages.map((item, i)=>{
                            return <PreviewItem onDelete={deletePhotosHandler} id={i}  key={item.encoded_file} item={item}/>
                        })}
                    </div>
                </div> 

                <div className={s.bottomBox}>
                    <div className={s.bottomBoxContent}>
                        <button onClick={submitHandler}>Завершити</button>
                        {isLoading?<Loading/>:null}
                        {successShown?
                        <div className={s.success}>
                            <div className={s.successContent}>
                                <h2>Оголошення було успішно створено</h2>
                                <button onClick={()=>{history.push(`/`)}}>Готово</button>
                            </div>
                        </div>:null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProduct;
