import s from "../../../pages/Auction/Auction.module.css";
import DatePicker, {setDefaultLocale} from "react-datepicker";
import React, {useState} from "react";
import dateFormat from "dateformat";
import uk from "date-fns/locale/uk";

const DateFilter = ({value, onChange}) => {
    const [selectedDate, setSelectedDate] = useState(value);
    setDefaultLocale(uk)
    const dateHandler = (date) =>{
        const formattedDate = dateFormat(date,"yyyy-mm-dd");
        setSelectedDate(formattedDate)
        // onChange(formattedDate)
        //setSelectFilter({...selectFilter, date_start:formattedDate});
    }
    return (
        <div className={s.filterItem}>
            <label>Дата початку аукціону</label>
            <DatePicker
                style={{ width:'100%'}}
                onChange={dateHandler}
                locale="ua-Uk"
                customInput={
                    <div style={{height:'40px', boxSizing:'border-box', display:'flex', alignItems:'center', paddingLeft:'7px'}} className={s.filterBox}>
                        <p>{selectedDate || <span style={{width:'80px', height:'40px'}}/>}</p>
                    </div>}
            />
        </div>
    )
}

export default DateFilter;