import React, {useContext, useEffect, useState, Fragment} from 'react';
import s from './Cabinet.module.css';
import {NavLink, useHistory} from "react-router-dom";
import defaultPhoto from '../../assets/img/userIcon.png';
import InputMask from "react-input-mask";
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import userPhoto from '../../assets/img/userIcon.png';
import Avatar from 'react-avatar-edit';
import InputRange from "react-input-range";
import {AuthContext} from "../../context/AuthContext";
import {FileDrop} from "react-file-drop";
import ErrorHandler from "../../utils/ErrorLoger";
function Cabinet(props) {
    const [previewPhoto, setPreviewPhoto] = useState();
    const auth = useContext(AuthContext);
    const history = useHistory();
    const [form, setForm] = useState({});
    const [phoneNumber, setPhoneNumber] = useState();
    const [userInfo, setUserInfo] = useState({});
    const [photoModalShown, setPhotoModalShown] = useState(false);
    const [successModalShown, setSuccessModalShown] = useState(false);
    const {request, requestErrors} = useHttp();
    const numberChangeHandler = (e) =>{
        const number = e.target.value.replace(/[^0-9]/g,'');
        setForm({...form, 'phone_number': '+' + number});
        setPhoneNumber(number);
    }

    const changeHandler = (e) => {
        setForm({...form, [e.target.name]: e.target.value})
    }


    const submitHandler = async () => {
        let passwordChangeSuccess = true, infoChangeSuccess = true;
        if(form.username || form.email || (form.phone_number && form.phone_number.length>2)){
            infoChangeSuccess = false;
            let data;
            try{
                data = await request(config.baseUrl + '/api/v1/user/update/', 'PUT', {
                    username:form.username,
                    email:form.email,
                    phone_number:form.phone_number
                });
                if(data && data.status == 'OK') infoChangeSuccess = true;
            }catch(e){
                ErrorHandler(e)
            }
        }

        if(form.old_password || form.new_password){
            passwordChangeSuccess = false;
            let data;
            try{
                data = await request(config.baseUrl + '/api/v1/user/password/change/', 'POST', {
                    old_password:form.old_password,
                    new_password1:form.new_password,
                    new_password2:form.new_password,
                });
                if(data && data.status == 'OK') passwordChangeSuccess = true;
            }catch(e){
                ErrorHandler(e)
            }
        }
        if(passwordChangeSuccess && infoChangeSuccess) setSuccessModalShown(true);
    }

    const filesHandler = async (files) => {
        const images = await fileListToBase64(files);
        let promises = [];
        let preview = [];
        images.map((img)=>{
                const promise = request(config.baseUrl + '/api/v1/user/photo/change/', 'POST', img);
                promise.then((data)=>{
                    setPreviewPhoto(img.encoded_file);
                    props.renderHeader();
                    setPhotoModalShown(false);
                })
        })
    }

    async function fileListToBase64(fileList) {
        function getBase64(file) {
            const reader = new FileReader()
            const fileExtension = file.name.split('.').pop();
            const fileName = file.name.split('.')[0];
            return new Promise(resolve => {
                reader.onload = ev => {
                    resolve({
                        "file_extension": fileExtension,
                        "encoded_file": ev.target.result,
                        "filename": fileName,
                        "is_main_picture": false
                    })
                }
                reader.readAsDataURL(file)
            })
        }
        const promises = []
        for (let i = 0; i < fileList.length; i++) {
            promises.push(getBase64(fileList[i]))
        }
        return await Promise.all(promises)
    }

    useEffect(async ()=>{
        let data;
        try{
            data = await request(config.baseUrl + '/api/v1/user/info/', 'GET');
            if(data && data.photo_url) {
                setPreviewPhoto(config.baseUrl + data.photo_url);
                setUserInfo(data);
                setForm({phone_number:data.phone_number, email:data.email, username:data.username});
                setPhoneNumber(data.phone_number);
            }
        }catch(e){
            ErrorHandler(e)
        }
    },[])

    return(
        <div className={s.Cabinet}>


            <div className={s.topBar}>
                <ul className={s.list}>
                    <li>
                        <NavLink activeClassName={s.active} exact to="/profile/">
                            <div className={s.listItem}>
                                <h3>Профіль</h3>
                            </div>
                        </NavLink>
                    </li>
                    {/*<li>*/}
                    {/*    <NavLink activeClassName={s.active} exact to="/profile/payment">*/}
                    {/*        <div className={s.listItem}>*/}
                    {/*            <h3>Оплата</h3>*/}
                    {/*        </div>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <NavLink activeClassName={s.active} exact to="/profile/subs">*/}
                    {/*        <div className={s.listItem}>*/}
                    {/*            <h3>Підписки</h3>*/}
                    {/*        </div>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <NavLink activeClassName={s.active} to="/payment">*/}
                    {/*        <div className={s.listItem}>*/}
                    {/*            <h3>Оплата</h3>*/}
                    {/*        </div>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                </ul>
            </div>

            <div className={s.profileContent}>
                <div className={s.photoChangeBox}>
                    <img src={previewPhoto?previewPhoto:userPhoto}/>
                    <button onClick={()=>{setPhotoModalShown(true)}}>Змінити фото</button>
                </div>
                <div className={s.colons}>
                    <div className={s.left}>
                        <div className={s.passwordItem}>
                            <label>Ім'я</label>
                            <input defaultValue={userInfo.username} name="username" onChange={changeHandler} placeholder="Андрій Андрієнко"/>
                        </div>
                        <div className={s.passwordItem}>
                            <label>Номер телефону</label>
                            <InputMask value={phoneNumber} mask="+38 (099) 999-99-99" type="text" name="phone_number" onChange={(e)=>{numberChangeHandler(e)}} placeholder="Номер телефону"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <div className={s.passwordItem}>
                            <label>Email</label>
                            <input defaultValue={userInfo.email} name="email" onChange={changeHandler} placeholder="mail@mail.com" type="email"/>
                        </div>
                    </div>
                </div>
            </div>

            {!userInfo.google_user_id?<div className={s.passwordBox}>
                <div className={s.passwordItem}>
                    <label>Пароль</label>
                    <input name="old_password" onChange={changeHandler} placeholder="********" type="password"/>
                </div>
                <div className={s.passwordItem}>
                    <label>Новий пароль</label>
                    <input name="new_password" onChange={changeHandler} placeholder="********" type="password"/>
                </div>
            </div>:null}
            <div className={s.bottom}>
                {requestErrors?<h2 data-testid="register-errros" className={s.error}>{requestErrors[0]}</h2>:null}
                <button onClick={submitHandler} className={s.saveButton}>Зберегти зміни</button>
            </div>

            {photoModalShown?
                <div className={s.success}>
                    <div className={s.successContent}>
                        <h2>Завантажити фото</h2>
                        <p>Доступні формати: .jpg, .jpeg, .png. Максимальний розмір файлу не повинен перевищувати 10 Mb. </p>
                        <FileDrop onDrop={filesHandler} className={s.dropBox} name="document">
                            <div>
                                <h3>Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері</h3>
                                <input onChange={(e)=>filesHandler(e.target.files)} type="file" accept=".jpg, .jpeg, .tiff, .png"/>
                                <button onClick={(e)=>{e.target.parentElement.children[1].click()}}>Обрати файл</button>
                            </div>
                        </FileDrop>
                        <div>
                            <button className={s.closeBtn} onClick={()=>{setPhotoModalShown(false)}}>Відхилити</button>
                            <button className={s.acceptBtn} onClick={()=>{setPhotoModalShown(false)}}>Готово</button>
                        </div>
                    </div>
                </div>:null}
                {successModalShown?
                <div className={s.success}>
                    <div className={s.successContent}>
                        <h2>Інформацію успішно оновлено</h2>
                        <div>
                            <button className={s.acceptBtn} onClick={()=>{setSuccessModalShown(false); history.push('/')}}>Готово</button>
                        </div>
                    </div>
                </div>:null}
        </div>
    )
}

export default Cabinet;