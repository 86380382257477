import React from 'react';
import s from './CabinetCard.module.css';
import {Link} from "react-router-dom";
import image from '../../assets/img/topSearchBackground.jpg';
import config from '../../config/config';
function CabinetCard({item, onSelect}) {

    const AuctionStatus = ({status}) => {
        switch (status) {
            case 'PENDING' : return <h5 className={s.cardInfo}>Очікується</h5>
            case 'AWAITING_START' : return <h5 className={s.cardInfo}>Очікування початку</h5>
            case 'STARTED' : return <h5 className={s.cardInfo}>Розпочалися</h5>
            case 'ENDED' : return <h5 className={s.cardInfo}>Завершились</h5>
            case 'AWAITING_PROTOCOL_SIGN' : return <h5 className={s.cardInfo}>Очікування підписання протоколу</h5>
            case 'AWAITING_CONTRACT_SIGN' : return <h5 className={s.cardInfo}>Очікування підписання договору</h5>
            case 'AWAITING_PAYMENT' : return <h5 className={s.cardInfo}>Очікування оплати</h5>
            case 'NOT_OCCURRED' : return <h5 className={s.cardInfo}>Не відбулись</h5>
            default : return <h5 className={s.cardInfo}>- -</h5>
        }
    }

    const PaymentStatus = ({status}) => {
        switch (status) {
            default : return <h5 className={s.cardInfo}>- -</h5>
        }
    }

    const  AuctionAdvertisementStatus = ({status}) => {
        switch (status) {
            case 'DRAFT' : return <p className={s.status+' '+s.green}>Чернетка</p>
            case 'SIGNED' : return <p className={s.status+' '+s.green}>Підписано</p>
            case 'PUBLISHED' : return <p className={s.status+' '+s.green}>Оприлюднено</p>
            case 'EDITED' : return <p className={s.status+' '+s.green}>Відредаговано</p>
            default : return <p className={s.status+' '+s.green}>- -</p>
        }
    }

    return(
        <div className={s.CabinetCard}>
            <img src={config.baseUrl + item.photo_url}/>
            <div className={s.rightContainer}>
                <div className={s.topBox}>
                    <div className={s.lotName}>
                        <p className={s.label}>Назва лоту</p>
                        <a onClick={onSelect}>{item.theme}</a>
                    </div>
                    <div className={s.lotDate}>
                        <p className={s.label}>Дата і час останніх змін</p>
                        <h5 className={s.cardInfo}>{item.last_edit_at}</h5>
                    </div>
                </div>
                <div className={s.bottomBox}>
                    <div className={s.lotStatus}>
                        <p className={s.label}>Статус торгів:</p>
                        <AuctionStatus status={item.auction_status}/>
                    </div>
                    <div className={s.lotPayment}>
                        <p className={s.label}>Оплата:</p>
                        <PaymentStatus status={item.payment_status}/>
                    </div>
                </div>
            </div>
            <AuctionAdvertisementStatus status={item.status}/>
        </div>
    )
}

export default CabinetCard