import React, {useEffect, useContext} from 'react';
import jwt from 'jwt-decode';
import {Switch, Route, Redirect} from 'react-router-dom';
import Home from './pages/Home/Home';
import AddLot from './pages/AddLot/AddLot';
import Auction from './pages/Auction/Auction';
import Lot from './pages/Lot/NewLot';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register/RegisterRedesign';
import Login from './pages/Login/Login';
import Restore from './pages/Restore/Restore';
import FixedMarket from './pages/FixedMarket/FixedMarket';
import Confirm from './pages/Confirm/Confirm';
import Land from './pages/Land/Land';
import Cabinet from "./pages/Cabinet/Cabinet";
import Map from "./pages/Map/Map";
import Documents from "./pages/Documents/Documents";
import Notifications from "./pages/Notifications/Notifications";
import MyLots from "./pages/MyLots/MyLots";
import EditLot from "./pages/EditLot/EditLotRedesign";
import Invoices from "./pages/Invoices/Invoices";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Saved from "./pages/Saved/Saved";
import Protocols from "./pages/Protocols/Protocols";
import AuctionRoom from "./pages/AuctionRoom/AuctionRoom";
import AuctionResults from "./pages/AuctionResults/AuctionResults";
import CabinetSideBar from "./components/CabinetSideBar/CabinetSideBar";
import Applications from "./pages/Applications/Applications";
import Participation from "./components/Participation/Participation";
import EditForm from "./pages/EditForm/EditForm";
import AddLotRedesign from "./pages/AddLot/AddLotRedesign";
import Contracts from "./pages/Contracts/Contracts";
import OrganizerLot from "./pages/OrganizerLot/OrganizerLot";
export const useRoutes = (scope, renderHeader) =>{
    // useEffect(()=>{
    //     const getScope = async () =>{ 
    //     console.log('USE EFFECT')
    //     const storage = await JSON.parse(localStorage.getItem('userData'));
    //     if(storage && Object.keys(storage).length != 0 && storage.token){
    //         scope = await jwt(storage.token).scope;
    //     }else scope = null;
    // }
    // getScope();
    // console.log('TOKEN LAODED');
    // },[])


    //TODO add react lazy
    //TODO delete unused routes

    if(scope)
    switch(scope) {
        case 'complete_registration':
        case 'confirm_phone_number': {
            return(
                <Switch>
                    <Route path="/create/lot/">
                        <AddLot/>
                    </Route>
                     <Route path="/lot/:number/">
                        <Lot/>
                    </Route>
                    <Route path="/room/:params/:access_code/">
                        <AuctionRoom/>
                    </Route>
                    <Route path="/room/:params/">
                        <AuctionRoom/>
                    </Route>
                    <Route path="/results/:params/">
                        <AuctionResults/>
                    </Route>
                    <Route path="/land/:params/">
                        <Land/>
                    </Route>
                    <Route  path="/confirm/">
                        <Confirm/>
                    </Route>
                    <Route path="/profile/">
                        <Profile/>
                    </Route>
                    <Route exact path="/auction/">
                        <Home/>
                        <Auction/>
                    </Route>
                    <Route exact path="/fixed/">
                        <Home/>
                        <FixedMarket/>
                    </Route>
                    <Route exact path="/saved/">
                        <Home/>
                        <Saved/>
                    </Route>
                    <Route exact path="/register/">
                        <Register renderHeader={renderHeader}/>
                    </Route>
                    <Route exact path="/map/">
                        <Map/>
                    </Route>
                    <Redirect to="/confirm/"/>
                </Switch>
            )
        }
        case 'sms_reset_password': {
            return(
                <Switch>
                    <Route path="/create/lot/">
                        <AddLot/>
                    </Route>
                    <Route path="/lot/:number/">
                        <Lot/>
                    </Route>
                    <Route path="/room/:params/:access_code/">
                        <AuctionRoom/>
                    </Route>
                    <Route path="/room/:params/">
                        <AuctionRoom/>
                    </Route>
                    <Route path="/results/:params/">
                        <AuctionResults/>
                    </Route>
                    <Route path="/land/:params/">
                        <Land/>
                    </Route>
                    <Route  path="/confirm/">
                        <Confirm/>
                    </Route>
                    <Route path="/profile/">
                        <Profile/>
                    </Route>
                    <Route exact path="/auction/">
                        <Home/>
                        <Auction/>
                    </Route>
                    <Route exact path="/fixed/">
                        <Home/>
                        <FixedMarket/>
                    </Route>
                    <Route exact path="/saved/">
                        <Home/>
                        <Saved/>
                    </Route>
                    <Route exact path="/register/">
                        <Register renderHeader={renderHeader}/>
                    </Route>
                    <Route exact path="/map/">
                        <Map/>
                    </Route>
                    <Route exact path="/reset/">
                        <ResetPassword/>
                    </Route>
                    <Route exact path="/login/">
                        <Login/>
                    </Route>
                    <Redirect to="/reset/"/>
                </Switch>
            )
        }
        case 'verify_sms_code': {
                return(
                    <Switch>
                        <Route path="/create/lot/">
                            <AddLot/>
                        </Route>
                        <Route path="/lot/:number/">
                            <Lot/>
                        </Route>
                        <Route path="/room/:params/:access_code/">
                            <AuctionRoom/>
                        </Route>
                        <Route path="/room/:params/">
                            <AuctionRoom/>
                        </Route>
                        <Route path="/results/:params/">
                            <AuctionResults/>
                        </Route>
                        <Route path="/land/:params/">
                            <Land/>
                        </Route>
                        <Route  path="/confirm/">
                            <Confirm/>
                        </Route>
                        <Route path="/profile/">
                            <Profile/>
                        </Route>
                        <Route exact path="/auction/">
                            <Home/>
                        </Route>
                        <Route exact path="/fixed/">
                            <Home/>
                        </Route>
                        <Route exact path="/saved/">
                            <Home/>
                            <Saved/>
                        </Route>
                        <Route exact path="/map/">
                            <Map/>
                        </Route>
                        <Redirect to="/confirm/"/>
                    </Switch>
                )
            }
    case 'main':{
        return(
            <Switch>
            <Route path="/test/">
                <Register/>
            </Route>

            <Route path="/form/edit/:params/">
                <EditForm/>
            </Route>
            <Route path="/create/lot/">
                <AddLotRedesign/>
            </Route>
            <Route path="/lot/:number/">
                <Lot/>
            </Route>
            <Route path="/room/:params/:access_code/">
                <AuctionRoom/>
            </Route>
            <Route path="/room/:params/">
                <AuctionRoom/>
            </Route>
            <Route path="/results/:params/">
                <AuctionResults/>
            </Route>
            <Route path="/land/:params/">
                <Land/>
            </Route> 
            <Route exact path="/auction/">
                <Home/>
            </Route>
            <Route exact path="/saved/">
                <Home/>
                <Saved/>
            </Route>
            <Route exact path="/timer/">
                <Profile/>
            </Route>
            <Route exact path="/profile/">
                <CabinetSideBar>
                    <Cabinet renderHeader={renderHeader}/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/payment/">
                <Cabinet/>
            </Route>
            <Route exact path="/subscribes/">
                <Cabinet/>
            </Route>
            <Route exact path="/map/">
                <Map/>
            </Route>
            <Route exact path="/documents/">
                <CabinetSideBar>
                    <Documents/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/applications/">
                <CabinetSideBar>
                    <Applications/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/invoices/">
                <CabinetSideBar>
                    <Invoices/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/protocols/">
                <CabinetSideBar>
                    <Protocols/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/contracts/">
                <CabinetSideBar>
                    <Contracts/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/notifications/">
                <CabinetSideBar>
                    <Notifications renderHeader={renderHeader}/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/mylots/saved/">
                <CabinetSideBar>
                    <MyLots/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/mylots/auction/">
                <CabinetSideBar>
                    <MyLots/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/mylots/auction/:number/">
                <CabinetSideBar>
                    <OrganizerLot/>
                </CabinetSideBar>
            </Route>
            <Route exact path="/edit/:number/">
                <EditLot/>
            </Route>
            <Redirect to="/auction/"/>
        </Switch>
        )
    }

    default:{
        return (
            <Switch>
            <Route path="/auction/">
                <Home/>
            </Route>
            <Route path="/register/">
                <Register/>
            </Route>
            <Route path="/login/">
                <Login renderHeader={renderHeader}/>
            </Route>
            <Route path="/restore/">
                <Restore/>
            </Route>
            <Route path="/lot/:number/">
                <Lot/>
            </Route>
            <Route path="/room/:params/:access_code/">
                <AuctionRoom/>
            </Route>
            <Route path="/room/:params/">
                <AuctionRoom/>
            </Route>
            <Route path="/results/:params/">
                <AuctionResults/>
            </Route>
            <Route path="/land/:params/">
                <Land/>
            </Route>
            <Route path="/fixed/">
                <Home/>
            </Route>
            <Route path="/saved/">
                <Home/>
                <Saved/>
            </Route>
            <Route exact path="/map/">
                <Map/>
            </Route>
            <Redirect to="/auction/"/>
        </Switch>
        )
    }
}}
