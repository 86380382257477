import React, {useContext, useState} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import SignComponent from "../../../components/SignComponent/SignComponent";
import s from "../Protocols.module.css";
import icon from "../../../assets/img/document-icon.png";
import config from "../../../config/config";

export default function ProtocolItem({item, type, onUpdate}){
    const [isSigning, setIsSigning] = useState(false)
    const fileParts = item.file.split('/');
    const filename = fileParts[fileParts.length-1];
    const auth = useContext(AuthContext);

    const signHandler = async (file) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth.token}`);
        const formData = new FormData();
        formData.append("file", file, file.name);
        const requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };
        fetch(`https://private.rynok-zemli.com.ua/api/v1/auctions/auction-protocols/sign/${item.id}/${type}/`, requestOptions)
            .then(response => response.json())
            .then(result => onUpdate())
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <SignComponent
                isVisible={isSigning}
                onSign={signHandler}
                onClose={()=>{setIsSigning(false)}}
            />
            <div className={s.monthCard}>
                <img src={icon}/>
                <p>{filename}</p>
                <a
                    download={item.file}
                    target="_blank"
                    href={config.baseUrl + item.file}
                    className={s.cardDelete}
                >Переглянути</a>
                {type === "winner" && item.protocol_status === 'AWAITING_WINNER_SIGNATURE' &&
                <p onClick={()=>{setIsSigning(true)}} className={s.cardDelete}>Підписати</p>}
                {type === "organizer" && item.protocol_status === 'AWAITING_ORGANIZER_SIGNATURE' &&
                <p onClick={()=>{setIsSigning(true)}} className={s.cardDelete}>Підписати</p>}
            </div>
        </>
    )
}