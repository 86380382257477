import React, {useEffect, useState, useRef} from 'react';
import s from './Land.module.css';
import locationIcon from '../../assets/img/combined-shape_2.png';
import typeIcon from '../../assets/img/combined-shape_3.png';
import timeIcon from '../../assets/img/combined-shape_4.png';
import {useHttp} from '../../hooks/HttpHook';
import {useParams} from 'react-router-dom';
import config from '../../config/config.js';
import mapMarker from '../../assets/img/mapMarker.svg';
import 'leaflet/dist/leaflet.css';
import '../../assets/styles/leaflet-styles.css';
import 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import OtherLotsMap from "../../components/OtherLotsMap/OtherLotsMap";
import InputMask from "react-input-mask";
import ErrorHandler from "../../utils/ErrorLoger";


function Land(){
    const id = useParams().params;
    const {request} = useHttp();
    const [markers, setMarkers] = useState([]);
    const [land, setLand] = useState({});
    const [landPosition, setLandPosition] = useState();
    const [photos, setPhotos] = useState([]);
    const [user, setUser] = useState({});
    const [zoomPhotoShown, setZoomPhotoShown] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);
    const [buyModalShown, setBuyModalShown] = useState(false);
    const [buyForm, setBuyForm] = useState({});


    const numberChangeHandler = (e) =>{
        const number = e.target.value.replace(/[^0-9]/g,'');
        setBuyForm({...buyForm, 'phone_number': '+' + number});
    }

    const nextPhotoHandler = (e) => {
        if(currentImg<photos.length-1) setCurrentImg(currentImg+1);
    }

    const prevPhotoHandler = (e) => {
        if(currentImg>0) setCurrentImg(currentImg-1);
    }

    const buyHandler = async () => {
        if(buyForm.name && buyForm.email && buyForm.phone_number){
            let data;
            try{
                data = await request(config.baseUrl + `/api/v1/land-market/lands/${id}/buy/`,'POST',{
                    name: buyForm.name,
                    email:buyForm.email,
                    phone_number: buyForm.phone_number
                });
            }catch(e){
                ErrorHandler(e)
            }
            setBuyModalShown(false);
        }
    }

    async function getData(){
        let data;
        try{ 
            data = await request(config.baseUrl + `/api/v1/land-market/lands/${id}/`,'GET');
        }catch(e){
            ErrorHandler(e)
        }
        if(data){
            setLand(data);
            setPhotos(data.photos);
            setUser(data.user);
            setLandPosition(data.position);
        }
        
    }

    useEffect(()=>{
        getData();
    },[]);
    return(
        <div className={s.Lot}>
            <div className={s.content}>
            {zoomPhotoShown?
            <div onClick={()=>{setZoomPhotoShown(false)}} className={s.zoomPhoto}>
                <div className={s.zoomPhotoContent}>
                    {land?<img src={config.baseUrl + '' + land.photos[currentImg].url}/>:null}
                </div>
            </div>:null}
                <div className={s.topText}>
                    <h3>Оголошення / {land.cadastral_number || 'test'}</h3>
                    <p>Переглянуто раз: {land? land.views_count:null}</p>
                </div>

                <div className={s.lotContent}>
                    <div className={s.leftCol}>
                        <div className={s.mainPhoto}>
                        <div onClick={prevPhotoHandler} className={s.leftArr}>
                            <i className="fas fa-chevron-left"></i>
                        </div>
                        <div onClick={nextPhotoHandler} className={s.rightArr}>
                            <i className="fas fa-chevron-right"></i>
                        </div>
                            {land && land.photos?<img onClick={()=>{setZoomPhotoShown(true)}} alt="img" src={config.baseUrl + '' + land.photos[currentImg].url}/>:null}
                        </div>
                            <div className={s.otherPhotos}>
                                {photos?photos.map((photo, i)=>{
                                    return <img 
                                    onClick={(e)=>{setCurrentImg(+e.target.id)}} 
                                    key={i} 
                                    id={i} 
                                    src={config.baseUrl + '' + photo.url}/>
                                }):null}
                            </div>
                    </div>
                    <div className={s.rightCol}>
                        <div className={s.priceBox}>
                            <div className={s.priceBoxInfo}>
                            <div>
                                <h2>{land.price} грн.</h2>
                                <p>{land.area} га</p>
                                <a className={s.cadastNum}
                                   target="_blank"
                                   href={land?'http://map.land.gov.ua/kadastrova-karta?cadnum='+land.cadastral_number:''}>
                                    {land?land.cadastral_number:null}
                                    <i className="fas fa-external-link-alt"></i>
                                </a>
                            </div>
                            </div>
                            <div className={s.priceBoxButtons}>
                            <button onClick={()=>{setBuyModalShown(true)}}>Купити</button>
                            </div>
                        </div>
                        <div className={s.infoBox}>
                            <div>
                                <div className={s.type}>
                                    <img alt="img" className={s.icon} src={typeIcon}/>
                                    <p>{land.purpose?land.purpose.description:null}</p>
                                </div>
                                <div className={s.time}>
                                    <img alt="img" className={s.icon} src={timeIcon}/>
                                    <p>14.02.21 14:33 </p>
                                </div>
                                <div className={s.location}>
                                    <img alt="img" className={s.icon} src={locationIcon}/>
                                    <p>{land.location?`${location.street?location.street.name+',':null} ${land.location.settlement.name}, ${land.location.region.name}, ${land.location.district.name} район`:null}</p>
                                </div>
                            </div>
                            {console.log(user)}
                            <div className={s.lotOwner}>
                                <img alt="img" src={user.photo_url?config.baseUrl + user.photo_url:"https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png"}/>
                                <div>
                                <p>{user.username || 'Людина'}</p>
                                <h3>{user.phone_number}</h3>
                                </div>
                            </div>
                        </div>
                        <div className={s.decriptionBox}>
                            <p>{land.description}</p>
                        </div>
                        {land && land.position?<OtherLotsMap type="land" defaultMarkerId={id} center={land.position} style={{height:'400px', width:'100%', "z-index":'0'}}/>:null}

                        {/*<div style={{height:'400px', width:'100%'}} id="map">*/}

                        {/*</div>*/}

                    </div>

                </div>
                {buyModalShown?
                    <div className={s.buyModal}>
                        <div className={s.buyModalContent}>
                            <h1>Заявка на купівлю</h1>
                            <p>Заповніть форму та продавець зв'яжеться з вами</p>
                            <label>Ваше прізвище та ім'я</label>
                            <input onChange={(e)=>{setBuyForm({...buyForm, 'name': e.target.value})}} placeholder="Ім'я Прізвище"/>
                            <label>Контактний телефон</label>
                            <InputMask mask="+38 (099) 999-99-99" type="text" name="phone_number" onChange={numberChangeHandler} placeholder="+38 (096) 123 45 67"/>
                            <label>Контактний емейл</label>
                            <input onChange={(e)=>{setBuyForm({...buyForm, 'email': e.target.value})}} placeholder="mail@mail.com"/>
                            <div className={s.buyModalButtons}>
                                <button className={s.buyModalCancel} onClick={()=>{setBuyModalShown(false)}}>Відхилити</button>
                                <button className={s.buyModalAccept} onClick={buyHandler}>Надіслати заявку</button>
                            </div>
                        </div>
                    </div>:null}
            </div>
        </div>
    )
}

export default Land;